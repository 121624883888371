import { createContext, useContext, useEffect, useState } from "react";

// Create the context
const ReferenceContext = createContext(undefined);

// Create a custom hook to use the context
export function useReferenceContext() {
    const context = useContext(ReferenceContext);
    if (context === undefined) {
        throw new Error("useReferenceContext must be used within a ReferenceProvider");
    }
    return context;
}

// Create the provider component
export function ReferenceProvider({ children }) {
    const [referencesList, setReferencesList] = useState([]);
    const [projectDetails, setProjectDetails] = useState();
    // const [golbalProjectId, setGolbalProjectId] = useState();

    const [miroValue, setMiroValue] = useState("");

    const [miroLink, setMiroLink] = useState("");
    const [boardId, setBoardId] = useState(() => {
        const savedBoardId = localStorage.getItem('boardId');
        try {
            return savedBoardId !== null ? JSON.parse(savedBoardId) : 0;
        } catch (e) {
            console.error("Error parsing boardId from localStorage", e);
            return 0;
        }
    });
    // const [sheetId, setSheetId] = useState(0);
    const [sheetId, setSheetId] = useState(() => {
        const savedSheetId = localStorage.getItem('sheetId');
        try {
            return savedSheetId !== null ? JSON.parse(savedSheetId) : 0;
        } catch (e) {
            console.error("Error parsing sheetId from localStorage", e);
            return 0;
        }

    });

    const [designId, setDesignId] = useState(() => {
        const savedDesignId = localStorage.getItem('designId');
        try {
            return savedDesignId !== null ? JSON.parse(savedDesignId) : 0;
        } catch (e) {
            console.error("Error parsing savedDesignId from localStorage", e);
            return 0;
        }

    });

    useEffect(() => {
        // Save sheetId to local storage whenever it changes
        localStorage.setItem('sheetId', JSON.stringify(sheetId));
        localStorage.setItem('boardId', JSON.stringify(boardId));
        localStorage.setItem('designId', JSON.stringify(designId));

    }, [sheetId, boardId, designId]);

    const [excalidrawLoading, setExcalidrawLoading] = useState(true);
    const [uploadedFiles,setUploadedFiles] = useState([]);
    return (
        <ReferenceContext.Provider value={{
            referencesList,
            setReferencesList,
            miroValue,
            setMiroValue,
            miroLink,
            setMiroLink,
            excalidrawLoading,
            setExcalidrawLoading,
            boardId,
            setBoardId,
            sheetId,
            setSheetId,
            designId,
            setDesignId,
            uploadedFiles,
            setUploadedFiles,
            projectDetails, 
            setProjectDetails
        }}>
            {children}
        </ReferenceContext.Provider>
    );
}
