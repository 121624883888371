import arrowLeft from "../../../assets/newicons/arrow-left-dark.svg";
import trueIcon from "../../../assets/newicons/true-icon.svg";
import menu from "../../../assets/newicons/menu.svg";
import closeIcon from "../../../assets/newicons/close.svg"
import aiIcon from "../../../assets/newicons/ai-white-icon.svg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import './visionStudio.scss';
import newImage from "../../../assets/newimages/no_image.jpg"
import { useKonvaContext } from "../../../context/useKonvaContext";
import { useNavigate } from "react-router-dom";
import comments from '../../../assets/newicons/comments.svg'
import ArrowBack from "../../../assets/newicons/arrow_back.svg";
// import closeIcon from "../../assets/newicons/close.svg"

const KonvaHeader = ({projectName, sheet, channelName, toggleCommentSection}) => {
    const { t } = useTranslation();
    const layers = useSelector(state => state.konva.layers);
    const [modal, setModal] = useState(false)
    const [exportQuality, setExportQuality] = useState(100);
    const [commentOpen, setCommentOpen] = useState(false)
    // State for export format
    const [exportFormat, setExportFormat] = useState('PNG');
    const navigate = useNavigate()
    const { sheetCount}= useKonvaContext()

    const {
        stageRef
    } = useKonvaContext();

    const handleExport = () => {
        setModal(true)
    };

    const navigateToReference = () => {
        navigate(-1);
    }

    const exportImage = (e) => {
        e.preventDefault();

        const stage = stageRef.current;
        const stageWidth = stage.width();
        const stageHeight = stage.height();

        const maxWidth = stageWidth;
        const maxHeight = stageHeight;
        let width = layers[0].size[0] || 0;
        let height = layers[0].size[1] || 0;

        let minRatio = 0, scaledWidth = 0, scaledHeight = 0;
        if (width > maxWidth || height > maxHeight) {
            const widthRatio = maxWidth / width;
            const heightRatio = maxHeight / height;
            minRatio = Math.min(widthRatio, heightRatio);
            scaledWidth = width * minRatio;
            scaledHeight = height * minRatio;
        }

        const jsonOutput = {
            "size": [layers[0].size[0], layers[0].size[1]],
            "channels": 3,
            "depth": 8,
            "color_mode": "3",
            "layers": layers[0].elements.map((element) => {
                return {
                    "z-index": element["z-index"],
                    "name": element?.props?.name,
                    "visible": element?.props?.visible,
                    "opacity": element?.props?.opacity,
                    "blend_mode": element?.blend_mode,
                    "bbox": [
                        +parseInt(parseInt((element?.props?.x / scaledWidth) * width)).toFixed(0),
                        +parseInt((element?.props?.y / scaledHeight) * height).toFixed(0),
                        +parseInt(((element?.props?.x + element?.props?.width) / scaledWidth) * width).toFixed(0),
                        +parseInt(((element?.props?.y + element?.props?.height) / scaledHeight) * height).toFixed(0)
                    ],
                    "size": [
                        element?.props?.width,
                        element?.props?.height
                    ],
                    "kind": element?.kind,
                    ...((element?.kind === "shape" || element?.kind === "smartobject") && {
                        "image_data": {
                            "mode": "RGBA",
                            "size": [
                                +parseInt(((element?.props?.x + element?.props?.width) / scaledWidth) * width).toFixed(0),
                                +parseInt(((element?.props?.y + element?.props?.height) / scaledHeight) * height).toFixed(0)
                            ],
                            "format": element?.props?.format,
                            "data": element?.props?.src
                        }
                    }),
                    ...(element?.kind === "type" && element?.type === "Text" && {
                        "text": {
                            "content": element?.props?.text,
                            "font": element?.props?.fontFamily,
                            "size": element?.props?.fontSize,
                            "color": element?.props?.fill
                        }
                    })
                };
            })
        };
        downloadData(jsonOutput, `image_${Date.now()}.json`);
    }

    const downloadData = (data, filename = `data_${Date.now()}`) => {
        let blob;
        let fileExtension;
        if (exportFormat === 'JSON') {
            const jsonString = JSON.stringify(data, null, 2);
            blob = new Blob([jsonString], { type: 'application/json' });
            fileExtension = '.json';
        } else if (exportFormat === 'PNG' || exportFormat === 'JPG') {
            const dataURL = stageRef.current.toDataURL({
                mimeType: exportFormat === 'PNG' ? 'image/png' : 'image/jpeg',
                quality: exportQuality / 100,
            });
            // Create a temporary download link
            const link = document.createElement('a');
            link.href = dataURL;
            link.download = `image_${Date.now()}.${exportFormat.toLowerCase()}`;
            document.body.appendChild(link);
            link.click();
            return document.body.removeChild(link);
        }

        // For JSON format
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${filename}${fileExtension}`;
        link.click();
        URL.revokeObjectURL(link.href);
    };

    const handleGoBack = () => {
        navigate(-1);
      };

    return (
        <>
            <div style={{zIndex:"10", position:"relative"}} className="d-flex justify-content-between flex-wrap align-items-center gap-md-3 gap-2 py-md-2 px-md-3 p-sm-3 p-xs-2 p-2 py-2 bg-white border-bottom font-noto-sans">
                <div className="d-flex  align-items-center gap-sm-3 gap-2">
                    <div className="cursor-pointer" onClick={handleGoBack} >
                        <img src={ArrowBack} alt="ArrowBack" width={25} />
                    </div>
                    
                    <div className="header_Bigline"></div>
                    {/* <div className='d-flex align-items-center gap-2'>
        
                        <div className="dropdown header-dropdown">
                            <button className="btn bg-transparent text-black fw-600 fs-14 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                Untitled design
                            </button>
                            <ul className="dropdown-menu shadow-sm" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#exampleModal">Create project from this workspace</a>
                                </li>
                                <li>
                                    <a className="dropdown-item">Share</a>
                                </li>

                            </ul>
                        </div>
                        <div className='d-flex align-items-center gap-2'>
                            <button className='p-0 bg-transparent d-flex align-items-center justify-content-center header-true-button'>
                                <img src={trueIcon} alt='trueIcon' />
                            </button>
                            <button className='p-0 bg-transparent d-flex align-items-center justify-content-center header-true-button'>
                                <img src={closeIcon} alt='closeIcon' />
                            </button>
                        </div>
                    </div> */}
                    <div className="d-flex align-items-center gap-2">
                        <span className="fs-14 fw-600">{channelName}</span>
                        <span className="fs-14 fw-600">/</span>
                        <span className="fs-14 fw-600">{projectName}</span>
                    </div>
                </div>

                <div style={{position:"relative", marginRight:"170px"}}>
                    <p className="fs-14 fw-500 mb-0 text-black">{t("totalSheet")}: <span className="ms-1"> {sheetCount} </span></p>
                </div>
                <div className="d-flex align-items-center gap-2">
                    {/* <button className='d-flex align-items-center gap-2 ai-vision-button rounded-pill px-md-3 px-2'>
                        <img src={aiIcon} alt='aiIcon' />
                        <span className='fs-14  fw-600 text-white'>AI Vision</span>
                    </button> */}
                     <img src={comments} alt="comments" className="cursor-pointer" onClick={toggleCommentSection} />
                    {/* <button className="btn-black py-md-2 py-1 px-md-4 px-3 fw-600" onClick={(e) => { handleExport() }}>Export</button> */}
                </div>
            </div>

            {modal && (
                <div className="export-modal">
                    <div className="export-modal-header">
                        <h4>Export <span>(0)</span></h4>
                        <img src={closeIcon} alt="closeIcon" onClick={(e) => { setModal(false) }} />
                    </div>
                    <div className="export-modal-body">
                        <p className="m-0 text-end select">Select all</p>
                        <div className="export-image-container">
                            <label
                                className={`position-relative ms-0 image-container image-container-ref mb-0 cursor-pointerselected`}
                                id='select_image'
                            >
                                <img
                                    loading="lazy"
                                    src={newImage}
                                    className='rounded-3 export-images'
                                    alt='no-img'
                                />
                                <input
                                    type='checkbox'
                                    className='image-checkbox'
                                    htmlFor='select_image'

                                />
                            </label>
                            <label
                                className={`position-relative ms-0 image-container image-container-ref mb-0 cursor-pointerselected`}
                                id='select_image'
                            >
                                <img
                                    loading="lazy"
                                    src={newImage}

                                    className='rounded-3 export-images'
                                    alt='no-img'
                                />
                                <input
                                    type='checkbox'
                                    className='image-checkbox'
                                    htmlFor='select_image'

                                />
                            </label>
                            <label
                                className={`position-relative ms-0 image-container image-container-ref mb-0 cursor-pointerselected`}
                                id='select_image'
                            >
                                <img
                                    loading="lazy"
                                    src={newImage}

                                    className='rounded-3 export-images'
                                    alt='no-img'
                                />
                                <input
                                    type='checkbox'
                                    className='image-checkbox'
                                    htmlFor='select_image'
                                />
                            </label>

                        </div>
                        <div className="image-select">
                            <label>Export as</label>
                            <select
                                value={exportFormat}
                                onChange={(e) => setExportFormat(e.target.value)}
                            >
                                <option value="PNG">PNG</option>
                                <option value="JPG">JPG</option>
                                <option value="JSON">JSON</option>
                            </select>
                        </div>
                        <div className="image-range">
                            <label>Quality</label>
                            <div className="d-flex align-items-center gap-2">
                                <input type="range" min="1"
                                    max="100"
                                    value={exportQuality}
                                    onChange={(e) => setExportQuality(e.target.value)} />
                                <button>{exportQuality}%</button>
                            </div>
                        </div>
                        <button className="text-white border-0 rounded-pill mt-lg-4 mt-2 px-4 black-gradiant-btn" onClick={(e) => { exportImage(e) }}>Export</button>
                    </div>
                </div>
            )}
        </>
    )
}

export default KonvaHeader;