export default {
    control: {
        backgroundColor: "transparent",
        fontSize: 14,
        fontWeight: "normal",
        maxHeight: 10,
        // overflow: "auto"
    },

    "&multiLine": {
        control: {
            fontFamily: "Noto Sans",
            minHeight: 30,
            maxHeight: 300, // Fixed height for textarea
            // overflow: "auto", // Enable scrolling for overflow
        },
        highlighter: {
            padding: 12,
            border: "1px solid transparent",
        },
        input: {
            padding: 12,
            border: "none",
        },
    },

    "&singleLine": {
        display: "inline-block",
        width: 180,

        highlighter: {
            padding: 1,
            border: "2px inset transparent",
        },
        input: {
            padding: 1,
            border: "2px inset",
        },
    },

    suggestions: {
        // left: "0px",
        // right: "auto",
        // width: "100%",
        // top: "auto",
        list: {
            backgroundColor: "white",
            border: "1px solid rgba(0,0,0,0.15)",
            fontSize: 14,
            borderRadius: 5,
            zIndex: 999999,
            // position: "absolute", 
            // top: "100%", 
            maxHeight: "150px",
            overflow: "auto",
            width: "100%",
            // right: "auto",
            // left: "0px"
        },
        item: {
            padding: "5px 15px",
            "&focused": {
                backgroundColor: "#d9d9d9",
            },
        },
    },
};
