// get company name by site-id
export const getCompanyName = (siteId, permissions) => {
  const site = permissions?.channel_name?.find(
    (site) => site.site_id === siteId
  );
  if (site) {
    return site.site_name;
  } else {
    return "N/A";
  }
};

//  helper for model filters
const updateModelFilters = (
  formValue,
  modelKeyName,
  optionValues,
  modelId,
  replace = false
) => {
  const modelFilters = formValue?.model_filters || []; // Ensure model_filters is defined

  const existingFilter = modelFilters?.find(
    (filter) => filter.model_key_name === modelKeyName
  );

  if (existingFilter) {
    if (replace) {
      return modelFilters.map((filter) => {
        if (filter.model_key_name === modelKeyName) {
          return {
            ...filter,
            model_options: optionValues,
            model_id: modelId,
          };
        }
        return filter;
      });
    } else if (optionValues.length === 0) {
    } else {
      // Otherwise, update the model options array
      return modelFilters.map((filter) => {
        if (filter.model_key_name === modelKeyName) {
          const newOptions = filter?.model_options?.includes(optionValues[0])
            ? filter.model_options.filter(
              (option) => option !== optionValues[0]
            )
            : [...filter.model_options, optionValues[0]];
          return { ...filter, model_options: newOptions, model_id: modelId };
        }
        return filter;
      });
    }
  } else {
    // If the filter does not exist, add a new one
    return [
      ...modelFilters,
      {
        model_id: modelId,
        model_key_name: modelKeyName,
        model_options: optionValues,
      },
    ];
  }
};

// for model filters
export const handleModelCheckBoxChange = (
  e,
  value,
  modelId,
  tag,
  formValue,
  setFormValue,
  checked
) => {
  const currentFormValue = { ...formValue };
  const modelDisplayCode = e.target.name;
  if (value === -2) {
    let allValues = [];
    if (!checked) {
      setFormValue({
        ...formValue,
        model_filters: [
          ...formValue?.model_filters,
          {
            model_id: modelId,
            model_key_name: modelDisplayCode,
            model_options: [],
          },
        ],
      });
      // return;
    } else {
      allValues = Object.values(tag.model_options);
      setFormValue({
        ...formValue,
        model_filters: [
          ...formValue?.model_filters,
          {
            model_id: modelId,
            model_key_name: modelDisplayCode,
            model_options: allValues,
          },
        ],
      });
    }

    // Get the updated model filters with all values, setting replace to true
    const updatedModelFilters = updateModelFilters(
      currentFormValue,
      modelDisplayCode,
      allValues,
      modelId,
      true // Replace existing filter values
    );

    // Set the new form value with the updated model filters
    setFormValue({ ...currentFormValue, model_filters: updatedModelFilters });
  } else {
    // Get the updated model filters with the single value
    const model = Object.keys(tag?.model_options)
    const formModel = currentFormValue.model_filters.find((a) => a.model_key_name == modelDisplayCode);
    if (formModel) {
      if (formModel?.model_options?.length == model.length && !checked) {
        // formModel.model_options = formModel?.model_options.includes(value) ? Object.values(formModel?.model_options).filter((v) => v != value && v != -2) : Object.values(tag.model_options);
        // setFormValue(currentFormValue);
        let valueList = []
        if (formModel?.model_options.includes(value)) { valueList = (Object.values(formModel?.model_options).filter((v) => v != value && v != -2)) }
        const updatedModelFilters = updateModelFilters(
          currentFormValue,
          modelDisplayCode,
          valueList, // Pass value as array
          modelId,
          true
        );
        return setFormValue({ ...currentFormValue, model_filters: updatedModelFilters });
      }
    }

    let updatedModelFilters = updateModelFilters(
      currentFormValue,
      modelDisplayCode,
      [value], // Pass value as array
      modelId,
    );

    const updateformModel = updatedModelFilters?.find((a) => a?.model_key_name == modelDisplayCode);
    if (((model.length - 1) === updateformModel?.model_options?.length) && checked) {
      updateformModel?.model_options.push(-2)
      updatedModelFilters = updatedModelFilters.map((item) =>
        item.model_key_name === modelDisplayCode ? updateformModel : item
      );
    }

    // Set the new form value with the updated model filters
    setFormValue({ ...currentFormValue, model_filters: updatedModelFilters });
  }
};

// for category filters
export const handleCheckboxChange = (
  e,
  value,
  tag,
  formValue,
  setFormValue,
  checked
) => {
  const name = e.target.name;
  if (value === -2) {
    if (!checked) {
      setFormValue({ ...formValue, [name]: [] });
    } else {
      const allValues = Object.values(tag.category_options);
      setFormValue({ ...formValue, [name]: allValues });
    }
  } else {
    const categories = Object.values(tag?.category_options)
    const currentValues = formValue[name] || [];
    if (categories.length == currentValues.length) {
      const newValues = currentValues.includes(value)
        ? currentValues.filter((v) => v !== value && v != -2)
        : [...currentValues, value];
      setFormValue({ ...formValue, [name]: newValues });
    } else {
      const newValues = currentValues.includes(value)
        ? currentValues.filter((v) => v !== value)
        : [...currentValues, value];

      if ((categories.length - 1) == newValues.length) {
        const allValues = Object.values(tag.category_options);
        return setFormValue({ ...formValue, [name]: allValues });
      }
      setFormValue({ ...formValue, [name]: newValues });
    }
  }
};
