import React, { useState, useRef, useEffect, useMemo, useContext } from "react";
import { newGet, newPost } from "../../API/axios";
import { useAuthContext } from "../../context/useAuthContext";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { showAlert } from "./component/AlertService";
import { KonvaProvider } from "../../context/useKonvaContext";
import { Provider } from "react-redux";
import store from "../../store/index";
import KonvaHeader from "./component/KonvaHeader";
import ImageComponent from "./component/ImageComponent";
import { useLocation } from "react-router-dom";
import CommentAndTaskVisionStudio from "./component/CommentsVisionStudio";
import { useDispatch } from 'react-redux';
import { resetKonvaState } from '../../store/konvaSlice';
import { useReferenceContext } from "../../context/useReferenceContext";


const VisionStudio = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedFont, setSelectedFont] = useState("");
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [clientUserList, setClientUserList] = useState([]);
  const allUsers = [...userList, ...clientUserList];
  const [isCreateDisable, setIsCreateDisable] = useState(false);
  const [showMenu, setShowMenu] = useState(true);
  const [typeChange, setTypeChange] = useState("");
  const [showCommentSection, setShowCommentSection] = useState(false);
  const { projectDetails} = useReferenceContext();


    // Check if project is active based on status
    const isProjectActive = () => {
      if (!projectDetails?.status) return true; // Default to active if no status
      
      const statusId = parseInt(Object.keys(projectDetails.status)[0]);
      
      // Status 5 means completed/cancelled project, status 3 means inactive
      return statusId !== 5 && statusId !== 3;
  };

  
  const location = useLocation();


  const toggleCommentSection = () => {
    setShowCommentSection((prev) => !prev);
  };


  let {projectName, sheet, channelName, createdProjectId } = location.state || {};
  




  const [ProjectId, setCreatedProjectId] = useState(createdProjectId);
  // useEffect(() => {
  //     if (projectFormValue.project_name.length >= 2) {
  //         checkProjectName(inputProjectName)
  //     }
  // }, [debouncedProjectName])

  const { t } = useTranslation();
  const queryParams = new URLSearchParams(location.search);
  const project_id = queryParams.get("project_id");

  const { initialLoading } = useAuthContext();

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get("project_name")) {
    projectName=decodeURIComponent(urlParams.get("project_name"));
  }
  if (urlParams.get("sheet")) {
    sheet = urlParams.get("sheet");
  }
  if (urlParams.get("channel_name")) {
    channelName = decodeURIComponent(urlParams.get("channel_name"));
  }
  if (urlParams.get("project_id")) {
    createdProjectId = urlParams.get("project_id"); 
  }

  const dispatch = useDispatch();
  
  // Clear redux state when component mounts
  useEffect(() => {
    dispatch(resetKonvaState());
    
    // Clear redux state when component unmounts
    return () => {
      dispatch(resetKonvaState());
    };
  }, [dispatch]);

  useEffect(()=>{

  },[])

  const handleImageUploadClick = (e, image) => {
    e.preventDefault();
    if (!showMenu) {
      setShowMenu(true);
    }
    setTypeChange(image);
  };

  const [projectFormValue, setProjectFormValue] = useState({
    project_name: "",
    project_description: "",
    channel: "",
    assign_to: [],
    internal_approver: [],
    client_approver: [],
    watch_list: [],
    start_date: "",
    due_date: "",
    required_sheet: "",
  });

  const [errors, setErrors] = useState({
    project_name: "",
    project_description: "",
    channel: "",
    assign_to: "",
    internal_approver: "",
    watch_list: "",
    start_date: "",
    due_date: "",
    required_sheet: "",
  });

  const checkProjectName = async (value) => {
    const specialCharsPattern = /[^a-zA-Z0-9 _]/g;
    const testValue = specialCharsPattern.test(value);
    if (!testValue) {
      try {
        await newGet(
          `project/checkProjectName?project_name=${value}&site_id=${projectFormValue?.channel?.site_id}`
        ).then((data) => {
          if (data && data.status_code == 200) {
            if (data.result[0]?.count_project_name > 0) {
              setErrors({
                ...errors,
                project_name: "Project name already exist !!!",
              });
              setIsCreateDisable(true);
            } else {
              setErrors({ ...errors, project_name: "Project name is valid" });
              setIsCreateDisable(false);
            }
          }
        });
      } catch (error) {
        console.log("error:???????", error);
        setLoading(false);
      }
    } else {
      setErrors({
        ...errors,
        project_name: "special characters are not allowed !!!",
      });
      setIsCreateDisable(true);
    }
  };

  const handleChange = async (e, fieldName) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: "" });
    if (name === "required_sheet") {
      if (/^\d*$/.test(value)) {
        setProjectFormValue({ ...projectFormValue, [name]: value });
      }
    } else {
      setProjectFormValue({ ...projectFormValue, [name]: value });
    }
  };

  const handleDateChange = (date, name) => {
    const formattedDate = date ? format(date, "MM-dd-yyyy") : null;
    setProjectFormValue({ ...projectFormValue, [name]: formattedDate });
    setErrors({ ...errors, [name]: "" });
  };

  const handleChannelChange = (site) => {
    setProjectFormValue({ ...projectFormValue, channel: site });
    setErrors({ ...errors, channel: "" });
  };

  const handlemultipleUsers = (e, options, fieldName) => {
    let newFormValue = { ...projectFormValue, [fieldName]: options };
    let newErrors = { ...errors, [fieldName]: "" };

    if (
      ["assign_to", "internal_approver", "client_approver"].includes(fieldName)
    ) {
      const combinedWatchList = [
        ...new Set([
          ...newFormValue.assign_to,
          ...newFormValue.internal_approver,
          ...newFormValue.client_approver,
        ]),
      ];
      const watchListUser = allUsers?.filter(
        (user) =>
          !combinedWatchList?.some(
            (combined) => combined.user_id === user.user_id
          )
      );
    }

    setProjectFormValue(newFormValue);
    setErrors(newErrors);
  };

  const handleSubmit = async () => {
    const requiredFields = [
      { key: "project_name", message: "Project name is required." },
      {
        key: "project_description",
        message: "Project description is required.",
      },
      { key: "channel", message: "Channel name is required." },
      { key: "required_sheet", message: "No.of sheet is required." },
      {
        key: "assign_to",
        message: "Assign to name is required.",
        isArray: true,
      },
      {
        key: "internal_approver",
        message: "Internal approver name is required.",
        isArray: true,
      },
      { key: "start_date", message: "start date is required." },
      { key: "due_date", message: "Due date is required." },
      { key: "required_sheet", message: "No. of sheet is required." },
    ];

    let formIsValid = true;
    const newErrors = { ...errors };

    requiredFields.forEach((field) => {
      if (
        field.isArray
          ? projectFormValue[field.key].length === 0
          : !projectFormValue[field.key]
      ) {
        newErrors[field.key] = field.message;
        formIsValid = false;
      } else if (
        field.key === "required_sheet" &&
        (!projectFormValue[field.key] || projectFormValue[field.key] == 0)
      ) {
        newErrors[field.key] =
          projectFormValue[field.key] == 0
            ? "Required sheet can not be zero"
            : field.message;
        formIsValid = false;
      } else {
        newErrors[field.key] = "";
      }
    });

    if (!formIsValid) {
      setErrors(newErrors);
      return;
    }
    const formData = {
      project_name: projectFormValue?.project_name,
      project_desc: projectFormValue?.project_description,
      site_id: projectFormValue?.channel?.site_id,
      assign_to_user_id: projectFormValue?.assign_to
        ?.map((user) => user?.account_login_id)
        .join(","),
      internal_approver_user_id: projectFormValue?.internal_approver
        ?.map((user) => user?.account_login_id)
        .join(","),
      client_approver_user_id: projectFormValue?.client_approver
        ?.map((user) => user?.account_login_id)
        .join(","),
      watchlist: projectFormValue?.watch_list
        ?.map((user) => user?.account_login_id)
        .join(","),
      start_date: projectFormValue?.start_date,
      due_date: projectFormValue?.due_date,
      required_sheet: parseInt(projectFormValue?.required_sheet),
    };
    try {
      setLoading(true);
      const data = await newPost(`project`, formData);
      setLoading(false);
      if (data.status_code == 201) {
        showAlert("Project created successfully", "success");
        closeModal();
      }
    } catch (error) {
      console.log("error:", error);
      setLoading(false);
    }
  };

  const closeModal = () => {
    const modalElement = document.getElementById("exampleModal");
    const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
    modalInstance.hide();
  };

  const handleClearForm = () => {
    setProjectFormValue({
      project_name: "",
      project_description: "",
      channel: "",
      required_sheet: "",
      assign_to: [],
      internal_approver: [],
      client_approver: [],
      watch_list: [],
      start_date: "",
      due_date: "",
    });
    setErrors({
      project_name: "",
      project_description: "",
      channel: "",
      required_sheet: "",
      assign_to: "",
      internal_approver: "",
      client_approver: "",
      watch_list: "",
      start_date: "",
      due_date: "",
    });
    setIsCreateDisable(false);
    closeModal();
  };

  useEffect(() => {
    const modalElement = document.getElementById("exampleModal");
    const handleModalHidden = () => {
      handleClearForm();
    };
    modalElement?.addEventListener("hidden.bs.modal", handleModalHidden);
    return () => {
      modalElement?.removeEventListener("hidden.bs.modal", handleModalHidden);
    };
  }, []);

  return (
    <div>
      <KonvaProvider>
        <Provider store={store}>
          <KonvaHeader
            projectName={projectName}
            sheet={sheet}
            channelName={channelName}
            toggleCommentSection={toggleCommentSection}
          />
          <div className="d-flex w-100">
            <div style={{ width: showCommentSection ? '76%' : '100%', transition: 'width 0.3s ease' }}>
              {/* <div className='bg-white px-sm-2 py-4 d-flex flex-column gap-4 border-end position-relative' style={{ zIndex: "10" }}>
                            <button className='border-0 bg-transparent d-flex align-items-center'>
                                <img src={addIcon} alt='addIcon' width={10} />
                                <span style={{ fontSize: "10px", fontWeight: "500" }} >Add</span>
                            </button>
                            <button className='border-0 bg-transparent' onClick={(e) => { handleImageUploadClick(e, 'text') }}>
                                <img src={tIcon} alt='tIcon' />
                            </button>
                            <button className='border-0 bg-transparent'>
                                <img src={imageIcon} alt='imageIcon' onClick={(e) => { handleImageUploadClick(e, 'image') }} />
                            </button>
                            <button className='border-0 bg-transparent'>
                                <img src={draw_collage} alt='draw_collage' onClick={(e) => { handleImageUploadClick(e, 'draw') }} />
                            </button>
                            <button className='border-0 border-bottom pb-3 bg-transparent'>
                                <img src={interests} alt='interests' onClick={(e) => { handleImageUploadClick(e, 'interests') }} />
                            </button>
                            <button className='border-0 bg-transparent'>
                                <img src={folderIcon} alt='folderIcon' onClick={(e) => { handleImageUploadClick(e, 'folder') }} />
                            </button>
                            <button className='border-0 bg-transparent'>
                                <img src={chat_bubble} alt='chat_bubble' onClick={(e) => { handleImageUploadClick(e, 'bubble') }} />
                            </button>
                        </div> */}

            {/* {showMenu && <SideMenu onClose={() => setShowMenu(false)} selectedImages={selectedImages} setTypeChange={setTypeChange} typeChange={typeChange} setSelectedImages={setSelectedImages} setSelectedFont={setSelectedFont} selectedFont={selectedFont} />}  */}
                <ImageComponent
                    selectedImages={selectedImages}
                    selectedFont={selectedFont}
                    typeChange={typeChange}
                    setSelectedImages={setSelectedImages}
                    setTypeChange={setTypeChange}
                    sheet={sheet}
                />
            </div>
            
            {!showCommentSection && (
                <button
                    className="visionstudio-sidebar-close-btn"
                    onClick={() => setShowCommentSection(true)}
                    style={{ right: 0, left: 'auto' }}
                >
                    <span style={{ rotate: '225deg' }}></span>
                </button>
            )}
            
            <div 
                className={`${showCommentSection ? "d-block" : "d-none"}`}
                style={{
                    position: 'fixed',
                    right: 0,
                    top: '42px',
                    width: '24%',
                    height: 'calc(100vh - 56px)',
                    backgroundColor: "#F6F6F6",
                    borderLeft: '1px solid #e0e0e0',
                    pointerEvents: isProjectActive() ? 'auto' : 'none',
                    transition: 'all 0.3s ease',
                }}
            >
                <button
                    className="visionstudio-sidebar-close-btn"
                 
                    onClick={() => setShowCommentSection(false)}
                >
                    <span style={{ rotate: '45deg' }}></span>
                </button>
                <CommentAndTaskVisionStudio
                    createdProjectId={createdProjectId}
                    ProjectId={ProjectId}
                />
            </div>
          </div>
        </Provider>
      </KonvaProvider>

      {/* initial modal */}
      {!initialLoading && (
        <div
          className="modal fade font-noto-sans"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content" style={{ overflow: "inherit" }}>
              <div className="modal-header border-0 pb-0">
                <h5 className="modal-title fw-bold" id="exampleModalLabel">
                  {t("createNewProject")}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClearForm}
                ></button>
              </div>

              {/* <CreateProjectForm
                handleChange={handleChange}
                handlemultipleUsers={handlemultipleUsers}
                projectFormValue={projectFormValue}
                setProjectFormValue={setProjectFormValue}
                errors={errors}
                userList={userList}
                allUsers={allUsers}
                clientManager={clientManager}
                clientUserList={clientUserList}
                handleChannelChange={handleChannelChange}
                loading={loading && initialLoading}
                handleDateChange={handleDateChange}
                setInputProjectName={setInputProjectName}
              /> */}

              {loading ? (
                <div className="modal-footer border-0">
                  <p
                    type="button"
                    className="cancel_modal_footer"
                    onClick={handleClearForm}
                  >
                    {t("cancel")}
                  </p>
                  {/* <CreateProjectFormLoader
                    height={60}
                    width={100}
                    rounded={"rounded-25"}
                  /> */}
                </div>
              ) : (
                <div className="modal-footer border-0">
                  <p
                    type="button"
                    className="cancel_modal_footer"
                    onClick={handleClearForm}
                  >
                    {t("cancel")}
                  </p>
                  <button
                    type="button"
                    className="send_modal_footer"
                    onClick={handleSubmit}
                    disabled={isCreateDisable}
                  >
                    {t("create")}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VisionStudio;
