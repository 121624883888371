import React, { createContext, useContext, useEffect, useState } from "react";

const SearchContext = createContext();

export const useSearchContext = () => {
  return useContext(SearchContext);
};

export const SearchProvider = ({ children }) => {
  // dashboard searchview
  const getSearchViewState = () => {
    const savedState = localStorage?.getItem("SEARCH_VIEW");
    const state = savedState === "true";  
    setSearchView(state);
    return state;
  };

  const setSearchViewState = async (state) => {
    await localStorage?.setItem("SEARCH_VIEW", state);
    setSearchView(state);
  };
  const [searchView, setSearchView] = useState(false);

  // search
  const [searchResults, setSearchResults] = useState([]);
  const [similarResults, setSimilarResults] = useState([]);

  const [results, setResults] = useState([]);
  const [similarityGroup, setSimilarityGroup] = useState([]);

  const [numberOfResults, setNumberOfResults] = useState({
    primary: 0,
    similar: 0,
  });
  const [data, setData] = useState({});
  const [prevSearch, setPrevSearch] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [formValue, setFormValue] = useState({
    input_text: "",
    page: 1,
    ctr: 0,
    tags: [],
    model_filters: [],
    is_edited: "",
    search_by: "both",
  });


  useEffect(() => {
    getSearchViewState()
  }, [])
  return (
    <SearchContext.Provider
      value={{
        searchResults,
        prevSearch,
        setPrevSearch,
        setSearchResults,
        similarResults,
        setSimilarResults,
        results,
        similarityGroup,
        setSimilarityGroup,
        setResults,
        numberOfResults,
        isEdited,
        setIsEdited,
        setNumberOfResults,
        formValue,
        setData,
        data,
        setFormValue,
        showResults,
        setShowResults,
        searchView,
        setSearchViewState
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
