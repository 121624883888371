import React, { useState, useEffect, useRef } from 'react';

import arrowsIcon from "../../../assets/newicons/arrows-icon.svg";
import smallImage from "../../../assets/newicons/small-image-icon.svg";
import plusIcon from "../../../assets/newicons/add.svg";
import removeIcon from "../../../assets/newicons/remove-icon.svg";
import aiIcon from "../../../assets/newicons/ai-white-icon.svg";
import smallTabImage from "../../../assets/newimages/tabs-image.png";
import percentage from "../../../assets/newicons/percentage-icon.svg";
import opacity from "../../../assets/newicons/opacity-icon.svg";
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import newDesign from "../../../assets/newimages/new-design-image.png";
import refresh from "../../../assets/newicons/refresh.svg";
import aiIconBlack from "../../../assets/newicons/ai-dark-icon.svg";
import boldIcon from "../../../assets/newicons/bold-icon.svg";
import italicIcon from "../../../assets/newicons/italic-icon.svg";
import underline from "../../../assets/newicons/underlined-icon.svg";
import alignManu from "../../../assets/newicons/format-align.svg";
import formatList from "../../../assets/newicons/format-list.svg";
import formatLine from "../../../assets/newicons/format-line.svg";
import copyIcon from "../../../assets/newicons/copy-icon.svg";
import pastIcon from "../../../assets/newicons/past-icon.svg";
import duplicateIocn from "../../../assets/newicons/duplicate-iocn.svg";
import deleteIocn from "../../../assets/newicons/delete.svg";
import lockOpen from "../../../assets/newicons/lock_open.svg";
import lockClose from "../../../assets/newicons/lock_close.svg";
import threeDots from "../../../assets/newicons/three-dots.svg";
import WebFont from 'webfontloader';
import KonvaCanvas from '../../../components/KonvaCanvas';
import { useKonvaContext } from '../../../context/useKonvaContext';
import roundedCorner from "../../../assets/newicons/rounded-corner-icon.svg";
import revartIcon from "../../../assets/newicons/revert-icon.svg";
import notrevartIcon from "../../../assets/newicons/not-revart-icon.svg";
import roteteImage from "../../../assets/newicons/rotete-center.svg";
import leftroteteImage from "../../../assets/newicons/left-rotete-icon.svg";
import cropIcon from "../../../assets/newicons/crop-icon.svg"
import replace from "../../../assets/newicons/replace.svg"
import download from "../../../assets/newicons/download1.svg";
import remove from "../../../assets/newicons/delete1.svg"
import verified from "../../../assets/newicons/verified.svg";
import { Menu, MenuItem, styled, Tooltip, tooltipClasses } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { initializeCanvas, removeSheet, updateSheetStatus, updateElement } from '../../../store/konvaSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import addIcon from '../../../assets/newicons/add-circle.svg';
import MagicBrush from './MagicBrush';
import AddImagePopUp from './AddImagePopUp';
import templateData from '../../../assets/jsons/type_a.json';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Close from '../../../assets/newicons/close.svg';
import Draggable from 'react-draggable';
import { newGet, newPost, newPut } from '../../../API/axios';
import { useReferenceContext } from '../../../context/useReferenceContext';
import { useAuthContext } from '../../../context/useAuthContext';
import { addSheet } from '../../../store/konvaSlice';
import UploadDesignFile from '../component/UploadDesignFile';
import { useTranslation } from 'react-i18next';


const ImageComponent = ({ selectedImages, typeChange, setSelectedImages, setTypeChange, handleExport, sheet }) => {
    const dispatch = useDispatch();
    const canvas = useSelector(state => state.konva.canvas);
    const [scale, setScale] = useState(1);
    const [reload, setReload] = useState(0);
    const [isLock, setisLock] = useState(false);
    const [sheetName, setsheetName] = useState("Sheet 1"); // Initial text
    const [isEditing, setIsEditing] = useState(false);
    const editorRef = useRef(null);
    const [layerId, setLayerId] = useState(null);
    const { t } = useTranslation();
    const [returnComment, setReturnComment] = useState("")

    const { boardId, designId } = useReferenceContext()
    const { setSheetCount, selectedSheetId } = useKonvaContext()
    const { userSettings } = useAuthContext();
    const modalRef = useRef(null);
    const [currentSheetId, setCurrentSheetId] = useState(0);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const replaceSheet = () => {
        console.log("replaceSheet")
    }

    const downloadSheet = () => {
        console.log("downloadSheet")
    }

    const lockNRequestApprival = () => {
        console.log("lockNRequestApprival")
    }
    // const templateData = location.state?.data || null;
    const { showSideModal,
        fontSize,
        setFontSize,
        fontFamily,
        setFontFamily,
        color,
        setColor,
        menuPosition,
        contextMenuVisible,
        setContextMenuVisible,
        newTextValue,
        setNewTextValue,
        inputPosition,
        showTextOptionsModal, setShowTextOptionsModal,
        selectedId, fonts,
        selectedLayerId,
        setTextStyle,
        textStyle,
        setCornerRadius,
        cornerRadius,
        cornerRadiusValue,
        setCornerRadiusValue
    } = useKonvaContext();

    const loadGoogleFonts = () => {
        WebFont.load({
            google: {
                families: fonts.map(font => font.family),
            },
        });
    };

    const getLayerDetails = async (sheetId) => {
        try {
            const sheetData = await newGet(`sheet/getSheetDetail?sheet_id=${sheetId}`);
            // console.log("sheetData", sheetData);
            // if (sheetData?.result?.layers?.[0]?.id) {
            //     setLayerId(sheetData.result.layers[0].id);
            // }
            if (sheetData?.result) {
                return sheetData?.result;
            }
            return ""
        }
        catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        loadGoogleFonts();
        getDesignDetails();
        // if (canvases?.[0]?.sheet_id) {
        //     getLayerDetails(canvases[0].sheet_id);
        // }
    }, []);

    // useEffect(() => {
    //     if (canvases?.[0]?.sheet_id) {
    //         getLayerDetails(canvases[0].sheet_id);
    //     }
    // }, [canvases]);

    // useEffect(() => {
    //     console.log("useEffect calling...")
    // }, [canvases])

    const getDesignDetails = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const design_id = urlParams.get("design_id");
        try {
            const designData = await newGet(`design/getDesignDetail?design_id=${design_id||designId}`);

            if ([200, 201].includes(designData?.status_code)) {
                setSheetCount(designData?.result?.sheets.length);
                dispatch(initializeCanvas({
                    id: designId,
                    sheets: designData?.result?.sheets
                }));
            } else {
                dispatch(initializeCanvas({
                    id: null,
                    sheets: []
                }));
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const cornerRadiusOptions = {
        0: [cornerRadiusValue, cornerRadiusValue, cornerRadiusValue, cornerRadiusValue],
        1: [cornerRadiusValue, 0, 0, 0],
        2: [0, cornerRadiusValue, 0, 0],
        3: [0, 0, cornerRadiusValue, 0],
        4: [0, 0, 0, cornerRadiusValue],
    };

    const handleRewrite = () => {
        if (selectedId && selectedId.includes('text')) {
            const newProps = {
                text: newTextValue,
            };
            dispatch(updateElement({ 
                sheetId: selectedSheetId,
                elementId: selectedId, 
                newProps 
            }));
            setShowTextOptionsModal(false);
        }
    };

    const hideContextMenu = () => {
        setContextMenuVisible(false);
    };

    const handleFontChange = (e) => {
        setFontFamily(e.target.value);
        if (selectedSheetId && selectedId) {
            dispatch(updateElement({ 
                sheetId: selectedSheetId,
                elementId: selectedId, 
                newProps: {
                    fontFamily: e.target.value,
                }
            }));
        }
    };

    const handleFontSizeChange = (e) => {
        setFontSize(e.target.value);
        if (selectedLayerId && selectedId) {
            const newProps = {
                fontSize: e.target.value,
            };
            dispatch(updateElement({ layerId: selectedLayerId, elementId: selectedId, newProps }));
        }
    };

    const handleColorChange = (e) => {
        setColor(e.target.value);
        if (selectedLayerId && selectedId) {
            const newProps = {
                fill: '#' + e.target.value,
            };
            dispatch(updateElement({ layerId: selectedLayerId, elementId: selectedId, newProps }));
        }
    };

    const cornerRadiusChange = (e) => {
        setCornerRadius(e.target.value)
        const data = cornerRadiusOptions[e.target.value]
        if (selectedLayerId && selectedId) {
            const newProps = {
                cornerRadius: data,
            };
            dispatch(updateElement({ layerId: selectedLayerId, elementId: selectedId, newProps }));
        }
    }

    const textFontStyleClick = (e, name) => {
        e.preventDefault();
        const styleMap = {
            bold: { prop: 'fontWeight', activeValue: 'bold', inactiveValue: 'normal' },
            italic: { prop: 'fontStyle', activeValue: 'italic', inactiveValue: 'normal' },
            underline: { prop: 'textDecoration', activeValue: 'underline', inactiveValue: 'none' }
        };

        const { prop, activeValue, inactiveValue } = styleMap[name];

        if (selectedLayerId && selectedId) {
            const currentValue = textStyle[name];
            const newValue = currentValue !== activeValue ? activeValue : inactiveValue;

            const newProps = {
                [prop]: newValue,
            };

            dispatch(updateElement({
                layerId: selectedLayerId,
                elementId: selectedId,
                newProps,
            }));

            setTextStyle((prevStyle) => ({
                ...prevStyle,
                [name]: newValue
            }));
        }
    };

    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },

        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 160,
            backgroundColor: "black"
        },

    }));
    const handleZoomIn = () => {
        const newScale = Math.min(scale + 0.1, 3);  // Max zoom 300%
        setScale(newScale);
    };

    const handleZoomOut = () => {
        const newScale = Math.max(scale - 0.1, 0.1);  // Min zoom 10%
        setScale(newScale);
    };

    // Handle mouse wheel zoom
    const handleWheel = (e) => {
        if (e.ctrlKey) {
            e.preventDefault();
            const delta = e.deltaY;
            setScale(prev => {
                const newZoom = delta > 0
                    ? Math.max(prev - 0.1, 0.1)  // Zoom out
                    : Math.min(prev + 0.1, 3);   // Zoom in
                return Number(newZoom.toFixed(1));
            });
        }
    };

    // Add wheel event listener
    useEffect(() => {
        const editor = editorRef.current;
        if (editor) {
            editor.addEventListener('wheel', handleWheel, { passive: false });
        }
        return () => {
            if (editor) {
                editor.removeEventListener('wheel', handleWheel);
            }
        };
    }, []);

    const StyledMenu = styled((props) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            {...props}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            marginTop: theme.spacing(1),
            minWidth: 250,
            color: 'rgb(55, 65, 81)',
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                fontWeight: 500,
                fontSize: 14,
                '& .MuiSvgIcon-root': {

                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(0.5),
                },
                '&:active': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity,
                    ),
                },
            },
            ...theme.applyStyles('dark', {
                color: theme.palette.grey[300],
            }),
        },
    }));

    const addNewSheet = async () => {
        try {
            const sheetData = await newPost('sheet/create', {
                design_id: designId,
                sheet_name: sheetName
            });

            if (sheetData?.result?.sheet_id) {
                const sheet = await getLayerDetails(sheetData?.result?.sheet_id);
                dispatch(addSheet({ 
                    sheet_id: sheetData?.result?.sheet_id,
                    sheet_name: sheetName,
                    ...sheet 
                }));
            }
        }
        catch (error) {
            console.log(error)
        }
    }


    const handleDoubleClick = () => {
        setIsEditing(true); // Enable editing mode
    };

    const handleChange = (event) => {
        setsheetName(event.target.value); // Update text as user types
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            setIsEditing(false); // Save and exit editing mode on Enter
        }
    };

    const handleBlur = () => {
        setIsEditing(false); // Save and exit editing mode on blur
    };

    const handleRemoveSheet = async (sheetId) => {
        try {
            const response = await newPut('sheet/delete', {
                sheet_id: sheetId,
            });

            if (response?.status_code === 200) {
                dispatch(removeSheet(sheetId));
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const lockNRequestApproval = async () => {
        try {
            const response = await newPut(`approval/sentForApproval`, {
                "sheet_ids": [selectedSheetId]
            });

            if ([200, 201].includes(response?.status_code)) {
                // Update Redux store with new status
                dispatch(updateSheetStatus({
                    sheetId: selectedSheetId,
                    status: 2  // 2 represents pending approval status
                }));
            }
        } catch (error) {
            console.error("Error in lockNRequestApproval:", error);
        }
    };

    const cancelRequestApproval = async () => {
        try {
            const response = await newPut(`approval/revoke`, {
                "sheet_ids": [selectedSheetId]
            });

            if ([200, 201].includes(response?.status_code)) {
                // This will update status and automatically unlock the sheet
                dispatch(updateSheetStatus({
                    sheetId: selectedSheetId,
                    status: 1  // 1 for draft status
                }));
            }
        } catch (error) {
            console.error("Error in cancelRequestApproval:", error);
        }
    };

    const handleReturnSheet = async () => {
        console.log("selectedSheetId", currentSheetId)
        try {
            const response = await newPut(`approval/return`, {
                "sheet_ids": [currentSheetId],
                "return_reason": returnComment
            });

            if ([200, 201].includes(response?.status_code)) {
                dispatch(updateSheetStatus({
                    sheetId: currentSheetId,
                    status: 4  // 4 for returned status
                }));
                // Close modal or reset comment if needed
                setReturnComment('');
            }
        } catch (error) {
            console.error("Error in handleReturnSheet:", error);
        }
    }

    const handleCommentChange = (event) => {
        setReturnComment(event.target.value)
    }

    return (
        <div className='w-100' key={reload}>
            <div className='d-flex justify-content-center w-100 main-image-editor position-relative overflow-hidden'>
                <KonvaCanvas
                    selectedImages={selectedImages}
                    setSelectedImages={setSelectedImages}
                    scale={scale}
                    setScale={setScale}
                    removeSheet={handleRemoveSheet}
                    modalRef={modalRef}
                    setCurrentSheetId={setCurrentSheetId}
                    lockNRequestApproval={lockNRequestApproval}
                    cancelRequestApproval={cancelRequestApproval}
                />
            </div>
            <div>


                {/* Zoom controls */}
                <div className="image-editor-controls">
                    <div className="zoom-controls">
                        <button
                            className="zoom-btn"
                            onClick={handleZoomOut}
                            disabled={scale <= 0.1}
                        >
                            <img src={removeIcon} alt="Zoom out" />
                        </button>
                        <span className="zoom-level">{Math.round(scale * 100)}%</span>
                        <button
                            className="zoom-btn"
                            onClick={handleZoomIn}
                            disabled={scale >= 3}
                        >
                            <img src={plusIcon} alt="Zoom in" />
                        </button>
                        <button
                            className="zoom-btn ms-2"
                            onClick={() => {
                                setReload(prev => prev + 1);
                            }}
                            title="Reset view"
                        >
                            <img src={revartIcon} alt="Reset view" style={{ width: "20px", height: "20px" }} />
                        </button>
                    </div>
                </div>

                {showTextOptionsModal && (
                    <div className="text-options-modal position-absolute w-100 bg-white text-start" style={{
                        top: inputPosition.y,
                        left: inputPosition.x,
                    }}>
                        <div className='position-relative'>
                            <textarea
                                value={newTextValue}
                                onChange={(e) => setNewTextValue(e.target.value)}
                                rows={3}
                                className='w-100'
                                placeholder='Write your prompt'
                            />
                            <div className='d-flex aalign-items-center gap-2 text-options-wrapper'>
                                <button className='text-options-btn font-noto-sans'>More fun</button>
                                <button className='text-options-btn font-noto-sans'>More formal</button>
                                <button className='text-options-btn font-noto-sans'>Shorten</button>
                            </div>
                        </div>
                        <button className="d-flex align-items-center justify-content-center gap-2 px-4 border-0 black-gradiant-btn py-md-2 py-1 rounded-pill mt-2"
                            onClick={handleRewrite} style={{ height: "auto" }}>
                            <img src={aiIcon} alt="aiIcon" />
                            <span>Rewrite</span>
                        </button>
                    </div>
                )}

                {showSideModal && (
                    <div>
                        {selectedId && selectedId.includes('text') && (
                            <div className='border-right ps-md-3 pe-md-3 pb-md-3 ps-2 pe-2 pb-2 pt-0 bg-white font-noto-sans tabs-right-conatainer '>
                                <div className='d-flex align-items-center justify-content-between pb-2 pt-3  bg-white position-sticky top-0'>
                                    <h5 className='fs-16 font-noto-sans fw-bolder mb-0'>Text layer name</h5>
                                </div>
                                <button
                                    className='d-flex align-items-center justify-content-center gap-2 px-4 border-0 black-gradiant-btn py-2 rounded-pill my-3 w-100'
                                >
                                    <img src={aiIcon} alt='aiIcon' />
                                    <span>MicoAI Rewriter</span>
                                </button>
                                <h5 className='font-noto-sans fs-14 mt-4 fw-bolder'>Typography</h5>
                                <div className='appearance-content border-bottom-0 pb-2'>
                                    <div className='w-100 d-flex flex-column gap-2 mt-3'>
                                        <select className="form-select" onChange={handleFontChange} value={fontFamily}>
                                            {fonts.map((font) => (
                                                <option key={font.family} value={font.family}>
                                                    {font.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='w-100 d-flex gap-3 align-items-center mt-3'>
                                        <div className='w-100 d-flex flex-column gap-2'>
                                            <select className="form-select" id="inputGroupSelect01">
                                                <option selected>Regular</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                        <div className='position-relative'>
                                            <input className='form-control px-2 text-center' placeholder='size' type='number' min='0' max='100' value={fontSize} onChange={handleFontSizeChange} />
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between gap-md-3 gap-2 mt-3'>
                                        <div className='d-flex align-items-center gap-md-3 gap-2'>
                                            <button
                                                className={`bg-transparent border-0 p-0`}
                                                onClick={(e) => textFontStyleClick(e, 'bold')}
                                            >
                                                <img src={boldIcon} alt='boldIcon' />
                                            </button>
                                            <button
                                                className={`bg-transparent border-0 p-0`}
                                                onClick={(e) => textFontStyleClick(e, 'italic')}
                                            >
                                                <img src={italicIcon} alt='italicIcon' />
                                            </button>
                                            <button
                                                className={`bg-transparent border-0 p-0`}
                                                onClick={(e) => textFontStyleClick(e, 'underline')}
                                            >
                                                <img src={underline} alt='underline' />
                                            </button>
                                        </div>

                                        <div className="header_Bigline" style={{ width: "2px" }}></div>
                                        <div className='d-flex align-items-center gap-md-3 gap-2'>
                                            <button className='bg-transparent border-0 p-0'>
                                                <img src={alignManu} alt='alignManu' />
                                            </button>
                                            <button className='bg-transparent border-0 p-0'>
                                                <img src={formatList} alt='formatList' />
                                            </button>
                                            <button className='bg-transparent border-0 p-0'>
                                                <img src={formatLine} alt='formatLine' />
                                            </button>
                                        </div>
                                    </div>

                                </div>
                                <h5 className='font-noto-sans fs-14 mt-3 fw-bolder'>Appearance</h5>
                                <div className='appearance-content border-bottom-0 pb-2'>
                                    <div className='d-flex align-items-center gap-md-3 gap-2'>
                                        <div className="w-100 d-flex flex-column gap-2 mt-3">
                                            <label for="formControlRange">Opacity</label>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <input type="range" className="form-control-range w-100" style={{ height: "3px", accentColor: "black" }} id="formControlRange" />
                                                <div className='position-relative' style={{ maxWidth: "65px" }}>
                                                    <input className='form-control pe-4 ps-2' type='number' />
                                                    <img src={percentage} alt='percentage' className='percentage' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='w-100 d-flex flex-column gap-2 mt-3'>
                                        <label className='fs-14 font-noto-sans ms-0'>Blend mode</label>
                                        <select className="form-select" id="inputGroupSelect01">
                                            <option selected>Normal</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                    <div className='w-100 d-flex flex-column gap-2 mt-3'>
                                        <label className='fs-14 font-noto-sans ms-0'>Color</label>

                                        <div className='d-flex gap-md-3 gap-2 align-items-center'>
                                            <div className='position-relative'>
                                                <input className='form-control px-2' placeholder='' value={color} type='text' onChange={handleColorChange} />
                                            </div>
                                            <div className='position-relative' style={{ maxWidth: "85px" }}>
                                                <input className='form-control px-4 text-center' type='number' />
                                                <img src={opacity} alt='opacity' className='opacity' />
                                                <img src={percentage} alt='percentage' className='percentage' />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )}

                        {selectedId && selectedId.includes('image') && (
                            <div className='border-right ps-md-3 pe-md-3 pb-md-3 ps-2 pe-2 pb-2 pt-0 bg-white font-noto-sans tabs-right-conatainer '>
                                <div className='d-flex align-items-center justify-content-between pb-2 pt-3  bg-white position-sticky top-0'>
                                    <h5 className='fs-16 font-noto-sans fw-bolder mb-0'>Image layer name</h5>
                                </div>

                                <div className='replace-img-container w-100 position-relative d-flex align-items-center justify-content-center'>
                                    <img src={newDesign} alt='newDesign' className='image' />
                                    <button className='replace-btn d-flex px-3 py-1 border-0 rounded-pill bg-white gap-2 d-flex justify-content-center align-items-center'
                                        data-bs-toggle="modal"
                                        data-bs-target="#addImage"
                                        type="button">
                                        <img src={refresh} alt='refresh' />
                                        <span className="font-noto-sans">replace</span>
                                    </button>
                                </div>
                                <div className="accordion mt-3" id="accordionExample">
                                    <div className="accordion-item border-0">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button collapsed p-0 font-noto-sans" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                Enhance with MicoAI
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div className="accordion-body p-0">
                                                <div className='position-relative'>
                                                    <Swiper
                                                        cssMode={true}
                                                        slidesPerView={3.5}
                                                        spaceBetween={15}
                                                        navigation={true}
                                                        pagination={true}
                                                        mousewheel={true}
                                                        keyboard={true}
                                                        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                                                        className="mySwiper mt-2"
                                                    >
                                                        <SwiperSlide>
                                                            <div className='swipper-container rounded-circle'
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#magicBrush"
                                                                type="button"
                                                            >
                                                                <BootstrapTooltip title="Quickly remove the background from your image with AI.">
                                                                    <img src={newDesign} alt='newDesign' className='w-100 h-100 rounded-circle' />
                                                                </BootstrapTooltip>
                                                                <div className='swipper-btn'>
                                                                    <img src={aiIconBlack} alt='aiIconBlack' />
                                                                </div>
                                                            </div>
                                                            <span className='label font-noto-sans text-center'>BG Remover</span>
                                                        </SwiperSlide>
                                                        <SwiperSlide>
                                                            <div className='swipper-container rounded-circle'>
                                                                <BootstrapTooltip title="Quickly remove the background from your image with AI.">
                                                                    <img src={newDesign} alt='newDesign' className='w-100 h-100 rounded-circle' />
                                                                </BootstrapTooltip>
                                                                <div className='swipper-btn'>
                                                                    <img src={aiIconBlack} alt='aiIconBlack' />
                                                                </div>
                                                            </div>
                                                            <span className='label font-noto-sans text-center'>Smart Edit</span>
                                                        </SwiperSlide>
                                                        <SwiperSlide>
                                                            <div className='swipper-container rounded-circle'>
                                                                <BootstrapTooltip title="Quickly remove the background from your image with AI.">
                                                                    <img src={newDesign} alt='newDesign' className='w-100 h-100 rounded-circle' />
                                                                </BootstrapTooltip>
                                                                <div className='swipper-btn'>
                                                                    <img src={aiIconBlack} alt='aiIconBlack' />
                                                                </div>
                                                            </div>
                                                            <span className='label font-noto-sans text-center'>Magic Brush</span>
                                                        </SwiperSlide>
                                                        <SwiperSlide>
                                                            <div className='swipper-container rounded-circle'>
                                                                <BootstrapTooltip title="Quickly remove the background from your image with AI.">
                                                                    <img src={newDesign} alt='newDesign' className='w-100 h-100 rounded-circle' />
                                                                </BootstrapTooltip>
                                                                <div className='swipper-btn'>
                                                                    <img src={aiIconBlack} alt='aiIconBlack' />
                                                                </div>
                                                            </div>
                                                            <span className='label font-noto-sans text-center'>BG Remover</span>
                                                        </SwiperSlide>
                                                    </Swiper>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <h5 className='font-noto-sans fs-14 mt-3 fw-bolder'>Appearance</h5>
                                <div className='appearance-content'>
                                    <div className='w-100 d-flex gap-md-3 gap-2 align-items-center mt-3'>
                                        <div className='w-100 d-flex flex-column gap-2'>
                                            <label className='fs-14 font-noto-sans ms-0'>Opacity</label>
                                            <div className='position-relative'>
                                                <input className='form-control px-4 text-center' type='number' />
                                                <img src={opacity} alt='opacity' className='opacity' />
                                                <img src={percentage} alt='percentage' className='percentage' />
                                            </div>
                                        </div>
                                        <div className='w-100 d-flex flex-column gap-2'>
                                            <label className='fs-14 font-noto-sans ms-0'>Corner radius</label>
                                            <div className='position-relative'>
                                                <input className='form-control px-4 text-center' type='number' value={cornerRadiusValue} onChange={(e) => { setCornerRadiusValue(parseInt(e.target.value)) }} />
                                                <img src={roundedCorner} alt='roundedCorner' className='roundedCorner' width={20} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-100 d-flex flex-column gap-2 mt-3'>
                                        <label className='fs-14 font-noto-sans ms-0'>Corner radius</label>
                                        <select className="form-select" id="inputGroupSelect01" value={cornerRadius} onChange={(e) => { cornerRadiusChange(e) }}>
                                            <option selected value="0">Normal</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                            <option value="4">Four</option>
                                        </select>
                                    </div>
                                </div>
                                <h5 className='font-noto-sans fs-14 mt-3 fw-bolder'>Layout & Composition</h5>
                                <div className='appearance-content border-bottom-0'>
                                    <div className='w-100 d-flex flex-column gap-2 mt-3'>
                                        <label className='fs-14 font-noto-sans ms-0'>Crop and scale</label>
                                        <select className="form-select" id="inputGroupSelect01">
                                            <option selected>Fit</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                    <div className='d-flex align-items-center gap-4 mt-4'>
                                        <button className='p-0 bg-transparent border-0'>
                                            <img src={revartIcon} alt='revartIcon' />
                                        </button>
                                        <button className='p-0 bg-transparent border-0'>
                                            <img src={notrevartIcon} alt='notrevartIcon' />
                                        </button>
                                        <button className='p-0 bg-transparent border-0'>
                                            <img src={roteteImage} alt='roteteImage' />
                                        </button>
                                        <button className='p-0 bg-transparent border-0'>
                                            <img src={leftroteteImage} alt='leftroteteImage' />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        <MagicBrush />
                    </div>
                )}

                <div>

                    {/* right click big popup */}

                    {contextMenuVisible && (
                        <div className='bg-white text-rightclick-popup position-absolute'
                            style={{
                                top: `${menuPosition.y}px`,
                                left: `${menuPosition.x}px`,
                            }}
                            onClick={hideContextMenu}
                        >
                            <div className='popup-body d-flex gap-3 flex-column'>
                                <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                                    <img src={aiIcon} alt="aiIcon" style={{ filter: "brightness(0)" }} />
                                    <span>BG Remover</span>
                                </button>
                                <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                                    <img src={aiIcon} alt="aiIcon" style={{ filter: "brightness(0)" }} />
                                    <span>Smart edit</span>
                                </button>
                                <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                                    <img src={aiIcon} alt="aiIcon" style={{ filter: "brightness(0)" }} />
                                    <span>Magic brush</span>
                                </button>
                                <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                                    <img src={aiIcon} alt="aiIcon" style={{ filter: "brightness(0)" }} />
                                    <span>Infinite Expand</span>
                                </button>
                                <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                                    <img src={aiIcon} alt="aiIcon" style={{ filter: "brightness(0)" }} />
                                    <span>Upscaler</span>
                                </button>
                            </div>
                            <div className='popup-body d-flex gap-3 flex-column'>

                                <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                                    <img src={refresh} alt="refresh" width={18} style={{ filter: "brightness(0)" }} />
                                    <span>Replace</span>
                                </button>
                                <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                                    <img src={cropIcon} alt="cropIcon" style={{ filter: "brightness(0)" }} />
                                    <span>Crop</span>
                                </button>
                            </div>
                            <div className='popup-body d-flex gap-3 flex-column'>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                                        <img src={copyIcon} alt="copyIcon" />
                                        <span>Copy</span>
                                    </button>
                                    <p className='m-0'>cmd+C</p>
                                </div>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                                        <img src={pastIcon} alt="pastIcon" />
                                        <span>Paste</span>
                                    </button>
                                    <p className='m-0'>cmd+V</p>
                                </div>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                                        <img src={duplicateIocn} alt="duplicateIocn" />
                                        <span>Duplicate</span>
                                    </button>
                                    <p className='m-0'>cmd+D</p>
                                </div>
                            </div>
                            <div className='popup-footer border-bottom-0'>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <button className='border-0 bg-transparent p-0 d-flex gap-2 align-items-center'>
                                        <img src={deleteIocn} alt="deleteIocn" width={20} />
                                        <span>Delete</span>
                                    </button>
                                    <p className='m-0'>del</p>
                                </div>
                            </div>
                        </div>)}
                </div>
                <AddImagePopUp typeChange={typeChange} />
            </div>


            <div className="modal fade" id="add_design" tabIndex="-1" aria-labelledby="add_designLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content px-3">
                        <div className="modal-header border-0">
                            <div className='upload_design'>
                                Upload design
                            </div>
                            <div className='cursor_pointer' data-bs-dismiss="modal">
                                <img src={Close} alt='Close' />
                            </div>
                        </div>
                        <div className="modal-body">
                            <UploadDesignFile
                                screen="visionStudio"
                                layerId={layerId}
                            />
                        </div>
                    </div>
                </div>
            </div>

        


           
        </div>
    );
};

export default ImageComponent;