import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    canvas: {
        id: null,
        layers: [
            {
                id: 'mainLayer',
                groups: [] // This will contain all the sheets/pages
            }
        ]
    }
};

const konvaSlice = createSlice({
    name: 'konva',
    initialState,
    reducers: {
        initializeCanvas: (state, action) => {
            state.canvas = {
                id: action.payload.id,
                layers: [
                    {
                        id: 'mainLayer',
                        groups: (action.payload.sheets || []).map((sheet, index) => ({
                            id: sheet.sheet_id || sheet.id,
                            name: sheet.sheet_name || "Untitled",
                            status_id: sheet.sheet_status_id || 1,
                            is_locked: sheet.sheet_is_locked || false,
                            position: { 
                                x: 50 + (index * 600), // Position each sheet horizontally with 600px spacing
                                y: 50 
                            },
                            elements: sheet.layers?.map(layer => ({
                                id: layer.id,
                                type: 'Image',
                                sheet_id: sheet.sheet_id || sheet.id,
                                x: 0,
                                y: 0,
                                width: 500,
                                height: 500,
                                image_data: {
                                    data: layer.image_data?.data || '',
                                    format: layer.image_data?.format || 'PNG',
                                    size: layer.image_data?.size || [500, 500]
                                },
                                draggable: false
                            })) || []
                        }))
                    }
                ]
            };
        },

        addSheet: (state, action) => {
            const newSheet = {
                id: action.payload.sheet_id,
                name: action.payload.sheet_name,
                status_id: action.payload.sheet_status_id,
                is_locked: action.payload.sheet_is_locked,
                position: {
                    x: 50 + (state.canvas.layers[0].groups.length * 600),
                    y: 50
                },
                elements: [],
                layers: action.payload.layers || []
            };
            state.canvas.layers[0].groups.push(newSheet);
        },

        removeSheet: (state, action) => {
            const sheetId = action.payload;
            if (state.canvas?.layers?.[0]?.groups) {
                state.canvas.layers[0].groups = state.canvas.layers[0].groups.filter(
                    group => group.id !== sheetId
                );
            }
        },

        updateSheetPosition: (state, action) => {
            const { sheetId, position } = action.payload;
            const sheet = state.canvas.layers[0].groups.find(g => g.id === sheetId);
            if (sheet) {
                sheet.position = position;
            }
        },

        updateSheetStatus: (state, action) => {
            const { sheetId, status } = action.payload;
            const sheet = state.canvas.layers[0].groups.find(g => g.id === sheetId);
            if (sheet) {
                sheet.status_id = status;
                sheet.is_locked = status === 2 || status === 3;
            }
        },

        addElement: (state, action) => {
            const { sheetId, element } = action.payload;
            const sheet = state.canvas.layers[0].groups.find(g => g.id === sheetId);
            if (sheet) {
                sheet.elements.push(element);
            }
        },

        updateElement: (state, action) => {
            const { sheetId, elementId, newProps } = action.payload;
            const sheet = state.canvas.layers[0].groups.find(g => g.id === sheetId);
            if (sheet) {
                const element = sheet.elements.find(el => el.id === elementId);
                if (element) {
                    element.props = { ...element.props, ...newProps };
                }
            }
        },

        removeElement: (state, action) => {
            const { sheetId, elementId } = action.payload;
            const sheet = state.canvas.layers[0].groups.find(g => g.id === sheetId);
            if (sheet) {
                sheet.elements = sheet.elements.filter(el => el.id !== elementId);
            }
        },

        updateSheetName: (state, action) => {
            const { sheetId, name } = action.payload;
            const sheet = state.canvas.layers[0].groups.find(g => g.id === sheetId);
            if (sheet) {
                sheet.name = name;
            }
        },

        updateSheetLock: (state, action) => {
            const { sheetId, is_locked } = action.payload;
            const sheet = state.canvas.layers[0].groups.find(g => g.id === sheetId);
            if (sheet) {
                sheet.is_locked = is_locked;
            }
        },

        resetKonvaState: (state) => {
            // Reset to initial state
            state.canvas = {
                id: null,
                layers: [
                    {
                        id: 'mainLayer',
                        groups: []
                    }
                ]
            };
        },
    }
});

export const {
    initializeCanvas,
    addSheet,
    removeSheet,
    updateSheetPosition,
    updateSheetStatus,
    updateSheetName,
    addElement,
    updateElement,
    removeElement,
    updateSheetLock,
    resetKonvaState
} = konvaSlice.actions;

export default konvaSlice.reducer;
