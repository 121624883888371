import React, { useEffect, useState } from "react";
import CommentAndTask from "./component/CommentAndTask";
import closeIcon from "../../assets/newicons/close.svg";
import frameImage from "../../assets/newicons/Frame_image.png";
import plus from "../../assets/newicons/variant-plus.svg";
import firstPage from "../../assets/newicons/first_page.svg";
import folderIcon from "../../assets/newicons/folderIcon.svg";
import comments from "../../assets/newicons/comments.svg";
import { useNavigate, useParams } from "react-router-dom";

import { useLocation } from "react-router-dom";
import AnnotatingImage from "./component/AnnotatingImage";
import { Paginator } from "primereact/paginator";
import { classNames } from "primereact/utils";

import ReferenceGrid from "./component/ReferenceGrid";
import DesignGrid from "./component/DesignGrid";
import ReferenceComp from "./component/ReferenceComp";
import { useTranslation } from "react-i18next";
import { newGet, newImagePost, newPost, newPut } from "../../API/axios";
import { showAlert } from "./component/AlertService";
import { useReferenceContext } from "../../context/useReferenceContext";
import headerSetting from "../../assets/newicons/header-setting.svg";
import ArrowBack from "../../assets/newicons/arrow_back.svg";
import { InputSwitch } from "primereact/inputswitch";

import axios from "axios";
import SideMenu from "./component/SideMenu";
import { use } from "i18next";
import { Tooltip } from "@mui/material";
import { useRef } from "react";
import { useAuthContext } from "../../context/useAuthContext";

const NewImageAnnotate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const queryParams = new URLSearchParams(location.search); // Parse the query string
  const project_id = queryParams.get("project_id");

  const autoSaveRefLink = useRef()
  const autoSaveRefChecked = useRef()
  const urlPattern = /^https:\/\/miro\.com\/app.*/;

  let {
    selectedImages,
    createdProjectId,
    channelId,
    projectName,
    channelName,
  } = location.state || {};
  const [ProjectId, setCreatedProjectId] = useState(createdProjectId);
  const [siteId, setSiteId] = useState(channelId);
  const [showCommentSection, setShowCommentSection] = useState(true);

  const [first, setFirst] = useState(0);
  const [activeImag, setActiveImage] = useState([]);
  const [isGridView, setIsGridView] = useState(false);
  const [isInternal, setIsInternal] = useState(true);
  const [zoomLevel, setZoomLevel] = useState("10%");
  const [referenceList, setReferenceList] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const { referencesList, setReferencesList } = useReferenceContext();
  const [isExcalidrawChanged, setIsExcalidrawChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    excalidrawAPI,
    miroValue,
    setMiroValue,
    miroLink,
    setMiroLink,
    setExcalidrawLoading,
    excalidrawLoading,
    boardId,
    setBoardId,
    projectDetails,
    setProjectDetails
  } = useReferenceContext();
  const { selectedSite } = useAuthContext();
  const [anotationData, setAnotationData] = useState("");
  const [referenceComments, setReferenceComments] = useState([
    {
      image_url: "",
      project_id: ProjectId,
      comments: [],
    },
  ]);

  const [showMenu, setShowMenu] = useState(true);
  // const [selectedImage, setSelectedImages] = useState([]);
  const [typeChange, setTypeChange] = useState("");
  const [selectedFont, setSelectedFont] = useState("");
  const [checked, setChecked] = useState(false);
  const [showAlertPopUp, setShowAlertPopUp] = useState(false);

  const backToReference = () => {
    // if (!isExcalidrawChanged) {

    const urlParams = new URLSearchParams(window.location.search);
    const projectIdFromUrl = urlParams.get("project_id");
    navigate(
      `/reference?project_id=${(ProjectId || createdProjectId || projectIdFromUrl)}&site_id=${channelId}`,
      {
        state: {
          siteId: channelId,
          createdProjectId: ProjectId || createdProjectId,
        },
      }
    );
    // setShowAlertPopUp(true);

    // setIsExcalidrawChanged(false);
    // if (isExcalidrawChanged) {
    // autoSaveRefrences();
    submitRefrences()
    // }

    // else {
    // const modal = new window.bootstrap.Modal(document.getElementById("saveExcalidraw"));
    // modal.show();
    // }
  };

  const isProjectActive = () => {
    if (!projectDetails?.status) return true; // Default to active if no status
    
    const statusId = parseInt(Object.keys(projectDetails.status)[0]);
    
    // Status 5 means completed/cancelled project, status 3 means inactive
    return statusId !== 5 && statusId !== 3;
  };


  
  useEffect(() => {
    const getProjectDetail = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const projectIdFromUrl = urlParams.get("project_id");
      const storedSiteId = selectedSite?.site_id;
      
      if ((createdProjectId || projectIdFromUrl) && (channelId || storedSiteId) && !projectDetails) {
        try {
          const data = await newGet(
            `project/getProjectDetails?project_id=${createdProjectId || parseInt(projectIdFromUrl)}&site_id=${channelId || parseInt(storedSiteId)}`
          );
          
          if (data?.message[0].property_message === "PROJECT_NOT_FOUND") {
            navigate('/dashboard');
            return;
          }

          setProjectDetails(data?.result[0]);
          
        } catch (error) {
          console.log("error fetching project details:", error);
        }
      }
    };

    getProjectDetail();
  }, [selectedSite]);



  const navigateToReference = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const projectIdFromUrl = urlParams.get("project_id");
    navigate(
      `/reference?project_id=${(ProjectId || createdProjectId || projectIdFromUrl)}&site_id=${channelId}`,
      {
        state: {
          siteId: channelId,
          createdProjectId: ProjectId || createdProjectId,
        },
      }
    );
    setIsExcalidrawChanged(false);
  };

  const getReferences = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const projectIdFromUrl = urlParams.get("project_id");
      if (!(projectIdFromUrl || createdProjectId)) {
        return;
      }
      const data = await newGet(
        // `reference/fetchReferences?project_id=${projectIdFromUrl || createdProjectId}`
        `board/get?project_id=${projectIdFromUrl || createdProjectId}`
      ).then((data) => {
        console.log("data:", data);
        if (data && data.status_code == 200) {
          setMiroValue(data?.result?.miro_link);
          autoSaveRefLink.current = data?.result?.miro_link || ""
          setChecked(!!data?.result?.is_external)
          autoSaveRefChecked.current = !!data?.result?.is_external
          if(data?.result?.miro_link && data?.result?.is_external && urlPattern.test(data?.result?.miro_link)){
            setIsInternal(false)
          }
          setReferenceList(data?.result?.files);
          setMiroLink(data?.result?.miro_link);
          setBoardId(data?.result?.board_id);
          setReferencesList(data?.result);
          if (data?.result?.board_json) {
            const parsedData = JSON.parse(data.result.board_json);

            const updatedData = {
              ...parsedData,
              appState: {
                ...(parsedData.appState || {}),
              },
            };
            setAnotationData(updatedData);
            //   setE(updatedData);
          } else {
            setExcalidrawLoading(false);
          }
        } else {
          navigate('/dashboard');
        }
      });
    } catch (error) {
      console.log("error:???????", error);
    }
  };
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get("project_name")) {
    projectName = decodeURIComponent(urlParams.get("project_name"));
  }
  if (urlParams.get("channel_name")) {
    channelName = decodeURIComponent(urlParams.get("channel_name"));
  }
  if (urlParams.get("project_id")) {
    createdProjectId = urlParams.get("project_id");
  }
  if (urlParams.get("channel_id")) {
    channelId = urlParams.get("channel_id");
  }
  useEffect(() => {
    // if (miroValue && checked) {
    //   modeChange(false);
    // }
    getReferences();
  }, []);

  useEffect(() => {

    if (miroValue && checked) {
      if (urlPattern.test(miroValue)) {
        // setIsInternal(false);
      }
    }
    // else if(miroValue && !checked){
    //   setIsInternal(true);
    // }
    // else{
    //   setIsInternal(true);
    // }
  }, [miroValue, checked]);

  // useEffect(() => {
  //   if (!checked && miroValue ) {
  //     setIsInternal(true);
  //   }
  //   // else {
  //   //   setIsInternal(true);
  //   // }
  // }, [checked]);

  // useEffect(() => {
  //   console.log('miroValue:', miroValue==="");
  //    if(miroValue!==''){
  //     setChecked(true);
  //    }
  // }, [miroValue]);

  const toggleInputSwitch = () => {
    if (miroValue && checked) {
      setChecked(true);
    }
  }


  useEffect(() => {
    if (selectedImages?.length > 0) {
      if (selectedImages[0]?.board_id) {
        setReferenceComments(selectedImages);
        setActiveImage(selectedImages);
      } else {
        setActiveImage(
          selectedImages.length === 1 ? [selectedImages[0]] : selectedImages
        );
      }
    }
  }, []);

  const onPageChange = (event) => {
    setFirst(event.first);
    setActiveImage(selectedImages[event.first]);
  };

  const template1 = {
    layout:
      "FirstPageLink PrevPageLink CurrentPageReport  NextPageLink LastPageLink",
    FirstPageLink: (options) => {
      return (
        <button
          type="button"
          className={classNames(options.className, "border-round")}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <img src={firstPage} alt="viewArrat" className="p-1" />
        </button>
      );
    },
    LastPageLink: (options) => {
      return (
        <button
          type="button"
          className={classNames(options.className, "border-round")}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <img
            src={firstPage}
            alt="viewArrat"
            className="p-1 "
            style={{ transform: "rotate(180deg)" }}
          />
        </button>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "50px",
            textAlign: "center",
          }}
        >
          {options.first} of {options.totalRecords}
        </span>
      );
    },
  };

  const handleZoomChange = (e) => {
    setZoomLevel(e.target.value);
  };
  // console.log('anotationData:', anotationData)
  const onHandleExcalidrawChanges = (data) => {
    setIsExcalidrawChanged(data);
    // if(data1?.length > anotationData?.elements?.length){
    //     setIsExcalidrawChanged(true);
    // }
    // else{
    //     setIsExcalidrawChanged(false);
    // }
  };

  // const submitRefrences = async () => {
  //     // file upload
  //     const getBase64ImageList = referenceList.filter(image => image?.reference_image_url.includes('base64'));

  //     if (getBase64ImageList.length > 0) {
  //         try {
  //             const formData = new FormData();
  //             for (let i = 0; i < getBase64ImageList.length; i++) {
  //                 formData.append('files', getBase64ImageList[i].file);
  //             }
  //             await newImagePost(`upload`, formData).then((res) => {
  //                 console.log(res);
  //             })
  //         } catch (error) {
  //             console.error("Error:-", error);
  //             return;
  //         }
  //     }

  //     const updatedList = referenceList.map(item => {
  //         if (item.file) {
  //             const { file, ...rest } = item; // Remove 'file' key from the object
  //             return rest;
  //         }
  //         return item; // If no 'file' key, return the object as is
  //     });

  //     setReferenceList(updatedList);

  //     const referencesToCreate = referenceList.filter(image => !image.reference_id).map(image => ({
  //         image_url: image.reference_image_url || image?.image_url,
  //         project_id: image.project_id || (ProjectId || createdProjectId),
  //     }));

  //     const excalidrawArray = document.excalidrawAPI.getSceneElements();

  //     const excalidrafile = document.excalidrawAPI.getFiles();

  //     const updatedElements = excalidrawArray.map(element => {
  //         const matchingFile = excalidrafile[element.image_id];

  //         if (matchingFile) {
  //             return {
  //                 ...element,
  //                 dataURL: element.dataURL
  //             };
  //         }

  //         return element;
  //     });

  //     const updatedFiles = { ...excalidrafile };
  //     const fileList = {}

  //     updatedElements.forEach(element => {
  //         const { image_id, dataURL } = element;

  //         if (updatedFiles[image_id]) {
  //             fileList[image_id] = {
  //                 ...updatedFiles[image_id],
  //                 dataURL: dataURL || updatedFiles[image_id].dataURL
  //             };
  //             // updatedFiles[image_id] = {
  //             //     ...updatedFiles[image_id],
  //             //     dataURL: dataURL || updatedFiles[image_id].dataURL
  //             // };
  //         }
  //     });
  //     const result = {
  //         elements: updatedElements,
  //         files: fileList
  //     };

  //     await newPost('annotation/upsert', {
  //         "project_id": (ProjectId || createdProjectId),
  //         "annotation_json": JSON.stringify(result),
  //         "miro_link": miroLink
  //     }).then(async (response) => {
  //         if (response && response.status_code == 200) {
  //             // showAlert('Annotation saved successfully', 'success');
  //             if (referencesToCreate.length > 0) {
  //                 try {
  //                     await newPost('reference/create', { references: referencesToCreate }).then((res) => {
  //                         showAlert('References Saved successfully', 'success');

  //                     });

  //                 } catch (error) {

  //                 }
  //             } else {
  //                 showAlert('References Saved successfully', 'success');
  //             }
  //         }
  //     });

  // }

  const isValidBase64 = (str) => {
    if (!str || typeof str !== "string") {
      return false; // Must be a non-empty string
    }

    // Base64 regex (allows padding with = or ==)
    const base64Regex =
      /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
    return base64Regex.test(str);
  };

  const submitRefrences = async () => {
    let updatedReferenceList = [...referenceList]; // Work directly with a copy of referenceList
    console.log('updatedReferenceList:>>> ', updatedReferenceList)
    // Filter out base64 images

    const base64Images = updatedReferenceList.filter((image) =>
      image?.file_url?.includes("base64")
    );
    if (base64Images?.length > 0) {
      try {
        const formData = new FormData();

        // Append files to formData
        base64Images.forEach((image) => {
          if (image.file) {
            formData.append("files", image.file);
          } else {
            console.error("No file found for image:", image);
          }
        });

        // Upload all base64 images at once
        const res = await newImagePost("upload", formData)
        // .then((res) => {
        console.log("ressssss", res);
        if (res && [201, 200].includes(+res.status_code)) {
          if (res && res.result) {
            const uploadedUrls = res.result; // Extract and split URLs
            console.log("uploadedUrls:>>> ", uploadedUrls);

            // console.log("updatedReferenceList", updatedReferenceList)

            // Update the reference_image_url with the uploaded URLs
            base64Images.forEach((image, index) => {
              image.file_url = uploadedUrls[index];
            });

            // Update referenceList with the returned URLs
            updatedReferenceList = updatedReferenceList.map((image) => {
              const base64Image = base64Images.find(
                (base64Img) => base64Img.file_url === image.file_url
              );
              return base64Image ? base64Image : image;
            });
          }
        }
        // });
      }
      catch (error) {
        console.error("Error uploading images:", error);
        return; // Exit the function on error
      }
    }
    console.log("updatedReferenceList", updatedReferenceList);
    // Create references from updatedReferenceList
    const referencesToCreate = updatedReferenceList
      .filter((image) => {
        console.log("Filtering image:", image); // Log during filtering
        return !image.board_id; // Keep images without a board_id
      })
      .map((image) => {
        console.log("image", image);
        return {
          image_url:
            image.file_url || image?.image_url,
          project_id: image.project_id || ProjectId || createdProjectId,
        };
      });
    console.log("updatedReferenceList", updatedReferenceList);
    console.log("referencesToCreate:>>> ", referencesToCreate);

    // Get the current scene elements and files from Excalidraw
    // const ssk = document.excalidrawAPI.getSceneElements();
    // if (!ssk || !ssk.length) {
    //     console.log("excalidra", ssk);
    // } else {
    //   console.log("excalidra info", ssk);
    // }
    const excalidrawElements = document.excalidrawAPI.getSceneElements();
    const excalidrawFiles = document.excalidrawAPI.getFiles();

    console.log("excalidrawElements", excalidrawElements);
    console.log("excalidrawFiles", excalidrawFiles);

   // Update elements with the corresponding reference image URL
    const updatedElements = excalidrawElements.map((element) => {
      console.log("int run");
      const linkedReference = referenceList.find(
        (ref) => (ref.fileId == element.fileId || ref.file_id == element.fileId)
      );
      const matchingFile = excalidrawFiles[element.image_id];
  
      if(matchingFile){
        return {
          ...element,
          dataURL: element?.dataURL || linkedReference?.file_url, // Use uploaded reference URL if applicable
        };
      }else{
        return element
      }
    });

    // Prepare updated files with dataURLs
    const updatedFiles = { ...excalidrawFiles };
    const fileList = {};

    updatedElements.forEach(({ image_id, dataURL }) => {
      if (updatedFiles[image_id]) {
        fileList[image_id] = {
          ...updatedFiles[image_id],
          dataURL: dataURL || updatedFiles[image_id].dataURL,
        };
      }
    });

    // Now remove files where the dataURL starts with 'data:image'
    Object.keys(fileList).forEach((image_id) => {
      if (fileList[image_id].dataURL.startsWith("data:image")) {
        delete fileList[image_id]; // Remove the file from the fileList
      }
    });

    const result = {
      elements: updatedElements,
      files: fileList,
    };
    console.log("result:>>> ", result, updatedElements, fileList);
    setIsSaving(true);
    // Upsert the annotation data
    try {
      const response = await newPost("board/updateInsertBoard", {
        project_id: +(project_id || ProjectId || createdProjectId),
        board_json: JSON.stringify(result),
        miro_link: miroLink,
        is_external: checked
      });
      // if (response?.status_code === 200 || response?.status_code === 201) {
      //     showAlert('Workspace created successfully', 'success');
      // }
      // if (response?.status_code === 200 || response?.status_code === 201) {
      //     if (referencesToCreate.length > 0) {
      //         try {
      //             await newPost('reference/create', { references: referencesToCreate });
      //             // if (showAlertPopUp) {
      //             showAlert(i18n.t(response?.message[0]?.property_message), 'success');
      //             // }
      //         } catch (error) {
      //             console.error('Error saving references:', error);
      //         }
      //     } else {
      //         // showAlert('References Saved successfully', 'success');
      //         // if (showAlertPopUp) {
      //         showAlert(i18n.t(response?.message[0]?.property_message), 'success');
      //         // }
      //     }
      // }
    } catch (error) {
      console.error("Error upserting annotation data:", error);
    } finally {
      setIsSaving(false); // Hide the saving indicator
    }
  };

  const autoSaveRefrences = async () => {

    // console.log('updatedReferenceList:>>> ', updatedReferenceList)
    // Filter out base64 images
    const base64Images = referenceList.filter((image) =>
      image?.file_url?.includes("base64")
    );
    if (base64Images?.length > 0) {
      try {
        const formData = new FormData();

        // Append files to formData
        base64Images.forEach((image) => {
          formData.append("files", image.file);
        });

        // Upload all base64 images at once
        const uploadResponse = await newImagePost("file/upload", formData).then(
          (res) => {
            if (res && [201, 200].includes(+res.status_code)) {
              if (res && Array.isArray(res.result)) {
                const uploadedUrls = res.result
                  .replace("urls: ", "")
                  .split(","); // Extract and split URLs

                // Update referenceList with the returned URLs
                setReferenceList(referenceList.map((item) => {
                  if (item.file_url.includes("base64")) {
                    const uploadedUrl = uploadedUrls.shift(); // Get the next uploaded URL
                    return {
                      ...item,
                      file_url: uploadedUrl, // Replace base64 URL with uploaded URL
                      file: undefined, // Remove file from the item
                    };
                  }
                  return item;
                }));
              }
            }
          }
        );
      } catch (error) {
        console.error("Error uploading images:", error);
        return; // Exit the function on error
      }
    }

  

    // Get the current scene elements and files from Excalidraw
    const excalidrawElements = document.excalidrawAPI.getSceneElements();
    const excalidrawFiles = document.excalidrawAPI.getFiles();

    console.log("excalidrawElements", excalidrawElements);
    console.log("excalidrawFiles", excalidrawFiles);
    // Update elements with the corresponding reference image URL
    const updatedElements = excalidrawElements.map((element) => {
      console.log("int run");
      const linkedReference = referenceList.find(
        (ref) => (ref?.fileId == element?.fileId || ref?.file_id == element?.fileId)
      );
      const matchingFile = excalidrawFiles[element.image_id];
   
      if(matchingFile){
        return {
          ...element,
          dataURL: element?.dataURL || linkedReference?.file_url, // Use uploaded reference URL if applicable
        };
      }else{
        return element
      }
    });

    // Prepare updated files with dataURLs
    const updatedFiles = { ...excalidrawFiles };
    const fileList = {};

    updatedElements.forEach(({ image_id, dataURL }) => {
      if (updatedFiles[image_id]) {
        fileList[image_id] = {
          ...updatedFiles[image_id],
          dataURL: dataURL || updatedFiles[image_id].dataURL,
        };
      }
    });

    // Now remove files where the dataURL starts with 'data:image'
    Object.keys(fileList).forEach((image_id) => {
      if (fileList[image_id].dataURL.startsWith("data:image")) {
        delete fileList[image_id]; // Remove the file from the fileList
      }
    });

    const result = {
      elements: updatedElements,
      files: fileList,
    };
    setIsSaving(true);
    // Upsert the annotation data
    try {
      const response = await newPost("board/updateInsertBoard", {
        project_id: +(project_id || ProjectId || createdProjectId),
        board_json: JSON.stringify(result),
        miro_link: autoSaveRefLink.current,
        is_external: autoSaveRefChecked.current
      });
      // if (response?.status_code === 200 || response?.status_code === 201) {
      //     showAlert('Workspace created successfully', 'success');
      // }
      // if (response?.status_code === 200 || response?.status_code === 201) {
      //     if (referencesToCreate.length > 0) {
      //         try {
      //             await newPost('reference/create', { references: referencesToCreate });
      //             // if (showAlertPopUp) {
      //             // showAlert(i18n.t(response?.message[0]?.property_message), 'success');
      //             // }
      //         } catch (error) {
      //             console.error('Error saving references:', error);
      //         }
      //     } else {
      //         // showAlert('References Saved successfully', 'success');
      //         // if (showAlertPopUp) {
      //         // showAlert(i18n.t(response?.message[0]?.property_message), 'success');
      //         // }
      //     }
      // }
    } catch (error) {
      console.error("Error upserting annotation data:", error);
    } finally {
      setIsSaving(false); // Hide the saving indicator
    }
  };

  useEffect(() => {
    const interval = setInterval(autoSaveRefrences, 10000);  // Save every 5 seconds
    return () => clearInterval(interval);
  }, [isExcalidrawChanged])

  // useEffect(() => {
  //   autoSaveRefLink.current = miroLink
  //   autoSaveRefChecked.current = checked
  // }, [miroLink, checked])

  const modeChange = (value) => {
    setIsInternal(value);
  };

  const switchVisionBoard = (e) => {

    // const isChecked = e.target.value;
    setChecked(e.target.value);
    // if (miroValue) {
    //   setIsInternal(!isChecked);
    // }
  };

  console.log("isInternal",isInternal)

  // const createJson = () => {
  //     const excalidrawArray = document.excalidrawAPI.getSceneElements();
  //     const excalidrafile = document.excalidrawAPI.getFiles();
  //     const updatedElements = excalidrawArray.map(element => {
  //         const matchingFile = excalidrafile[element.image_id];

  //         if (matchingFile) {
  //             return {
  //                 ...element,
  //                 dataURL: element.dataURL
  //             };
  //         }
  //         return element;
  //     });

  //     const updatedFiles = { ...excalidrafile };

  //     updatedElements.forEach(element => {
  //         const { image_id, dataURL } = element;

  //         if (updatedFiles[image_id]) {
  //             updatedFiles[image_id] = {
  //                 ...updatedFiles[image_id],
  //                 dataURL
  //             };
  //         }
  //     });

  //     const result = {
  //         elements: updatedElements,
  //         files: updatedFiles
  //     };
  //     setElement(result);
  // }

  const checkMiroLogin = async () => {
    try {
      const response = await fetch('https://miro.com/api/auth-check', {
        credentials: 'include',
      });
      return response.ok;
    } catch (error) {
      console.error("Error checking Miro login status:", error);
      return false;
    }
  };

  // const submitMiroLink = async (link) => {
  //     const isAuthenticated = await checkMiroLogin();
  //     if (!isAuthenticated) {
  //         alert("You need to log in to Miro first!");
  //         window.location.href = "https://miro.com/login";
  //         return;
  //     }

  //     try {
  //         const response = await fetch(link, {
  //             method: "GET",
  //             redirect: "manual",
  //             credentials: "include",
  //         });

  //         if (response.ok) {
  //             showAlert("Miro link submitted successfully", "success");
  //             setMiroLink(link);
  //         } else if (response.status === 302) {
  //             alert("Redirecting to Miro login...");
  //             window.location.href = response.headers.get("Location");
  //         } else {
  //             showAlert("Failed to access Miro link. Please check your link or login status.", "error");
  //         }
  //     } catch (error) {
  //         console.error("Error submitting Miro link:", error);
  //         showAlert("An unexpected error occurred. Please try again.", "error");
  //     }
  // };






  const submitMiroLink = async (link, saveValue=false) => {
    if (checked) {
      const urlPattern = /^https:\/\/miro\.com\/app.*/;
      if (link.length > 500) {
        showAlert("Miro link is too long", "error");
        return;
      }
      if (!urlPattern.test(link)) {
        showAlert("Invalid Miro link", "error");
        return;
      }
      if (!urlPattern.test(link)) {
        showAlert("Invalid Miro link", "error");
        return;
      }
      setMiroLink(link);
    }
    if(!saveValue) return
    autoSaveRefLink.current = link
    autoSaveRefChecked.current = checked  
    
    try {
      const response = await newPost("board/updateInsertBoard", {
        project_id: +(project_id || ProjectId),
        miro_link: link,
        is_external: checked
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsSaving(false); // Hide the saving indicator
      modeChange(!checked);
    }

    // // Trim the link to only include the base URL up to the board ID
    // const url = new URL(link);
    // const trimmedLink = `${url.origin}${url.pathname.split('?')[0]}/`;

    // setMiroLink(trimmedLink);
    // setMiroLink(link);

    // try {
    //   const response = await fetch(link);
    //   setMiroLink(link);
    //   console.log("response:", response.status);
    //   // if (response.status === 200) {
    //   //     console.log('response:', response);
    //   //     setMiroLink(link);
    //   // }
    //   // else {
    //   //     showAlert('Invalid Miro link', 'error');
    //   // }
    // } catch (error) {
    //   return false; // if network error occurs
    // }

    // try {
    //     await newPut('annotation/update', {
    //         project_id: ProjectId || createdProjectId,
    //         miro_link: link
    //     }).then((response) => {
    //         if (response && response.status_code == 200) {
    //             showAlert('Miro link submitted successfully', 'success');
    //             setMiroLink(link);
    //         }

    //     });
    // } catch (error) {
    //     showAlert('Failed to submit Miro link', 'error');
    // }
  };

  const handleImageUploadClick = (e, image) => {
    e.preventDefault();
    if (!showMenu) {
      setShowMenu(true);
    }
    setTypeChange(image);
  };

  const deleteImages = (id) => {
    const updatedReferenceList = referenceList.filter(image => image.file_id !== id);
    setReferenceList(updatedReferenceList);
    if (document?.excalidrawAPI) {
      const { getSceneElements, updateScene, getFiles } = document?.excalidrawAPI;
      const updatedElements = getSceneElements().filter(element => element.type !== 'image' || element.fileId !== id);
      const updatedFiles = Object.fromEntries(Object.entries(getFiles()).filter(([fileId]) => fileId !== id));

      updateScene({ elements: updatedElements, files: updatedFiles });
    }
  }

  const getButtonDisabled = () => {
    if (!checked) {
      return !autoSaveRefChecked.current;
    }

    if (checked && (!miroValue || !urlPattern.test(miroValue))) {
      return true;
    }

    return autoSaveRefChecked.current && miroValue === autoSaveRefLink.current;
  }

  const cancelSwitchPopup = () => {
    setChecked(autoSaveRefChecked.current)
    setMiroLink(autoSaveRefLink.current)
    setMiroValue(autoSaveRefLink.current)
  }

  return (
    <div >
      <div className={`${isGridView ? "d-none" : "d-block"}`}>
        <div
          className="p-3 border-bottom header-border-radius d-flex align-items-center justify-content-between px-sm-4 px-2 flex-wrap"
          style={{ backgroundColor: "#f6f6f6", borderRadius: "10px" }}
        >
          <div className="d-flex align-items-center gap-3 w-100 justify-content-between flex-wrap">
            {/* <div className="d-flex gap-4 align-items-center fw-bold font-noto-sans pagination-top">
                            <span className="font-noto-sans fs-14 text-dark fw-500">
                                {activeImag[0]?.image_url?.split('/').pop()}
                            </span>
                        </div> */}
            {/* <div className='rounded-pill py-1 px-3 fs-12 bg-color-gray'>Active</div> */}

            {/* {!isInternal ? (
                            <div className="pagination-style-top pagination-top d-sm-block d-none">
                                <div className="">
                                    <div className="input-group" >
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Miro link"
                                            aria-label="Enter Miro link"
                                            aria-describedby="button-addon2"
                                            value={miroValue}
                                            onChange={(e) => setMiroValue(e.target.value)}
                                            style={{ borderRadius: "0", borderRight: "none" }}
                                        />
                                        <button
                                            className="btn btn-outline-secondary"
                                            type="button"
                                            id="button-addon2"
                                            onClick={() => submitMiroLink(miroValue)}
                                            style={{ backgroundColor: "#000", color: "white" }}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : null} */}
            {/* <div>
                            <p>Site Name</p>
                        </div> */}
            <div className="d-flex align-items-center ">
              <img
                src={ArrowBack}
                alt="no_image"
                width={24}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowAlertPopUp(true);
                  backToReference();
                }}
              // data-bs-toggle="modal" data-bs-target="#saveExcalidraw"
              />
              <div
                className="d-flex align-items-center  mx-4"
                style={{ marginBottom: "-15px" }}
              >
                <Tooltip
                  title={
                    <div>
                      <span>{channelName}</span>
                    </div>
                  }
                  slotProps={{
                    popper: {
                      modifiers: [
                        { name: "offset", options: { offset: [0, -12] } },
                      ],
                    },
                  }}
                  placement="bottom-start"
                  arrow
                >
                  <p className="font-noto-sans micro_ad text-truncate">
                    {channelName}
                  </p>
                </Tooltip>

                <p className="font-noto-sans micro_ad  mx-2"> / </p>
                <Tooltip
                  title={
                    <div>
                      <span>{projectName}</span>
                    </div>
                  }
                  slotProps={{
                    popper: {
                      modifiers: [
                        { name: "offset", options: { offset: [0, -12] } },
                      ],
                    },
                  }}
                  placement="bottom-start"
                  arrow
                >
                  <p className="font-noto-sans micro_ad text-truncate">
                    {" "}
                    {projectName}{" "}
                  </p>
                </Tooltip>
              </div>

              {/* <div className="d-flex align-items-center gap-1 rounded-pill miro-tab-button ">
                            <button
                                style={{ width: "100px", transition: "all 0.3s ease" }}
                                className={`rounded-pill py-2 border-0 font-noto-sans fw-bold fs-14 ${isInternal ? "bg-color-black text-white" : "bg-transparent text-black"} `}
                                onClick={() => modeChange(true)}
                                type="button"
                            >
                                Internal
                            </button>
                            <button
                                style={{ width: "100px", transition: "all 0.3s ease" }}
                                className={`rounded-pill py-2 border-0 font-noto-sans fw-bold fs-14 ${!isInternal ? "bg-color-black text-white" : "bg-transparent text-black"} `}
                                onClick={() => modeChange(false)}
                                type="button"
                                disabled={excalidrawLoading}
                            >
                                Miro
                            </button>
                        </div> */}
            </div>
            {/* <div>
                            {isSaving && <div style={{ position: "absolute", top: 10, right: 10 }}>Saving...</div>}
                        </div> */}
            <div className="d-flex align-items-center gap-3">
              <img
                src={comments}
                alt="comments"
                className="cursor-pointer"
                onClick={() => setShowCommentSection(!showCommentSection)}
              />
              <div
                className="cursor-pointer"
                data-bs-toggle="modal"
                data-bs-target="#settingModal"
                onClick={() => {
                  toggleInputSwitch()
                }}
              >
                <img src={headerSetting} alt="logout" />
              </div>
            </div>
            {/* Settings Button */}

            {/* Setting Button Modal 1 */}

            <div
              className="modal fade"
              id="settingModal"
              tabIndex="-1"
              aria-labelledby="settingModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content px-3">
                  <div className="modal-header border-0 font-noto-sans">
                    <div className="upload_design fw-bolder">
                      {t("visionBoardSetting")}
                    </div>
                    {/* <div className='cursor_pointer' data-bs-dismiss="modal" >
                                            <img src={Close} alt='Close' />
                                        </div> */}
                  </div>
                  <div className="modal-body font-noto-sans">
                    <div className="d-flex  gap-3">
                      <InputSwitch
                        checked={checked}
                        onChange={(e) => switchVisionBoard(e)}
                      />
                      <h6>{t("switchToExternalBoard")}</h6>
                    </div>
                    <p
                      className="text-muted fs-12"
                      style={{ marginLeft: "65px" }}
                    >
                      {t("visionBoardSettingText")}
                    </p>

                    {checked && (
                      <>
                        <p className="fs-12 fw-bold"> {t("miroBoardUrl")}</p>
                        <input
                          type="text"
                          name="required_sheet"
                          value={miroValue}
                          onChange={(e) => setMiroValue(e.target.value)}
                          autoComplete="off"
                          // onWheel={(e) => e.preventDefault()}
                          className="form-control from-input"
                          id="miroBoard"
                          placeholder="Enter Miro Board URL"
                          max={50}
                        ></input>
                      </>
                    )}
                  </div>
                  <div className="modal-footer border-0">
                    <p
                      type="button"
                      className="cancel_modal_footer"
                      data-bs-dismiss="modal"
                      onClick={cancelSwitchPopup}
                    >
                      {t("cancel")}
                    </p>
                    <button
                      type="button"
                      className="send_modal_footer"
                      data-bs-dismiss="modal"
                      data-bs-toggle="modal"
                      data-bs-target="#externalBoardModal"
                      onClick={() => { submitMiroLink(miroValue) }}
                      disabled={getButtonDisabled()}
                    >
                      {t("save")}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Switch to external board Modal */}
            <div
              className="modal fade"
              id="externalBoardModal"
              tabIndex="-1"
              aria-labelledby="externalBoardModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content px-3">
                  <div className="modal-header border-0 font-noto-sans">
                    <div className="upload_design fw-bolder">
                      {t("switchToExternalBoard")} ?
                    </div>
                    {/* <div className='cursor_pointer' data-bs-dismiss="modal" >
                                            <img src={Close} alt='Close' />
                                        </div> */}
                  </div>
                  <div className="modal-body font-noto-sans">
                    <div className="d-flex  gap-3">
                      <p className="fw-normal fs-12">
                        {" "}
                        {t("externalBoardtext")}{" "}
                      </p>
                    </div>
                    <div className="modal-footer border-0">
                      <p
                        type="button"
                        className="cancel_modal_footer"
                        data-bs-dismiss="modal"
                        onClick={cancelSwitchPopup}
                      >
                        {t("cancel")}
                      </p>
                      <button
                        style={{ padding: "8px" }}
                        type="button"
                        className="send_modal_footer"
                        data-bs-dismiss="modal"
                        onClick={() => {
                          submitMiroLink(miroValue, true);
                        }}
                      >
                        {" "}
                        {t("yesSwitch")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Save Button */}
            {/* <div className="d-flex align-items-center gap-2  pagination-top" style={{ right: "0px" }}>
                            <button
                                className="btn btn-outline-secondary"
                                type="button"
                                id="button-addon2"
                                onClick={submitRefrences}
                                style={{ backgroundColor: "#000", color: "white", borderRadius: '10px' }}
                            >
                                {t('save')}
                            </button>
                            <img
                                src={closeIcon}
                                alt="no_image"
                                width={24}
                                style={{ cursor: "pointer", transition: "transform 0.2s ease", opacity: "0.8" }}
                                onClick={backToReference}
                                onMouseEnter={(e) => e.target.style.transform = "rotate(90deg)"}
                                onMouseLeave={(e) => e.target.style.transform = "rotate(0)"}
                            // data-bs-toggle="modal" data-bs-target="#saveExcalidraw"
                            />
                        </div> */}

            {/* Modal to check any changes are done on excalidraw */}
            {/* {showSaveModal && (  */}
            <div
              className="modal fade"
              id="saveExcalidraw"
              tabindex="-1"
              aria-labelledby="saveExcalidrawLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="saveExcalidrawLabel">
                      Save
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    <p>Do you want to save changes ?</p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      onClick={navigateToReference}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        submitRefrences();
                        navigateToReference();
                      }}
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* )} */}

            {/* {!isInternal ? (
                            <div className="pagination-style-top pagination-top d-sm-none">
                                <div className="">
                                    <div className="input-group" >
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Miro link"
                                            aria-label="Enter Miro link"
                                            aria-describedby="button-addon2"
                                            value={miroValue}
                                            onChange={(e) => setMiroValue(e.target.value)}
                                            style={{ borderRadius: "0", borderRight: "none" }}
                                        />
                                        <button
                                            className="btn btn-outline-secondary"
                                            type="button"
                                            id="button-addon2"
                                            onClick={() => submitMiroLink(miroValue)}
                                            style={{ backgroundColor: "#000", color: "white" }}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : null} */}
          </div>
        </div>
        <ReferenceComp
          prevSelectedImages={selectedImages}
          setReferenceList={setReferenceList}
        />
        {/* <div className="d-flex justify-content-between flex-column flex-md-row ">
                    <div className={`heigth-calc-56 annotation-big-image`}>
                        <div className={`w-100 ms-3 pe-5 d-flex align-items-center gap-3 h-100 ${selectedImages?.length > 0 ? "justify-content-end" : "justify-content-center pe-0"}`}> */}
        {/* <CanvasComponent src={selectedImage?.imageUrl || frameImage} /> */}
        {/* {activeImag?.length > 0 && activeImag?.map((image, i) => (
                                <div style={{}} key={i}>
                                    <AnnotatingImage
                                        src={image?.image_url || frameImage}
                                        setIsGridView={setIsGridView} />
                                </div>
                            ))
                            }
                        </div>
                    </div>
                    <div className="max-w-420px w-100 bg-color-gray border-start heigth-calc-56 overflow-auto">
                        {activeImag && selectedImages && ProjectId &&
                            (
                                <CommentAndTask
                                    key={'comments'}
                                    referenceComments={referenceComments}
                                    setReferenceComments={setReferenceComments}
                                    activeImag={activeImag}
                                    selectedImages={selectedImages}
                                    ProjectId={ProjectId}
                                    getReferences={getReferences}
                                    referenceList={referenceList}
                                />
                            )}
                    </div>
                </div> */}
        <div className="d-flex">
          {isInternal && (
            <div className="d-flex">
              <div
                className="bg-white px-sm-2 py-4 d-flex flex-column gap-4 border-end position-relative"
                style={{ zIndex: "10" }}
              >
                <button
                  className="border-0"
                  style={{
                    backgroundColor: "#E6E6E6",
                    borderRadius: "8px",
                    width: "40px",
                    height: "40px",
                  }}
                  onClick={(e) => {
                    handleImageUploadClick(e, "image");
                  }}
                >
                  <img src={folderIcon} alt="folderIcon" />
                </button>
              </div>

              {showMenu && (
                <div >
                  <SideMenu
                    onClose={() => setShowMenu(false)}
                    selectedImages={selectedImage}
                    setTypeChange={setTypeChange}
                    typeChange={typeChange}
                    setSelectedImages={setSelectedImage}
                    setSelectedFont={setSelectedFont}
                    selectedFont={selectedFont}
                    createdProjectId={createdProjectId}
                    deleteImages={deleteImages}
                    isProjectActive={isProjectActive}
                  />
                </div>
              )}
            </div>
          )}
          <div className={`w-100 d-flex align-items-center h-100`} >
            <div style={{ width: "100%", pointerEvents: isProjectActive()?"auto":"none" }}>
              <AnnotatingImage
                src={
                  Array.isArray(activeImag)
                    ? activeImag.map((img) => img?.image_url || frameImage)
                    : [activeImag[0]?.image_url || frameImage]
                }
                isGridView={isGridView}
                setIsGridView={setIsGridView}
                setSelectedImage={setSelectedImage}
                setReferenceList={setReferenceList}
                referenceList={referenceList}
                referenceComments={referenceComments}
                setReferenceComments={setReferenceComments}
                isInternal={isInternal}
                miroLink={miroLink}
                selectedImages={selectedImages}
                createdProjectId={createdProjectId}
                excalidrawData={anotationData}
                excaliDrawChanges={onHandleExcalidrawChanges}
              />
            </div>
          </div>

          {/* <div className="max-w-420px w-100 bg-color-gray border-start heigth-calc-56 overflow-auto comment-task-sidebar">
                        <CommentAndTask
                            key={'comments'}
                            referenceComments={referenceComments}
                            setReferenceComments={setReferenceComments}
                            activeImag={activeImag}
                            selectedImages={selectedImages}
                            ProjectId={ProjectId}
                            siteId={siteId}
                            getReferences={getReferences}
                            referenceList={referenceList}
                            createdProjectId={createdProjectId}
                            selectedImage={selectedImage}
                            referencesList = {referencesList}
                        />
                    </div> */}
          <button
            className="sidebar-open-btn"
            onClick={() => setShowCommentSection(true)}
          >
            <span></span>
          </button>
          <div
            className={`sidebar-comment max-w-420px w-100  ${showCommentSection ? "d-block" : "d-none"
              }`}
              style={{pointerEvents:isProjectActive() ? "auto" : "none"}}
          >
            <div className=" w-100 bg-color-gray border-start heigth-calc-56  comment-task-sidebar">
              <button
                className="sidebar-close-btn"
                onClick={() => setShowCommentSection(false)}
              >
                <span></span>
              </button>
              <div
                className={`h-100 ${showCommentSection ? "d-block overflow-auto" : "d-none"
                  }`}
              >
                <CommentAndTask
                  key={"comments"}
                  referenceComments={referenceComments}
                  setReferenceComments={setReferenceComments}
                  activeImag={activeImag}
                  selectedImages={selectedImages}
                  ProjectId={ProjectId}
                  siteId={siteId}
                  getReferences={getReferences}
                  referenceList={referenceList}
                  createdProjectId={createdProjectId}
                  selectedImage={selectedImage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${isGridView ? "d-block" : "d-none"}`}>
        <ReferenceGrid selectedImages={selectedImages} />
        {/* <DesignGrid /> */}
      </div>
    </div>
  );
};

export default NewImageAnnotate;
