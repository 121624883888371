import React, { useEffect, useMemo, useRef, useState } from "react";
import "./reference.scss";
import axios from "axios";
import ArrowBack from "../../assets/newicons/arrow_back.svg";
import Settings from "../../assets/newicons/settings.svg";
import Add from "../../assets/newicons/add.svg";
import upload from "../../assets/newicons/upload.svg";
import info from "../../assets/newicons/info.svg";
import Chate from "../../assets/newicons/forum.svg";
import visionBoardLogo from "../../assets/newimages/vision_board_Logo.png";
import visionBoardPreview from "../../assets/newimages/visionBoardPreview.png";
import visionStudioPreview from "../../assets/newimages/editor-bg.png";
import ReferenceComp from "./component/ReferenceComp";
import { Bounce, toast } from "react-toastify";
import Draft from "./component/Draft";
import CreateProjectForm from "./component/createProjectForm";
import ChateBoat from "./component/ChateBoat";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { imageGet, newDelete, newGet, newPut, newPost } from "../../API/axios";
import { showAlert, showConfirmationDialog } from "./component/AlertService";
import { format, set } from "date-fns";
import CreateProjectFormLoader from "./skeletonLoaders/CreateProjectFormLoader";
import ProjectStages from "./component/ProjectStages";
import { FormControl, MenuItem, Select, Skeleton, Stack, Tooltip } from "@mui/material";
import { useAuthContext } from "../../context/useAuthContext";
import { useTranslation } from "react-i18next";
import { Excalidraw, MainMenu } from "@excalidraw/excalidraw";
import { useReferenceContext } from "../../context/useReferenceContext";
import Header from "./component/header";
import UploadDesignFile from "./component/UploadDesignFile";
import Close from "../../assets/newicons/close.svg";
// import en from "../../locales/en/translation.json";
// import ja from "../../locales/ja/translation.json";
import AddPsd from "./component/AddPsd";
import { useKonvaContext } from "../../context/useKonvaContext";

const useDebouncedValue = (inputValue, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(inputValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, delay]);

  return debouncedValue;
};
const Reference = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isOpenChat, setIsOpenChat] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refListLoader, setRefListLoader] = useState(false);
  const location = useLocation();
  let { createdProjectId, siteId, userRole } = location.state || {};
  const [channelId, setChannelId] = useState(siteId);
  const [userList, setUserList] = useState([]);
  const [clientUserList, setClientUserList] = useState([]);
  const [clientManager, setClientManager] = useState([]);
  const allUsers = [...userList, ...clientUserList];

  const [referenceList, setReferenceList] = useState();
  const [assignee, setAssignee] = useState();
  const [internalApprover, setInternalApprover] = useState();
  const [clientApprover, setClientApprover] = useState();
  const [watchListList, setWatchListList] = useState();
  const [dueDate, setDueDate] = useState("");
  const [requiredSheet, setRequiredSheet] = useState();
  const [projectId, setProjectId] = useState();
  const [channelName, setChannelName] = useState();
  const [internalApprovalMsg, setInternalApprovalMsg] = useState("");
  const [projectStatus, setProjectStatus] = useState(1);
  const [isCreateDisable, setIsCreateDisable] = useState(false);

  const [inputProjectName, setInputProjectName] = useState("");
  const debouncedProjectName = useDebouncedValue(inputProjectName, 1000);

  const { initialLoading, hasPermission, userSettings } = useAuthContext();
  const [excalidrawData, setExcalidrawData] = useState(null);
  const [excalidrawAPI, setExcalidrawAPI] = useState(null);
  const [designDetails, setdesignDetails] = useState([]);
  const [excalidrawLoading, setExcalidrawLoading] = useState(true);
  const [isApprovalBtnDisable, setIsApprovalBtnDisable] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [linkError, setLinkError] = useState('');
  const [visionBoardData, setVisionBoardData] = useState([]);

  const {
    referencesList,
    setReferencesList,
    setMiroValue,
    setMiroLink,
    setBoardId,
    setSheetId,
    setDesignId,
    projectDetails,
    setProjectDetails
  } = useReferenceContext();
  const [checked, setChecked] = useState(false);
  const [projectName, setProjectName] = useState();
  const [projectType, setProjectType] = useState();
  const [psdData, setPsdData] = useState([]);
  const [psdLinkItems, setPsdLinkItems] = useState([]);
  const [currentLink, setCurrentLink] = useState("");
  const [editPsdLinkId, setEditPsdLinkId] = useState(null);
  const [showAll, setShowAll] = useState(false);
  const { designId } = useReferenceContext();

  const disableStatus = {
    2: {
      1: t("STATUS_NOT_STARTED_DISABLE"),
    },
    4: {
      1: t("STATUS_NOT_STARTED_DISABLE"),
      3: t("STATUS_CANCELLED_DISABLE"),
    },
  }

  // useEffect(() => {
  //   localStorage.setItem("psdLinkItems", JSON.stringify(psdLinkItems));
  // }, [psdLinkItems]);

  // useEffect(() => {
  //   const savedLinks = JSON.parse(localStorage.getItem("psdLinkItems")) || [];
  //   setPsdLinkItems(savedLinks);
  // }, []);

  // Check if project is active based on status
  const isProjectActive = () => {
    if (!projectDetails?.status) return true; // Default to active if no status

    const statusId = parseInt(Object.keys(projectDetails.status)[0]);

    // Status 5 means completed/cancelled project, status 3 means inactive
    return statusId !== 5 && statusId !== 3;
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    createdProjectId = queryParams.get('project_id');
    siteId = queryParams.get('site_id');
    userRole = queryParams.get('user_role');

    fetchPsdLinks()
  }, [])


  const resolvablePromise = () => {
    let resolve;
    let reject;
    const promise = new Promise((_resolve, _reject) => {
      resolve = _resolve;
      reject = _reject;
    });
    promise.resolve = resolve;
    promise.reject = reject;
    return promise;
  };

  const initialStatePromiseRef = useRef({ promise: resolvablePromise() });
  if (!initialStatePromiseRef?.current?.promise) {
    initialStatePromiseRef.current.promise = resolvablePromise();
  }


  // const projectStage = useMemo(() => {
  //   <ProjectStages  projectDetails={projectDetails}  designDetails={designDetails} />
  // }, [designDetails, projectDetails])

  const fetchPsdLinks = async () => {
    try {
      const response = await newGet(`link/getLinkByProjectId?project_id=${createdProjectId}`);
      if (response?.status_code === 200) {
        const sortedResult = response.result.sort((a, b) => b.link_id - a.link_id);
        setPsdLinkItems(sortedResult);
        // setPsdLinkItems(response.result);
      }
    } catch (error) {
      navigate('/dashboard');
    }
  }

  function formatURL(url) {
    return url.replace(/^(?:https?:\/\/)?(.+)$/, 'https://$1');
  }

  // Add or Save link (based on state)
  const handleAddOrSave = async () => {

    if (isSubmitting || !isProjectActive()) return; // Prevent multiple submissions
    setIsSubmitting(true); // Set submitting state to true

    if (!currentLink.trim()) {
      // toast.error(t("emptyLinkError"), { autoClose: 1500 });
      setLinkError(t("validLink"));
      setIsSubmitting(false); // Always reset submitting state after validation
      return;
    }
    setLinkError('');

    const isDuplicate = psdLinkItems.some(item => item.link_url === currentLink.trim());
    if (isDuplicate) {
      // toast.error(t("duplicateLinkError"), { autoClose: 1500 });
      setLinkError('This link already exists');
      setIsSubmitting(false); // Always reset submitting state after validation
      return;
    }

    const queryParams = new URLSearchParams(window.location.search);
    const createdProjectId = queryParams.get('project_id');

    try {
      if (editPsdLinkId === null) {

        // Add new link
        const psdLinkData = {
          link_url: currentLink,
          project_id: Number(createdProjectId),
          is_active: true,
        };

        const response = await newPost("link/create", psdLinkData);
        if (response?.status_code === 1058) {
          toast.error(t("LINK_IS_NOT_IN_URL_FORMAT"), { autoClose: 1500 });
        }

        if (response?.status_code === 200 || response?.status_code === 201) {
          toast.success(t("linkAddedSuccess"), { autoClose: 1500 });
          fetchPsdLinks();

          const insertId = response?.result?.insertId;
          if (insertId) {
            const newLink = { link_id: insertId, link_url: currentLink };
            setPsdLinkItems(prevItems => [...prevItems, newLink]);
          }
        } else {
          setLinkError(t("validLink"));
          // toast.error(t("linkCreationFailed"), { autoClose: 1500 });
        }
      } else {
        // Save (edit) link
        const response = await newPut("link/update", {
          link_id: editPsdLinkId,
          link_url: currentLink,
        });

        if (response.status_code === 200) {
          toast.success(t("linkUpdatedSuccess"), { autoClose: 1500 });

          setPsdLinkItems(prevItems =>
            prevItems.map(item =>
              item.link_id === editPsdLinkId ? { ...item, link_url: currentLink } : item
            )
          );

          setEditPsdLinkId(null); // Reset edit state
          fetchPsdLinks();
        } else {
          toast.error(t("updateFailed"), { autoClose: 1500 });
        }
      }
    } catch (error) {
      console.error("Error handling link:", error);
      toast.error(editPsdLinkId === null ? t("linkCreationFailed") : t("updateFailed"), {
        autoClose: 1500,
      });
    }

    setCurrentLink(""); // Reset input field
    setIsSubmitting(false); // Always reset submitting state after operation
  };





  // Delete a link
  const handleDelete = async (id) => {
    try {
      if(!isProjectActive()) return;
      const response = await newDelete(`link/softDelete?link_id=${id}`);
      if (response.status_code === 200) {
        toast.success(t("linkdeleteSuccess"), { autoClose: 1500 });
        setPsdLinkItems(prevItems => prevItems.filter(item => item.link_id !== id));
      } else {
        toast.error(t("linkDeleteFailed"), { autoClose: 1500 });
      }
    } catch (error) {
      console.error("Error deleting link:", error);
      toast.error(t("linkDeleteFailed"), { autoClose: 1500 });
    }
  };


  // Start editing a link
  const handleEdit = (linkId, currentUrl) => {
    if(!isProjectActive()) return;
    setEditPsdLinkId(linkId);
    setCurrentLink(currentUrl);
  };

  // Toggle to show all links
  const handleShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };

  // Slice links for pagination
  const displayedLinks = showAll ? psdLinkItems : psdLinkItems.slice(0, 5);

  useEffect(() => {
    const updateData = async () => {
      setExcalidrawLoading(true);
      try {
        const files = excalidrawData.files || {};
        await Promise.all(
          Object.entries(files).map(async ([key, file]) => {
            if (!file.dataURL.startsWith("data:")) {
              const response = await imageGet("download_image", {
                image_url: file.dataURL,
              });
              const base64String = await blobToBase64(response);
              excalidrawAPI.addFiles([{ ...file, dataURL: base64String }]);
              return [key, { ...file, dataURL: base64String }];
            }
            return [key, file];
          })
        );

        const updatedData = {
          elements: excalidrawData?.elements,
          appState: {
            ...(excalidrawData.appState || {}),
          },
        };
        initialStatePromiseRef.current.promise.resolve(updatedData);

        excalidrawAPI.updateScene(updatedData);
        excalidrawAPI.scrollToContent();
        setTimeout((res) => {
          setExcalidrawLoading(false);
        }, 1000);
      } catch (error) {
        setExcalidrawLoading(false);
        console.error("Error updating Excalidraw data:", error);
      }
    };
    if (excalidrawData && excalidrawAPI) {
      updateData();
    } else {
      // if (excalidrawAPI && !excalidrawData) {
      //   setExcalidrawLoading(false);
      // }
      initialStatePromiseRef.current.promise.resolve({
        appState: { viewModeEnabled: true },
      });
      // setExcalidrawLoading(false);
    }
  }, [excalidrawData, excalidrawAPI]);

  const [projectFormValue, setProjectFormValue] = useState({
    project_name: "",
    project_description: "",
    channel: "",
    assign_to: [],
    internal_approver: [],
    client_approver: [],
    watch_list: [],
    start_date: "",
    due_date: "",
    required_sheet: "",
    type_id: "",
  });

  const [oldFormValue, setOldFormValue] = useState({
    project_name: "",
    project_description: "",
    channel: "",
    assign_to: [],
    internal_approver: [],
    client_approver: [],
    watch_list: [],
    start_date: "",
    due_date: "",
    required_sheet: "",
    type_id: "",
  });

  useEffect(() => {
    if (
      projectFormValue.project_name.length < 1 ||
      projectFormValue.project_name === oldFormValue.project_name
    ) {
      return;
    } else {
      checkProjectName(inputProjectName);
    }
  }, [debouncedProjectName]);

  useEffect(() => {
    const modalElement = document.getElementById("exampleModal");
    let modalInstance = null;

    if (modalElement) {
      modalInstance = new window.bootstrap.Modal(modalElement);
    }

    const handleModalClose = () => {
      if (modalInstance && modalInstance._isShown) {
        modalInstance.hide();
      }
    };

    handleModalClose();

    return () => {
      handleModalClose();
    };
  }, [location]);

  // Method to get the data of designs

  const getDesigns = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const projectIdFromUrl = urlParams.get("project_id");
    try {
      const data = await newGet(
        `design/getDesign?project_id=${createdProjectId || parseInt(projectIdFromUrl)
        }`
      ).then((data) => {
        if (data && data.status_code == 200) {
          data !== undefined && setdesignDetails(data?.result);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getPsdImages = async () => {
    try {
      // document/getDocument?project_id=165
      const data = await newGet(
        `document/getDocument?project_id=${createdProjectId}`
      );
      // data !== undefined && setdesignDetails(data?.result);
      if (data && (data.status_code == 200 || data.status_code == 201)) {
        setPsdData(data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Remove the design version
  const removeDesignVersion = async (id) => {
    try {
      const data = await newDelete(`design/deleteDesign?design_id=${id}`);
      if (data.status_code === 200) {
        getDesigns();
        // showAlert("Design deleted successfully", "success")
        //    closeModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Method to handle "Submit for client approval" button
  const onHandleClientApproval = async () => {
    let formData = {};
    if (designDetails.length > 0) {
      const designIdArray = designDetails
        .map((design) => design.design_id)
        .join(",");
      formData = {
        design_id: `${designIdArray}`,
        stage_id: 8,
        project_id: createdProjectId,
        message: internalApprovalMsg,
      };
    }

    try {
      await newPut("design/updateDesignStage", formData).then((response) => {
        if (response.status_code == 200) {
          setIsApprovalBtnDisable(true);
          const modalElement = document.getElementById("exampleModalSubmit");
          const modalInstance =
            window.bootstrap.Modal.getInstance(modalElement);
          modalInstance.hide();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const formatUserArray = (users, list) => {
    if (users) {
      const idMatches = users.match(/id:([a-zA-Z0-9-]+)/g);
      if (idMatches) {
        const ids = idMatches.map((idStr) => idStr.split(":")[1]);
        const selectedUsers = list?.filter((user) =>
          ids.includes(user.account_login_id)
        );
        return selectedUsers;
      } else {
        console.error("No valid ids found in the input string.");
        return [];
      }
    } else {
      return null;
    }
  };

  const [errors, setErrors] = useState({
    project_name: "",
    project_description: "",
    channel: "",
    assign_to: "",
    internal_approver: "",
    client_approver: "",
    watch_list: "",
    start_date: "",
    due_date: "",
    required_sheet: "",
    type_id: "",
  });

  const getProjectDetail = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const projectIdFromUrl = urlParams.get("project_id");
    const storedSiteId = localStorage?.getItem("siteId");
    if ((createdProjectId || projectIdFromUrl) && (siteId || storedSiteId)) {
      try {
        setLoading(true);
        const data = await newGet(
          `project/getProjectDetails?project_id=${createdProjectId || parseInt(projectIdFromUrl)
          }&site_id=${siteId || parseInt(storedSiteId)}`
        );
        if (data?.result[0]) {
          getDesignDetail(data?.result[0]?.design_id)
        }
        if (data?.message[0].property_message === "PROJECT_NOT_FOUND") {
          navigate('/dashboard');
          return;
        }
        // setSheetId(data?.result[0]?.design_id);
        setDesignId(data?.result[0]?.design_id);
        setProjectDetails(data?.result[0]);
        const status = data?.result[0].status;
        setProjectStatus(parseInt(Object.keys(status)[0], 10));
        const assigneeList = makeUserArray(
          data?.result[0]?.assign_to_user_name
        );
        setAssignee(assigneeList);
        const internalApproverList = makeUserArray(
          data?.result[0]?.internal_approver_username
        );
        setInternalApprover(internalApproverList);
        const clientApproverList = makeUserArray(
          data?.result[0]?.client_approver_username
        );
        setClientApprover(clientApproverList);
        const watchListUsers = makeUserArray(data?.result[0]?.watchlist);
        setWatchListList(watchListUsers);
        const date = formatCreatedTimestamp(data?.result[0]?.due_date);
        setDueDate(date);
        setRequiredSheet(data?.result[0]?.required_sheet);
        setChannelName(data?.result[0]?.channel_name[0]?.site_name);
        setProjectName(data?.result[0]?.project_name);
        const projectType = data?.result[0]?.type;
        setProjectType(parseInt(Object.keys(projectType)[0]));

        localStorage.setItem(
          "siteId",
          data?.result[0]?.channel_name[0]?.site_id
        );

        setLoading(false);
      } catch (error) {
        console.log("error:???????", error);
        setLoading(false);
      }
    }
  };
  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
        const base64Image = reader.result;
        const img = new Image();
        img.src = base64Image;
        img.onload = () => {
          // setImageHeight(img.height)
        };
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };
  const getReferences = async () => {
    try {
      setRefListLoader(true);

      if (!createdProjectId) {
        return;
      }

      setLoading(true);
      await newGet(
        // `reference/fetchReferences?project_id=${createdProjectId}`
        `board/get?project_id=${createdProjectId}`
      ).then(async (data) => {
        if (data && data.status_code == 200) {
          setReferenceList(data?.result.files);
          setReferencesList(data?.result);
          setMiroValue(data?.result.miro_link);
          setMiroLink(data?.result.miro_link);
          setBoardId(data?.result?.board_id);
          if (data?.result?.board_json) {
            const parsedData = JSON.parse(data.result.board_json);
            const fileKeys = Object.keys(parsedData.files);

            for (const key of fileKeys) {
              const file = parsedData.files[key];
              if (!file.dataURL.startsWith("data:")) {
                const response = await imageGet("download_image", {
                  image_url: file.dataURL,
                });
                const base64String = await blobToBase64(response);
                if (excalidrawData) {
                  excalidrawAPI.addFiles([{ ...file, dataURL: base64String }]);
                }
                // file.dataURL = base64String;
              }
            }

            const updatedData = {
              ...parsedData,
              appState: {
                ...(parsedData.appState || {}),
                viewModeEnabled: true,
              },
            };
            if (excalidrawAPI) {
              excalidrawAPI?.updateScene(updatedData);
              excalidrawAPI.scrollToContent();
              setExcalidrawLoading(false);
            } else {
              setExcalidrawData(updatedData);
            }
            setLoading(false);
          } else {
            setExcalidrawLoading(false);
          }
        }
      });

      setRefListLoader(false);
    } catch (error) {
      setLoading(false);
      console.log("error:???????", error);
      setRefListLoader(false);
    }
  };


  const checkProjectName = async (value) => {
    if (value.length < 1) {
      setErrors({
        ...errors,
        project_name: "It required at least 3 characters",
      });
      return;
    }
    // Check if the first character is not a single-byte special character
    const firstCharSingleByteSpecialPattern = /^[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]/; // Range of single-byte special characters
    const firstCharIsAlphanumericOrDoubleByte = /^[a-zA-Z0-9\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF]/;

    if (firstCharSingleByteSpecialPattern.test(value.charAt(0))) {
      setErrors({ ...errors, project_name: "The first character cannot be a single-byte special character." });
      setIsCreateDisable(true);
      return;
    }

    if (!firstCharIsAlphanumericOrDoubleByte.test(value.charAt(0))) {
      setErrors({ ...errors, project_name: "The first character must be alphanumeric or a double-byte character." });
      setIsCreateDisable(true);
      return;
    }

    // Check if the % character is present anywhere in the string
    if (value.includes('%')) {
      setErrors({ ...errors, project_name: "The '%' character is not allowed." });
      setIsCreateDisable(true);
      return;
    }

    // Allow double-byte and special characters except for %
    const validCharactersPattern = /^[a-zA-Z0-9\s\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF!@#\$^\&\*\(\)\-_+=\[\]\{\};:'"<>,.?\/\\|`~]*$/;
    const testValue = validCharactersPattern.test(value);
    // const specialCharsPattern = /[^a-zA-Z0-9 _]/g;
    // const testValue = specialCharsPattern.test(value);
    // if (!testValue) {
    try {
      await newGet(
        `project/checkProjectName?project_name=${value}&site_id=${projectFormValue?.channel?.site_id}`
      ).then((data) => {
        if (data && data.status_code == 200) {
          if (data.result[0]?.count_project_name > 0) {
            setErrors({
              ...errors,
              project_name: "Project name already exist !!!",
            });
            setIsCreateDisable(true);
          } else {
            setErrors({ ...errors, project_name: "Project name is valid" });
            setIsCreateDisable(false);
          }
        }
      });
    } catch (error) {
      console.log("error:???????", error);
      setLoading(false);
    }
    // } else {
    //   setErrors({
    //     ...errors,
    //     project_name: "special characters are not allowed !!!",
    //   });
    //   setIsCreateDisable(true);
    // }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Clear the error for the current field
    setErrors({ ...errors, [name]: "" });

    if (name === "required_sheet") {
      // Allow only digits and no strings
      if (/^\d*$/.test(value.trim())) {
        setProjectFormValue({ ...projectFormValue, [name]: value.trim() });

        // Check after the user has entered a value (when it's a valid number)
        const numericValue = parseInt(value.trim(), 10);
        if (numericValue === 0) {
          setErrors({ ...errors, [name]: "Value cannot be 0!" });
        }
        else if (numericValue > 50) {
          setErrors({ ...errors, [name]: "Maximum sheet allowed is 50!" });
        }
      }
    } else {
      // Handle other input fields normally
      setProjectFormValue({ ...projectFormValue, [name]: value });
    }
  };

  const handleDateChange = (date, name) => {
    const formattedDate = date ? format(date, "MM-dd-yyyy") : null;
    setProjectFormValue({ ...projectFormValue, [name]: formattedDate });
    setErrors({ ...errors, [name]: "" });
  };

  const handleChannelChange = (site) => {
    setProjectFormValue({ ...projectFormValue, channel: site });
    setErrors({ ...errors, channel: "" });
  };

  const handleInternalApprovalMsg = (e) => {
    setInternalApprovalMsg(e.target.value);
  };

  const handlemultipleUsers = (e, options, fieldName) => {
    let newFormValue = { ...projectFormValue, [fieldName]: options };
    let newErrors = { ...errors, [fieldName]: "" };

    if (
      ["assign_to", "internal_approver", "client_approver"].includes(fieldName)
    ) {
      const combinedWatchList = [
        ...new Set([
          ...newFormValue.assign_to,
          ...newFormValue.internal_approver,
          ...newFormValue.client_approver,
        ]),
      ];
      const watchListUser = projectFormValue?.watch_list?.filter(
        (user) =>
          !combinedWatchList?.some(
            (combined) => combined.user_id === user.user_id
          )
      );
      newFormValue = { ...newFormValue, watch_list: watchListUser };
    }
    setProjectFormValue(newFormValue);
    setErrors(newErrors);
  };

  const fetchUsers = async () => {
    const urlParams = new URLSearchParams(window.location.search)
    const siteIdFromUrl = urlParams.get("site_id");

    try {
      setLoading(true);
      const data = await newGet(`project/usersByRole?role_name=channel&site_id=${siteId || siteIdFromUrl}`);
      const dataClient = await newGet(`project/usersByRole?role_name=client&site_id=${siteId || siteIdFromUrl}`);
      const allUsers = [...data?.result, ...dataClient?.result];
      setUserList(data?.result || []);
      setClientUserList(dataClient?.result || []);
      setClientManager(
        dataClient?.result?.filter(
          (user) => user.role_name === "CLIENT_MANAGER"
        )
      );
      const assignTo =
        formatUserArray(
          projectDetails?.assign_to_user_name || "",
          data?.result
        ) || [];
      const internalApprover =
        formatUserArray(
          projectDetails?.approver_username || "",
          data?.result
        ) || [];
      const clientApprover =
        formatUserArray(
          projectDetails?.approver_username || "",
          dataClient?.result
        ) || [];

      const watchList =
        formatUserArray(projectDetails?.watchlist || "", allUsers) || [];

      setProjectFormValue({
        project_name: projectDetails.project_name || "",
        project_description: projectDetails.project_desc || "",
        channel: projectDetails.channel_name[0],
        assign_to: assignTo,
        internal_approver: internalApprover,
        client_approver: clientApprover,
        watch_list: watchList,
        start_date: formatDate(projectDetails.start_date || ""),
        due_date: formatDate(projectDetails.due_date || ""),
        required_sheet: projectDetails?.required_sheet
          ? projectDetails?.required_sheet
          : 0,
        type_id: projectType,
      });

      setOldFormValue({
        project_name: projectDetails.project_name || "",
        project_description: projectDetails.project_desc || "",
        channel: projectDetails.channel_name[0],
        assign_to: assignTo,
        internal_approver: internalApprover,
        client_approver: clientApprover,
        watch_list: watchList,
        start_date: formatDate(projectDetails.start_date || ""),
        due_date: formatDate(projectDetails.due_date || ""),
        required_sheet: projectDetails?.required_sheet
          ? projectDetails?.required_sheet
          : 0,
        type_id: projectType,
      });

      setProjectId(projectDetails.project_id);
      if (clientApprover?.length > 0) {
        setChecked(true);
      }
      setLoading(false);

      // getProjectDetail(data?.result || []);
    } catch (error) {
      console.log("error:???????", error);
      setLoading(false);
    }
  };

  const formatDate = (date1) => {
    const datePart = date1?.slice(0, 10);
    const [year, month, day] = datePart?.split("-");
    const formattedDate = `${month}-${day}-${year}`;
    return formattedDate;
  };

  const formatDueDate = (date1) => {
    const datePart = date1?.slice(0, 10);
    const [year, month, day] = datePart?.split("-");
    const formattedDate = `${year}/${month}/${day}`;
    return formattedDate;
  };

  const formatCreatedTimestamp = (timestamp) => {
    if (timestamp) {
      const options = { year: "numeric", month: "short", day: "2-digit" };
      const date = new Date(timestamp);
      return date.toLocaleDateString("en-US", options);
    } else {
      return "Not specified";
    }
  };

  // const handleClientSubmit = async () => {
  //   try {
  //     const data = await newPut(`design/shareWithClient`, {
  //       project_id: createdProjectId
  //     });
  //     if (data.status_code == 200 || data.status_code == 201) {
  //       getDesigns();
  //       showAlert("Design shared successfully", "success")
  //     }
  //   } catch (err) {
  //     console.log('err: ', err)
  //   }
  // }

  const handleClientApproveReturn = async (status) => {
    try {
      const data = await newPut(`design/approveReturnDesign`, {
        project_id: createdProjectId,
        design_id: 189,
        version_number: 1,
        status: status,
      });
      if (data.status_code == 200 || data.status_code == 201) {
        showAlert(`Design ${status} successfully`, "success");
        // showAlert(i18n.t(data?.message[0]?.property_message), 'success');
      }
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const getProjectStatus = async () => {
    try {
      const data = await newGet(`project/getStatus`);
      if (data.status_code == 200) {
        // setProjectStatus(data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    if(!isProjectActive()) {
      return 
    }
    const requiredFields = [
      { key: "project_name", message: "Project name is required." },
      {
        key: "project_description",
        message: "Project description is required.",
      },
      { key: "channel", message: "Channel name is required." },
      // {
      //   key: "assign_to",
      //   message: "Assign to name is required.",
      //   isArray: true,
      // },
      {
        key: "internal_approver",
        message: t("internalApproverRequired"),
        isArray: true,
      },
      {
        key: "client_approver",
        message: t("clientRequired"),
        isArray: true,
      },

      { key: "due_date", message: "Due date is required." },
      { key: "required_sheet", message: "No. of sheet is required." },
    ];

    let formIsValid = true;
    const newErrors = { ...errors };

    requiredFields.forEach((field) => {
      if (
        field.isArray
          ? projectFormValue[field.key].length === 0
          : !projectFormValue[field.key]
      ) {
        if (field.key === "client_approver") {
          if (projectFormValue?.type_id === 2 && projectFormValue[field.key].length === 0) {
            newErrors[field.key] = field.message;
            formIsValid = false;
          }
        } else if (field.key === "internal_approver") {
          if (projectFormValue?.type_id === 1 && projectFormValue[field.key].length === 0) {
            newErrors[field.key] = field.message;
            formIsValid = false;
          }
        }
        else {
          newErrors[field.key] = field.message;
          formIsValid = false;
        }
      } else if (
        field.key === "required_sheet" &&
        (!projectFormValue[field.key] || projectFormValue[field.key] == 0)
      ) {
        newErrors[field.key] =
          projectFormValue[field.key] == 0
            ? "Required sheet can not be zero"
            : field.message;
        formIsValid = false;
      }
      else {
        if (field.key == "project_name") {
          if (projectFormValue[field.key].length < 1) {
            newErrors[field.key] = "It required at least 1 characters";
            formIsValid = false;
            return
          }
        }
        else {

          newErrors[field.key] = "";
        }
      }
    });
    if (!formIsValid) {
      setErrors(newErrors);
      return;
    }

    const getChangedValues = (newForm, oldForm) => {
      let changedValues = {};

      Object.keys(newForm).forEach((key) => {
        if (JSON.stringify(newForm[key]) !== JSON.stringify(oldForm[key])) {
          changedValues[key] = newForm[key];
        }
      });

      return changedValues;
    };



    const changedFormData = getChangedValues(projectFormValue, oldFormValue);

    let approver_id = "";
    if (projectFormValue?.type_id === 1) {
      approver_id = projectFormValue?.internal_approver
        ?.map((user) => user?.account_login_id)
        .join(",");
    } else if (projectFormValue?.type_id === 2) {
      approver_id = projectFormValue?.client_approver
        ?.map((user) => user?.account_login_id)
        .join(",");
    }

    const formData = {
      project_id: projectId,
      site_id: projectFormValue?.channel?.site_id?.toString(),
      ...(changedFormData.project_name && {
        project_name: changedFormData.project_name,
      }),
      ...(changedFormData.project_description && {
        project_desc: changedFormData.project_description,
      }),
      // ...(changedFormData.channel && { site_id: (changedFormData.channel?.site_id).toString() }),
      ...(changedFormData.assign_to && {
        assign_to_user_id: changedFormData.assign_to
          ?.map((user) => user?.account_login_id)
          .join(","),
      }),
      ...(changedFormData.internal_approver && {
        internal_approver_user_id: changedFormData.internal_approver
          ?.map((user) => user?.account_login_id)
          .join(","),
      }),
      ...(changedFormData.client_approver && {
        client_approver_user_id: changedFormData.client_approver
          ?.map((user) => user?.account_login_id)
          .join(","),
      }),
      ...(changedFormData.watch_list && {
        watchlist: changedFormData.watch_list
          ?.map((user) => user?.account_login_id)
          .join(","),
      }),
      // start_date: projectFormValue?.start_date,
      ...(changedFormData.start_date && {
        start_date: projectFormValue?.start_date,
      }),
      ...(changedFormData.due_date && { due_date: changedFormData.due_date }),
      ...(changedFormData.required_sheet && {
        required_sheet: parseInt(changedFormData.required_sheet),
      }),
      approver_id: approver_id,
      type_id: projectFormValue?.type_id,
    };

    try {
      if (
        Object.keys(changedFormData).length === 0
        // !changedFormData.project_name &&
        // !changedFormData.project_description &&
        // !changedFormData.assign_to &&
        // !changedFormData.internal_approver &&
        // !changedFormData.client_approver &&
        // !changedFormData.watch_list &&
        // !changedFormData.due_date &&
        // !changedFormData.required_sheet
      ) {
        showAlert("There are no changes to update");
        closeModal();
        return;
      }
      setLoading(true);
      const data = await newPut(`project`, formData);
      if (data.status_code == 200) {
        // showAlert("Project Updated successfully", "success");
        showAlert(i18n.t(data?.message[0]?.property_message), "success");
        closeModal();
      }
      setLoading(false);
      getProjectDetail();
    } catch (error) {
      console.log("error:", error);
      setLoading(false);
    }
  };

  const closeModal = () => {
    const modalElement = document.getElementById("exampleModal");
    const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
    modalInstance.hide();
  };
  const handleClearForm = () => {
    setProjectFormValue({
      project_name: "",
      project_description: "",
      channel: "",
      required_sheet: "",
      assign_to: [],
      internal_approver: [],
      client_approver: [],
      // watchlist: false,
      watch_list: [],
      start_date: "",
      due_date: "",
      type_id: "",
    });
    setErrors({
      project_name: "",
      project_description: "",
      channel: "",
      required_sheet: "",
      assign_to: "",
      // project_type: "",
      internal_approver: "",
      client_approver: "",
      watch_list: "",
      start_date: "",
      due_date: "",
      type_id: "",
    });
    closeModal();
  };
  const handleGoBack = () => {
    navigate("/dashboard");
  };

  function makeUserArray(input) {
    if (input) {
      const objectsArray = input.split("},").map((item) => {
        // Add back the closing curly brace that was removed during the split (if needed)
        item = item.trim().endsWith("}") ? item : item + "}";

        // Extract the id and username values
        const idMatch = item.match(/id:([a-f0-9\-]+)/);
        const usernameMatch = item.match(/username:([^,}]+)/);

        // Return the object in proper format
        return {
          id: idMatch ? idMatch[1] : null,
          username: usernameMatch ? usernameMatch[1].trim() : null,
        };
      });
      return objectsArray;
    } else {
      return;
    }
  }

  const makeSiteObject = (str) => {
    if (!str) {
      return null;
    }
    const jsonString = str?.replace(/(\w+):([^,}]+)/g, (match, key, value) => {
      // If the value is a number, keep it as is; otherwise, wrap it in quotes
      const formattedValue = isNaN(value) ? `"${value.trim()}"` : value.trim();
      return `"${key}":${formattedValue}`;
    });

    return JSON.parse(jsonString);
  };

  const handelVariant = () => {
    // navigate('/newimage-annotation', {
    //   state: {
    //     selectedImages: "",
    //     createdProjectId: createdProjectId
    //   },
    // });
  };

  const handleStatusChange = async (e) => {
    const urlParams = new URLSearchParams(window.location.search)
    const URLParamsSiteId = urlParams.get('site_id')
    const formData = {
      project_id: createdProjectId,
      site_id: siteId?.toString() || URLParamsSiteId,
      status_id: e.target.value,
    };
    try {
      const data = await newPut(`project`, formData);
      if (data.status_code == 200) {
      }
    } catch (error) {
      console.log(error);
    }
    setProjectStatus(e.target.value);
  };

  console.log("excalidrawLoading", excalidrawLoading);


  const hadleWorkSpace = (data) => {
    navigate(
      `/reference-details?project_id=${createdProjectId}&channel_id=${channelId}&project_name=${encodeURIComponent(projectName)}&channel_name=${encodeURIComponent(channelName)}`
      , {
        state: {
          createdProjectId: createdProjectId,
          channelId: channelId,
          projectName: projectName,
          channelName: channelName,
        },
      });
  };

  const handleDeleteReference = async (ref) => {
    const confirmation = await showConfirmationDialog(
      `${t("projectDeleteMsg")}`,
      "",
      "warning",
      `${t("no")}`,
      `${t("yes")}`
    );
    try {
      if (confirmation) {
        const data = await newDelete(
          `reference/delete?reference_id=${ref.reference_id}`
        );
        if (data.status_code == 200) {
          // showAlert("Reference Deleted successfully", "success")
          showAlert(i18n.t(data?.message[0]?.property_message), "success");
          getReferences();
        }
      }
    } catch (error) {
      console.log("error:???????", error);
    }
  };

  const navigateToVisionStudio = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const storedSiteId = urlParams.get("site_id");
    const createdProjectId = urlParams.get('project_id');

    let data = { projectName: projectName, sheet: requiredSheet, channelName: channelName, createdProjectId: createdProjectId }
    navigate(`/vision-studio?project_name=${encodeURIComponent(projectName)}&sheet=${requiredSheet}&channel_name=${encodeURIComponent(channelName)}&project_id=${createdProjectId}&design_id=${designId}&site_id=${storedSiteId}`, { state: data })
  }


  useEffect(() => {
    // getProjectStatus()
    const urlParams = new URLSearchParams(window.location.search);
    const projectIdFromUrl = urlParams.get("project_id");
    const siteIdFromUrl = urlParams.get("site_id");
    const storedSiteId = localStorage?.getItem("siteId");
    if (projectIdFromUrl) {
      createdProjectId = projectIdFromUrl;
    }
    if (siteIdFromUrl) {
      siteId = siteIdFromUrl;
    }
    getReferences();
    // if ((createdProjectId || projectIdFromUrl) && (siteId || storedSiteId)) {
    if (createdProjectId || projectIdFromUrl) {
      getProjectDetail();
      getPsdImages();
      // getDesigns()
      // if (requiredSheet === designDetails?.length) {
      //   setIsApprovalBtnDisable(false)
      // }
      // else {
      //   setIsApprovalBtnDisable(true)
      // }
    } else {
      navigate("/dashboard");
    }
  }, []);

  const getDesignDetail = async (design_id) => {
    try {
      const response = await newGet(`design/getDesignDetail?design_id=${design_id}`);
      if (response?.status_code === 200) {
        setVisionBoardData(response?.result?.sheets);
      }
    } catch (error) {
      console.error("Error fetching design details:", error);
    }
  };


  return (
    <div>
      {!initialLoading && (
        <div style={{ border: "1px solid #F2EFF0" }}>
          <Header screen="reference" />
        </div>
      )}
      <div className="bg_color_gray">
        <div className="container-fluid p-0">
          <div className="d-flex justify-content-between flex-md-nowrap flex-wrap align-items-center p-xl-4 gap-lg-3 gap-md-2 gap-3 p-lg-4  py-sm-3 px-sm-3 p-2 py-3 bg_color_gray border-bottom">
            <div className="d-flex align-items-center gap-2">
              <div onClick={handleGoBack} className="cursor-pointer">
                <img src={ArrowBack} className="" alt="ArrowBack" />
              </div>
              <div className="header_line"></div>

              <Tooltip
                title={
                  <div>
                    <span>{projectName}</span>
                  </div>
                }
                slotProps={{
                  popper: {
                    modifiers: [
                      { name: "offset", options: { offset: [0, -12] } },
                    ],
                  },
                }}
                placement="bottom-start"
                arrow
              >
                {projectName ?
                  (
                    <div
                      className="font-noto-sans"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontWeight: "bold",
                        maxWidth: "100%"
                      }}
                    >
                      {projectName?.length > 15 ? `${projectName?.slice(0, 15)}...` : projectName + " /" || "---"}
                    </div>
                  ) : (
                    <Stack>
                      <Skeleton animation="wave" variant="text" width={80} height={36} />
                    </Stack>
                  )

                }


              </Tooltip>


              {!loading && (
                <div className="mb-1 header-selector">
                  <FormControl>
                    <Tooltip
                      title={
                        projectStatus === 5
                          ? t("STATUS_COMPLETED")
                          : projectStatus === 3
                            ? t("STATUS_CANCELLED")
                            : ""
                      }
                      slotProps={{
                        popper: {
                          modifiers: [
                            { name: "offset", options: { offset: [0, -7] } },
                          ],
                        },
                      }}
                      placement="bottom"
                      arrow
                    >
                      <div
                        className="rounded"
                        style={{ backgroundColor: "#666666", minWidth: "90px" }}
                      >
                        <Select
                          value={projectStatus}
                          name="channel"
                          onChange={handleStatusChange}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          className="w-100 border-none text-white font-noto-sans fs-14 fw-500"
                          disabled={
                            (userSettings?.permissions && !hasPermission(17) && userRole !== "Creator") ||
                            projectStatus === 3 ||
                            projectStatus === 5
                          }
                        >
                          <MenuItem
                            disabled={
                              projectStatus > 1 ||
                              (userRole != "Creator"
                                && userSettings?.role_id === 4
                              )}
                            value={1}
                          >
                            {
                              disableStatus?.[projectStatus]?.[1] && (projectStatus > 1 ||
                                (userRole != "Creator"
                                  && userSettings?.role_id === 4
                                ))
                                ? <Tooltip
                                  title={<div>{disableStatus?.[projectStatus]?.[1]}</div>}
                                  arrow
                                  placement="right"
                                  PopperProps={{
                                    style: { zIndex: 3000, pointerEvents: "auto" }, // Ensures tooltip appears above other elements
                                  }}
                                >
                                  <div style={{
                                    pointerEvents: "auto"
                                  }}
                                    onClick={e => e.stopPropagation()}
                                  >
                                    {t("notStarted")}
                                  </div>
                                </Tooltip>
                                : t("notStarted")
                            }
                          </MenuItem>
                          <MenuItem
                            disabled={
                              (projectStatus > 2 && projectStatus !== 4) ||
                              userRole != "Creator" && (
                                userSettings?.role_id === 4 ||
                                userSettings?.role_id === 6
                              )
                            }
                            value={2}
                          >
                            {t("inProgress")}
                          </MenuItem>
                          <MenuItem
                            disabled={
                              projectStatus > 3 ||
                              userRole != "Creator" && (
                                userSettings?.role_id === 4 ||
                                userSettings?.role_id === 6)
                            }
                            value={3}
                          >
                            {disableStatus?.[projectStatus]?.[3] && (projectStatus > 3 ||
                              userRole != "Creator" && (
                                userSettings?.role_id === 4 ||
                                userSettings?.role_id === 6))
                              ? <Tooltip
                                title={<div>{disableStatus?.[projectStatus]?.[3]}</div>}
                                arrow
                                placement="right"
                                PopperProps={{
                                  style: { zIndex: 3000, pointerEvents: "auto" }, // Ensures tooltip appears above other elements
                                }}
                              >
                                <div style={{
                                  pointerEvents: "auto"
                                }}
                                  onClick={e => e.stopPropagation()}
                                >
                                  {t("cancelled")}
                                </div>
                              </Tooltip>
                              : t("cancelled")}
                          </MenuItem>
                          <MenuItem
                            disabled={
                              (projectStatus === 3
                                || projectStatus > 4
                                || userRole != "Creator" && userSettings?.role_id === 6)
                            }
                            value={4}>
                            {" "}
                            {t("approved")}
                          </MenuItem>
                          <MenuItem
                            disabled={
                              userRole != "Creator" &&
                              (userSettings?.role_id === 4
                                || userSettings?.role_id === 6)
                            }
                            value={5}
                          >
                            {" "}
                            {t("completed")}
                          </MenuItem>
                          {/* <MenuItem value={5}> {t("submitForClientApproval")}</MenuItem> */}
                        </Select>
                      </div>
                    </Tooltip>
                  </FormControl>
                </div>
              )}
            </div>
            <div className="w-100 d-flex align-items-baseline justify-content-evenly ">
              {/* <div className="d-flex flex-column align-items-center">
                <h6 className="font-noto-sans micro_ad mb-0">{t("approver")}</h6>
                <div className="d-flex gap-1  align-items-center">

                  <div className="d-flex align-items-center">
                    {((internalApprover !== undefined && internalApprover[0] !== null) && internalApprover?.map((name) => (
                      <Tooltip title={<div>
                        <span>{name?.username}</span>
                      </div>} slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -12] } }] } }} placement="bottom" arrow>
                        <p key={name?.id} className="approver-icon mb-0">
                          <span>{name?.username?.slice(0, 2)?.toUpperCase()}</span>
                        </p>
                      </Tooltip>
                    ))) || ""}
                  </div>

                  <div className="header_line"></div>

                  <div className="d-flex align-items-center">

                    {((clientApprover !== undefined && clientApprover[0] !== null) && clientApprover?.map((name) => (
                      <Tooltip title={<div>
                        <span>{name?.username}</span>
                      </div>} slotProps={{ popper: { modifiers: [{ name: 'offset', options: { offset: [0, -12] } }] } }} placement="bottom" arrow>
                        <p key={name?.id} className="approver-icon mb-0">
                          <span>{name?.username?.slice(0, 2)?.toUpperCase()}</span>
                        </p>
                      </Tooltip>
                    ))) || ""}
                  </div>
                </div>

              </div> */}

              <div className="d-flex flex-column align-items-center">
                <h6 className="font-noto-sans micro_ad mb-0">
                  {t("assignee")}
                </h6>
                <div className="d-flex align-items-center">
                  {(assignee !== undefined &&
                    assignee[0] !== null &&
                    assignee?.map((name) => (
                      <Tooltip
                        title={
                          <div>
                            <span>{name?.username}</span>
                          </div>
                        }
                        slotProps={{
                          popper: {
                            modifiers: [
                              { name: "offset", options: { offset: [0, -12] } },
                            ],
                          },
                        }}
                        placement="bottom"
                        arrow
                      >
                        <p key={name?.id} className="approver-icon mb-0">
                          <span>
                            {name?.username?.slice(0, 2)?.toUpperCase()}
                          </span>
                        </p>
                      </Tooltip>
                    ))) ||
                    ""}
                </div>
              </div>

              {/* <div className="d-flex flex-column align-items-center gap-1">
                <h6 className="font-noto-sans micro_ad mb-0">{t("DueDate")}</h6>
                <h6 className="font-noto-sans micro_ad mb-0">{dueDate || ""} </h6>
              </div> */}
              <div className="d-flex flex-column align-items-center gap-1">
                <h6 className="font-noto-sans micro_ad mb-0">
                  {t("requiredSheet")}
                </h6>
                <h6 className="font-noto-sans micro_ad mb-0">
                  {requiredSheet || ""}
                </h6>
              </div>
            </div>
            <div
              className="d-flex justify-content-end align-items-center  "
              style={{ width: "30%" }}
            >
              {userSettings?.permissions && hasPermission(11) && (
                <button
                  onClick={() => fetchUsers()}
                  className="cursor_pointer bg-transparent px-2 py-1 rounded-pill font-noto-sans "
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  disabled={userSettings?.permissions && !hasPermission(11)}
                  style={{ fontSize: "16px", fontWeight: "600" }}
                >
                  {/* <img src={Settings} alt="settings" /> */}
                  <img src={info} alt="Close" className="mx-1 mb-1" />{" "}
                  {t("projectDetails")}
                </button>
              )}
              {/* project detail modal */}

              <div
                className="modal fade font-noto-sans"
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                      <h5
                        className="modal-title fw-bold"
                        id="exampleModalLabel"
                      >
                        {t("projectDetails")}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={handleClearForm}
                      ></button>
                    </div>
                    <CreateProjectForm
                      handleChange={handleChange}
                      handlemultipleUsers={handlemultipleUsers}
                      projectFormValue={projectFormValue}
                      setProjectFormValue={setProjectFormValue}
                      errors={errors}
                      userList={userList}
                      allUsers={allUsers}
                      clientManager={clientManager}
                      handleChannelChange={handleChannelChange}
                      loading={loading}
                      handleDateChange={handleDateChange}
                      setInputProjectName={setInputProjectName}
                      IsFormForUpadate={true}
                      checked={checked}
                      setChecked={setChecked}
                      screen="reference"
                    />

                    {loading ? (
                      <div className="modal-footer border-0">
                        <CreateProjectFormLoader
                          height={60}
                          width={100}
                          rounded={"rounded-25"}
                        />
                        <CreateProjectFormLoader
                          height={60}
                          width={100}
                          rounded={"rounded-25"}
                        />
                      </div>
                    ) : (
                      <div className="modal-footer border-0">
                        <p
                          type="button"
                          className="cancel_modal_footer"
                          data-bs-dismiss="modal"
                          onClick={handleClearForm}
                        >
                          {t("cancel")}
                        </p>
                        <button
                          type="button"
                          className="send_modal_footer"
                          onClick={handleSubmit}
                          disabled={isCreateDisable || !isProjectActive()}
                        >
                          {t("save")}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* end of project detail modal */}
              {/* {userSettings?.permissions && hasPermission(16) &&
                <button
                  className="header-submit rounded-pill text-nowrap"
                  // data-bs-toggle="modal"
                  // data-bs-target="#exampleModalSubmit"
                  disabled={!designDetails?.length > 0 || clientApprover === undefined}
                  onClick={handleClientSubmit}
                >
                  {t("submitForClientApproval")}
                </button>
              } */}

              {/* submit model */}

              <div
                className="modal fade"
                id="exampleModalSubmit"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog me-5 pt-5">
                  <div className="modal-content mt-3">
                    <div className="modal-header border-0">
                      <h5
                        className="font-noto-sans fw-bold fs-20 mb-0 text-black"
                        id="exampleModalLabel"
                      >
                        Internal approver
                      </h5>
                    </div>

                    <div className="modal-body font-noto-sans fw-500 pt-0 ">
                      <div className="d-flex align-items-center gap-3">
                        <div className="d-flex align-items-center gap-2 submit-client-approval rounded-pill p-1">
                          <p className="approver-icon mb-0">
                            <span>TY</span>
                          </p>
                          <span className="pe-2 font-noto-sans fw-bold fs-14">
                            Takashi Yamada
                          </span>
                        </div>
                        <div className="d-flex align-items-center gap-2 submit-client-approval rounded-pill p-1">
                          <p className="approver-icon mb-0">
                            <span>TY</span>
                          </p>
                          <span className="pe-2 font-noto-sans fw-bold fs-14">
                            Takashi Yamada
                          </span>
                        </div>
                      </div>
                      <div className="mt-3">
                        <form>
                          <div className="d-flex flex-column gap-2">
                            <label className=" font-noto-sans fw-bold fs-14 ms-0">
                              Message
                            </label>
                            <textarea
                              placeholder="For your approval"
                              className="submit-client-approval-textarea"
                              value={internalApprovalMsg}
                              onChange={handleInternalApprovalMsg}
                            ></textarea>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div className="modal-footer mt-0 border-0">
                      <button
                        className="rounded-pill px-4 bg-transparent h-36 border-0 text-black font-noto-sans fw-bold fs-14 text-nowrap"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Cancel
                      </button>
                      <button
                        className="rounded-pill px-4 bg-color-black h-36 border-black text-white font-noto-sans fw-500 fs-14"
                        // data-bs-toggle="modal"
                        data-bs-target="#exampleModalSubmit"
                        onClick={onHandleClientApproval}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* end of submit model */}
            </div>
          </div>
        </div>
        <div className="container-fluid px-md-5 px-sm-4 px-3 my-4 overflow-auto">
          <div className="row justify-content-center">
            <div className="px-xl-5">
              <ProjectStages
                projectDetails={projectDetails}
                // designDetails={designDetails}
                createdProjectId={createdProjectId}
              />

              <div className="p-lg-4 mt-3 p-md-3 p-3 bg-white bora_24 bg_white_padding">
                <div className="d-flex justify-content-between align-items-center cursor_pointer mb-1">
                  <div className="d-flex align-items-center gap-4">
                    <div className="text_formate">{t("wireframe")}</div>
                  </div>
                </div>
                {/* <div style={{ maxHeight: "390px", overflowY: "auto", display: excalidrawLoading ? 'none' : 'block' }}> */}
                {/* <div className="d-flex flex-wrap align-items-center justify-content-center gap-3 hover-container" > */}
                {/* <div className="text-center">
                  <img src={visionBoardLogo} alt="Vision Board" />
                  <p
                    className="font-noto-sans"
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      margin: "0px",
                    }}
                  >
                    {t("visionBoardText1")}{" "}
                  </p>
                  <p
                    className="font-noto-sans"
                    style={{ fontSize: "20px", fontWeight: "700" }}
                  >
                    {" "}
                    {t("visionBoardText2")}
                  </p>
                  <p
                    className="font-noto-sans"
                    style={{
                      border: "1px solid gray",
                      fontWeight: "600",
                      borderRadius: "20px",
                      padding: "8px",
                      cursor: excalidrawLoading ? 'wait' : "pointer",
                      display: "inline-block",
                      fontSize: "16px",
                      transition: "background-color 0.3s",
                    }}
                    onClick={() => excalidrawLoading && hadleWorkSpace()}
                  >
                    {
                      excalidrawLoading
                        ? <Skeleton animation="wave" variant="text" width={150} height={24} />
                        : t("openVisionBoard")
                    }
                  </p>
                </div> */}
                <div className="px-3 mt-2" style={{ maxHeight: "370px", overflowY: "auto", display: excalidrawLoading ? 'none' : 'block', position:'relative' }}>
                  <div className="d-flex flex-wrap align-items-center justify-content-center gap-3 hover-container" >
                    <div className="wireframe-transparent-layer"></div>
                    <div
                      title="Click to start design."
                      className='position-relative  reference-view-mode workspace-image'

                    // onClick={hadleWorkSpace}
                    >
                      <Excalidraw
                        excalidrawAPI={(api) => setExcalidrawAPI(api)}
                        initialData={initialStatePromiseRef.current.promise}>
                      </Excalidraw>


                    </div>
                    {/* <button className="hover-button" onClick={hadleWorkSpace} >
                      {t("open")}
                    </button> */}
                  </div>
                  <p
                    className="font-noto-sans"
                    style={{
                      position: "absolute", // Make the <p> tag positioned relative to the parent
                      bottom: '30%',
                      left: "50%",
                      transform: "translate(-50%, -50%)", // Adjust for perfect centering
                      border: "1px solid gray",
                      fontWeight: "600",
                      borderRadius: "20px",
                      padding: "8px",
                      cursor: "pointer",
                      fontSize: "16px",
                      transition: "background-color 0.3s",
                      backgroundColor: "white",
                      zIndex: 999, // Ensure it appears above the image
                    }}
                    onClick={hadleWorkSpace}
                  >
                    {t("openVisionBoard")}
                  </p>

                </div>

                {/* <div className="wireframe-transparent-layer"></div>
                <div
                  title="Click to start design."
                  className="position-relative mt-4 reference-view-mode workspace-image"

                // onClick={hadleWorkSpace}
                >
                  <Excalidraw
                    excalidrawAPI={(api) => setExcalidrawAPI(api)}
                    initialData={initialStatePromiseRef.current.promise}
                  ></Excalidraw>
                </div> */}
                {/* <button className="hover-button" onClick={hadleWorkSpace} >
                  {t("open")}
                </button> */}
                {/* </div> */}

                {/* </div> */}
                <div style={{ maxHeight: "390px", overflowY: "auto", display: !excalidrawLoading ? 'none' : 'block' }}>
                  <CreateProjectFormLoader height={"260px"} width={"100%"}  ></CreateProjectFormLoader>
                </div>

                <ReferenceComp
                  createdProjectId={createdProjectId}
                  referenceList={referenceList}
                  setReferenceList={setReferenceList}
                  channelId={channelId}
                />
              </div>

              <div>
                <div className="p-lg-4 mt-3 p-md-3 p-3 bg-white bora_24 bg_white_padding">
                  {/* <div className="border_bootom">
                    <Draft
                      designDetails={designDetails}
                      setdesignDetails={setdesignDetails}
                      projectDetails={projectDetails}
                      assignee={assignee}
                      internalApprover={internalApprover}
                      clientApprover={clientApprover}
                      removeDesignVersion={removeDesignVersion}

                    />
                  </div> */}
                  <div className="d-flex justify-content-between align-items-center gap-4 cursor_pointer">
                    <div className="text_formate d-flex align-items-center gap-1">
                      {/* <span>{t("design")}</span> */}
                      <span>{t("visionStudio")}</span>
                    </div>
                  </div>
                  {/* <div className="text-center ">
                    <p
                      className="font-noto-sans"
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        margin: "0px",
                      }}
                    >
                      {" "}
                      {t("visionStudioText1")}{" "}
                    </p>
                    <p
                      className="font-noto-sans"
                      style={{ fontSize: "20px", fontWeight: "700" }}
                    >
                      {" "}
                      {t("visionStudioText2")}{" "}
                    </p>
                    <p
                      className="font-noto-sans"
                      style={{
                        border: "1px solid gray",
                        fontWeight: "600",
                        borderRadius: "20px",
                        padding: "8px",
                        cursor: excalidrawLoading ? 'wait' : "pointer",
                        display: "inline-block",
                        fontSize: "16px",
                        transition: "background-color 0.3s",
                      }}
                      onClick={() => !excalidrawLoading && navigateToVisionStudio()}
                    >
                      {
                        excalidrawLoading
                          ? <Skeleton animation="wave" variant="text" width={150} height={24} />
                          : t("openVisionStudio")
                      }
                    </p>
                  </div> */}

                  {/* Vision Studio Preview */}
                  <div className="text-center px-3 mt-2" style={{ position: 'relative', boxSizing: "border-box" }} >
                    <img style={{ borderRadius: "10px", width: "100%", height: "270px" }} src={visionStudioPreview} alt="visionStudioPreview" />
                    <div style={{ display: "flex", flexDirection: 'row', padding: '1vh', bottom: '30px', position: 'absolute' }}>
                      {visionBoardData.length > 0 && (
                        visionBoardData.slice(0, 6).map((item, index) => (
                          <div key={index} style={{ margin: '8px', width: '150px', height: '150px', background: 'white', filter: 'blur(3px)' }}>
                            {item?.layers[0].image_data.data && (
                              <img src={`data:image/jpeg;base64,${item?.layers[0].image_data.data}`} alt="" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                            )}
                          </div>
                        )))}
                    </div>

                    <p
                      className="font-noto-sans"
                      style={{
                        position: "absolute",
                        bottom: '30%',                      
                        
                        left: "50%",
                        transform: "translate(-50%, -50%)", // Adjust for perfect centering
                        border: "1px solid gray",
                        fontWeight: "600",
                        borderRadius: "20px",
                        padding: "8px",
                        cursor: "pointer",
                        // display: "inline-block",
                        fontSize: "16px",
                        transition: "background-color 0.3s",
                        backgroundColor: "white",
                        zIndex: 1, // Ensure it appears above the image
                        // color: "white"
                      }}
                      onClick={() => navigateToVisionStudio()}
                    >
                      {t("openVisionStudio")}
                    </p>
                  </div>
                </div>
              </div>

              {/* Container for Psd designs*/}

              <div className="p-lg-4 mt-3 p-md-3 p-3 bg-white bora_24 bg_white_padding">
                <div className="align-items-center cursor_pointer mb-1">
                  <div className="align-items-center gap-4">
                    <div className="text_formate" style={{ width: "100%" }}>
                      {t("addPsdLink")}
                      <form>
                        <div className="mb-1 psd-link ">
                          <input
                            disabled={!isProjectActive()}
                            type="text"
                            name="psd_link"
                            value={currentLink}
                            onChange={(e) => {
                              setCurrentLink(e.target.value);
                              if (e.target.value.trim()) {
                                setLinkError(''); // Clear error when user types
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                handleAddOrSave();
                              }
                            }}
                            className="form-control from-input-link"
                            placeholder={t("addPsdLink")}
                          />
                          <button
                            onClick={(e) => {
                              e.preventDefault(); // Prevent form submission
                              handleAddOrSave();
                            }}
                            disabled={(editPsdLinkId === null ? (isSubmitting || !currentLink.trim()) : false) || !isProjectActive()}
                            style={{ marginLeft: "5%" }}
                            type="button"
                            className="send_modal_footer"
                          >
                            {editPsdLinkId === null ? t("add") : t("save")}

                          </button>
                        </div>
                      </form>

                      {linkError && (
                        <div className="text-danger mt-1" style={{ fontSize: '14px' }}>
                          {linkError}
                        </div>
                      )}
                      <div className="list-group list-group-flush">
                        {displayedLinks.map((item, index) => (
                          <div
                            key={item.link_id}
                            className="list-group-item d-flex justify-content-between align-items-center psd-link-with-button"
                          >
                            <a
                              href={formatURL(item.link_url)}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-decoration-none"
                            >
                              {item.link_url}
                            </a>
                            <div className="psd-link-actions">
                              <button
                                onClick={() =>
                                  handleEdit(item.link_id, item.link_url)
                                }
                                disabled={!isProjectActive()}
                                type="button"
                                className="send_modal_footer"
                              >
                                {t("edit")}
                              </button>
                              <button
                                onClick={() => handleDelete(item.link_id)}
                                type="button"
                                disabled={!isProjectActive()}
                                className="send_modal_footer"
                              >
                                {t("delete")}
                              </button>
                              <hr class="dotted"></hr>
                            </div>
                          </div>
                        ))}
                      </div>

                      {psdLinkItems.length > 5 && (
                        <a
                          onClick={handleShowAll}
                          style={{
                            marginTop: "10px",
                            fontSize: "16px",
                            fontWeight: "600",
                            color: "black",
                          }}
                          className="text-decoration-none"
                        >
                          {showAll ? t("showLess") : t("showMore")}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Modal to Upload PSD */}
              <div
                className="modal fade"
                id="add_design"
                tabIndex="-1"
                aria-labelledby="add_designLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                  <div className="modal-content px-3">
                    <div className="modal-header border-0">
                      <div className="upload_design">Upload PSD</div>
                      <div className="cursor_pointer" data-bs-dismiss="modal">
                        <img src={Close} alt="Close" />
                      </div>
                    </div>
                    <div className="modal-body">
                      <UploadDesignFile screen="addPsd" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* <div
          typeof="button"
          className="chate_box cursor_pointer"
          onClick={() => setIsOpenChat(!isOpenChat)}
        >
          {/* <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <img src={Chate} className="" alt="Chate" />
          </div> */}
        {/* </div>  */}
        {isOpenChat && (
          <div>
            <ChateBoat
              isOpenChat={isOpenChat}
              setIsOpenChat={setIsOpenChat}
              ProjectId={createdProjectId}
              designDetails={designDetails}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Reference;
