import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Skeleton } from '@mui/material';
import NewLoader from '../../../components/Loaders/NewLoader';
import ProjectLoader from '../skeletonLoaders/ProjectLoader';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../../context/useAuthContext';

const ProjectTable = ({ projectList, handleDelete, loading, handleUpdateProject, userSettings, channelname, onFormattedDataChange }) => {
    const { t, i18n } = useTranslation();
    const { hasPermission, selectedSite } = useAuthContext();

    const formatAssignee = (name) => {
        if (name!=null) {
            const usernameMatches = name?.match(/username:([^,}]+)/g);
            if (usernameMatches) {
                // Map through the matches to extract the username part and join them with commas
                const usernames = usernameMatches.map(match => match.split(':')[1]).join(',');
                return usernames;
            } else {
                console.error('No usernames found in the input string.');
                return '';
            }
        };

    }

    const formatCreatedName = (name) => {
        const name1 = name?.match(/username:([^,}]+)/);
        let userName;
        if (name1) {
            userName = name1[1]
            return userName
        }
        return userName;
    }

    const dateFormat = (date) => {
        const datePart = date?.split(' ')[0];
        return datePart
    }

    const formatCreatedTimestamp = (timestamp) => {
        if (timestamp) {
            const options = { year: 'numeric', month: 'short', day: '2-digit' };
            const date = new Date(timestamp);
            return date.toLocaleDateString('en-US', options);
        } else {
            return "Not specified"
        }
    };


    const makeSiteObject = (str) => {
        if (!str) {
            return null;
        }
        const jsonString = str?.replace(/(\w+):([^,}]+)/g, (match, key, value) => {
            // If the value is a number, keep it as is; otherwise, wrap it in quotes
            const formattedValue = isNaN(value) ? `"${value.trim()}"` : value.trim();
            return `"${key}":${formattedValue}`;
        });

        return JSON.parse(jsonString).site_name;
    }

    const formattedData = selectedSite?.site_name === 'All' ? projectList : projectList?.filter((project) => { return (makeSiteObject(project.channel_name) === selectedSite?.site_name) })

    useEffect(() => {
        onFormattedDataChange(formattedData);
    }, [formattedData, onFormattedDataChange]);


    return (
        <div style={{ maxHeight: 'calc(100vh - 10vh)', overflowY: "scroll" }}>
            <div className="tabel_border_bottam">
                <table className="table table-responsive table-hover">
                    <thead className='sticky_top'>
                        <tr>
                            <th className='tabel-fild-title'> {t("project")}</th>
                            <th className='tabel-fild-title'> {t("client")}</th>
                            <th className='tabel-fild-title'> {t("asssignee")}</th>
                            {userSettings?.role_id === 5 &&
                                <th className='tabel-fild-title'> {t("requiredSheet")}</th>
                            }
                            <th className='tabel-fild-title'> {t("status")}</th>
                            {/* <th className='tabel-fild-title'> {t("startDate")}</th> */}
                            {/* <th className='tabel-fild-title'> {t("dueDate")}</th> */}
                            <th className='tabel-fild-title'>{t("finaldeliveryDate")} </th>
                            <th className='tabel-fild-title'> {t("createdBy")}</th>
                            <th className='tabel-fild-title'> {t("role")}</th>
                            <th className='tabel-fild-title'> {t("createdDate")}</th>
                            <th className='tabel-fild-title'></th>
                        </tr>
                    </thead>
                    {loading ? (
                        {/* <tbody>
                            <tr className=''>
                                <td colSpan={8} className='text-center pt-5 border-0'>
                                    <NewLoader />
                                </td>
                            </tr>
                        </tbody> */},
                        <tbody>
                            {Array.from({ length: 5 }).map((_, i) => (
                                <tr key={i} className='border-bottom'>
                                    <td className='text-center align-middle border-0'>
                                        <div className='w-75'>
                                            <ProjectLoader />
                                        </div>
                                        <ProjectLoader />
                                    </td>
                                    {Array.from({ length: 9 }).map((_, index) => (
                                        <td key={index} className="text-center align-middle border-0">
                                            <ProjectLoader />
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    ) :
                        (
                            formattedData && formattedData?.length > 0 ? (
                                <tbody>

                                    {[...formattedData]
                                        .sort((a, b) => new Date(b.created_timestamp) - new Date(a.created_timestamp))
                                        .map((project, i) => (
                                            // console.log(project.channel_name.split('site_name:')[1]?.split('}')[0]?.trim()),
                                            <tr key={i} className='cursor_pointer' onClick={() => handleUpdateProject(project?.project_id, project?.channel_name, project?.shared_with_client, project?.user_role)}>
                                                <th className='tabel-fild border-bottom'>
                                                    <div className='project_name' title={project?.project_name} >{project?.project_name}</div>
                                                    <div className='project_des' title={project?.project_desc}>{project?.project_desc}</div>
                                                </th>
                                                <td className='tabel-fild' title={makeSiteObject(project?.channel_name)}>{makeSiteObject(project?.channel_name)}</td>
                                                <td className='tabel-fild' title={formatAssignee(project?.assign_to_user_name)}>{formatAssignee(project?.assign_to_user_name)}</td>
                                                {userSettings?.role_id === 5 &&
                                                    <td className='tabel-fild'>{project?.required_sheet}</td>
                                                }
                                                <td className='tabel-fild'><span className=''>{project?.status}</span></td>
                                                {/* <td className='tabel-fild'>{formatCreatedTimestamp(project?.start_date)}</td> */}
                                                <td className='tabel-fild'>{formatCreatedTimestamp(project?.final_delivery_date)}</td>
                                                <td className='tabel-fild'>{formatCreatedName(project?.created_username)}</td>
                                                <td className='tabel-fild'>{project?.user_role}</td>
                                                <td className='tabel-fild'>
                                                    {formatCreatedTimestamp(project?.created_timestamp)}
                                                </td>
                                                {userSettings?.permissions && hasPermission(10) &&
                                                    <td className='tabel-fild'><button className='submit_approval px-2 py-0'
                                                        onClick={(e) => handleDelete(e, project.project_id)}>{t("delete")}</button>
                                                    </td>
                                                }
                                            </tr>
                                        ))}
                                </tbody>

                            )
                                : (
                                    <tbody>
                                        <tr>
                                            <td colSpan={8} className='text-center pt-5 border-0' >{t("noRecordsFound")}</td>
                                        </tr>
                                    </tbody>

                                )
                        )}

                </table>
            </div>
        </div>
    );
}

export default ProjectTable;
