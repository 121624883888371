import React, { useEffect, useRef, useState } from 'react';
import { useAuthContext } from '../../../context/useAuthContext';
import headerLogomicroworks from '../../../assets/newicons/header-logo-microworks.svg';
import headerArrowDown from '../../../assets/newicons/keyboard_arrow_down.svg';
// import downArrow from "../../../assets/icons/drop_blue.svg";

const HeaderDropdown = ({ screen }) => {
    const {
      
        saveSelectedSite,
        selectedSite,
        userSettings,
    } = useAuthContext();
    const inputRef = useRef(null);
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);
    const [filteredSites, setFilteredSites] = useState(userSettings?.channel_name);


    const handleSiteChange = (e) => {
            const value = e.target.value;
            const filtered = userSettings?.channel_name.filter((site) =>
                site.site_name.toLowerCase().includes(value.toLowerCase())
            );
     
            setFilteredSites(filtered);
    };

    const onSiteChange = (index, site = "") => {
        saveSelectedSite(index, site);
        setIsDropDownOpen(false);
        setFilteredSites(userSettings?.channel_name);
    };

    const handleClickOutside = (e) => {
        if (inputRef.current && !inputRef.current.contains(e.target)) {
            setIsDropDownOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleDropdown = () => {
        if (screen === 'manager' || screen ==="settings") {
            setIsDropDownOpen(!isDropDownOpen)
        }
    }

    useEffect(()=>{
        setFilteredSites(userSettings?.channel_name);
    },[userSettings])


    return (
        <div>
            {/* {selectedSite?.site_name && ( */}
            <>
                <div className="col-md-4 col-12 ">
                    <div
                        className="w-100 position-relative max-w-250"
                        ref={inputRef}
                    >
                        <div
                            style={{ cursor: "pointer", }}
                            className="border-0 d-flex gap-2 align-items-center justify-content-between header-selection-width p-1 pe-md-3"
                            onClick={() => handleDropdown()}
                        >
                            <img src={headerLogomicroworks} alt="header_logo" className="img-fluid" />
                            {isDropDownOpen ? (
                                <input
                                    type="text"
                                    className="remove_outline_header"
                                    onChange={handleSiteChange}
                                    // ref={inputRef}
                                    autoFocus

                                ></input>
                            ) : (
                                <span className="text-500 fs-14" style={{ whiteSpace: 'nowrap' }}>
                                    {selectedSite?.site_name}
                                </span>
                            )}
                            <img
                                onClick={() => handleDropdown()}
                                src={headerArrowDown}
                                style={{ cursor: "pointer", transform: isDropDownOpen ? "rotate(180deg)" : "rotate(0)" }}
                                alt="icon"
                            />
                        </div>

                        {isDropDownOpen && (
                            <>
                                <ul className="position-absolute list-unstyled sitemenu border-0 w-100" style={{ maxHeight: "250px" }}>
                                    {userSettings && (
                                        <li onClick={() => onSiteChange(-1)} className='border-bottom-0'>
                                            <div
                                                className="sitemenu-item text-center border-bottom-0 w-100 fs-14"
                                                href="#"
                                            >
                                                All
                                            </div>
                                        </li>
                                    )}
                                    {filteredSites &&
                                        filteredSites?.map((site, index) => (   
                                            <li className='border-bottom-0'
                                                key={index}
                                                onClick={() => onSiteChange(index, site)}
                                            >
                                                <div
                                                    className="sitemenu-item text-center border-bottom-0 w-100 fs-14"
                                                    href="#"
                                                >
                                                    {site?.site_name}
                                                </div>
                                            </li>
                                        ))
                                        }
                                </ul>
                            </>
                        )}
                    </div>
                </div>
            </>
            {/* )} */}
        </div>
    );
}

export default HeaderDropdown;
