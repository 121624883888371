import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import CategoryFilter from "../../helpers/CategoryFilter";
import refresh from "../../assets/icons/refresh.png";
import { useFilterContext } from "../../context/useFilterContext";
import { useSearchContext } from "../../context/useSearchContext";
import SearchInput from "../../components/SearchInput";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/useAuthContext";

const InitialSearch = ({
  getSearchText,
  getSearchTags,
  tags,
  handleChange,
  handleHashtagChange,
  tagLoader,
  scrollRef,
  // setSearchView
}) => {
  const navigate = useNavigate()
  const { formValue, setFormValue ,setSearchViewState} = useSearchContext();
  const [open, setOpen] = useState();
  const { filterTags } = useFilterContext();
  const { t } = useTranslation();
  const loc = useLocation();
  const { isDebug } = useAuthContext();
  console.log(loc.pathname);
  const fetchSearchText = () => {
    getSearchText(false);
    navigate("/search")
  }

  const goToDashbaord = () => {
    try {
      if (loc.pathname === "/dashboard") {
        setSearchViewState(false)
      }
      else {
        navigate("/dashboard");
        setSearchViewState(false)
      }
    } catch (e) {
      // console.log(e)
    }

  }

  useEffect(() => {
    if (isDebug) {
      setFormValue({ ...formValue, is_edited: "all" });
    }
    else {
      setFormValue({ ...formValue, is_edited: "" });
    }
  }, [isDebug])

  return (
    <div className="filter-container">
      <div className="search-bar ">
        <div className="d-flex justify-content-center align-items-center w-100 flex-column">
          <div className="w-100 header-max-w-100">
            <div className="search-bar-inner">
              {/* <SearchInput getSearchText={getSearchText} scrollRef={scrollRef} /> */}
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-4 mt-sm-0 flex-column">
        <div className="d-flex justify-content-center">
          <div className="align-items-start flex-column d-flex w-100 max-w-900">
            <>
              <div className="d-flex justify-content-between align-items-center w-100 gap-2 gap-sm-0 pb-3">
                <div>
                  <span className="filter-label fs-16 mb-3">
                    {t("searchBy")}
                  </span>
                  <div className="d-flex align-items-end gap-3">
                    <div>
                      <input
                        className="form-check-input"
                        type="radio"
                        id="text"
                        value="text"
                        name="search_by"
                        checked={formValue.search_by === "text"}
                        onChange={(e) => handleChange(e)}
                      />
                      <label className="text-gray" htmlFor="text">
                        {t('text')}
                      </label>
                    </div>
                    <div>
                      <input
                        className="form-check-input"
                        type="radio"
                        value="desc"
                        id="desc"
                        name="search_by"
                        checked={formValue.search_by === "desc"}
                        onChange={(e) => handleChange(e)}
                      />
                      <label className="text-gray" htmlFor="desc">
                       {t('description')}
                      </label>
                    </div>
                    <div>
                      <input
                        className="form-check-input"
                        type="radio"
                        id="both"
                        value="both"
                        checked={formValue.search_by === "both"}
                        onChange={(e) => handleChange(e)}
                        name="search_by"
                      />
                      <label className="text-gray" htmlFor="both">
                          {t('both')}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bottom-border mb-4"></div>
              <div style={{ display: isDebug ? "block" : "none" }}>
                {/* {permissions?.debug_mode ? ( */}
                <div className="d-flex justify-content-between align-items-center w-100 gap-2 gap-sm-0 pb-3">
                  <div>
                    <span className="filter-label fs-16 mb-3">
                      {t("imageStatus")}
                    </span>
                    <div className="d-flex align-items-end gap-3">
                      <div>
                        <input
                          className="form-check-input"
                          type="radio"
                          id="all"
                          value="all"
                          name="is_edited"
                          checked={formValue.is_edited === "all"}
                          onChange={(e) => handleChange(e)}
                        />
                        <label className="text-gray" htmlFor="all">
                          All
                        </label>
                      </div>
                      <div>
                        <input
                          className="form-check-input"
                          type="radio"
                          value="edited"
                          id="edited"
                          name="is_edited"
                          checked={formValue.is_edited === "edited"}
                          onChange={(e) => handleChange(e)}
                        />
                        <label className="text-gray" htmlFor="edited">
                          Edited
                        </label>
                      </div>
                      <div>
                        <input
                          className="form-check-input"
                          type="radio"
                          id="unedited"
                          value="unedited"
                          checked={formValue.is_edited === "unedited"}
                          onChange={(e) => handleChange(e)}
                          name="is_edited"
                        />
                        <label className="text-gray" htmlFor="unedited">
                          Unedited
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ) : <></>} */}
              </div>
              <div style={{ display: isDebug ? "block" : "none" }} className="bottom-border mb-4"></div>

              {/* Tag */}
              <div className="d-flex justify-content-start align-items-center w-100 gap-2 gap-sm-0">
                <p className="fs-16 ctr-label me-3">{t("tags")}</p>
                <div className="d-flex gap-sm-3 gap-1 w-100 align-items-center p-0">
                  <Autocomplete
                    multiple
                    id="hashtags"
                    options={tags || []}
                    open={open}
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    getOptionLabel={(tags) => tags}
                    className={` input-border-gray w-100 ${formValue?.tags?.length > 4 ? " " : "rounded-2"
                      } bg-transparent`}
                    value={formValue.tags}
                    onChange={(e, value) => handleHashtagChange(value)}
                    renderInput={(params) => (
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Tags"
                          placeholder="Select Tags..."
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                <>
                                  {tagLoader ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : null}

                                </>
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}

                        />
                        <img
                          className="ms-2"
                          src={refresh}
                          style={{
                            width: "20px",
                            cursor: "pointer",
                            position: "relative",
                            right: "10px"
                          }}
                          width={"10px"}
                          alt="refresh"
                          onClick={getSearchTags}
                        />
                      </div>
                    )}
                  />
                </div>
              </div>

              <div className="bottom-border my-4"></div>

              {/* Industry */}
              <div
                className={`d-flex p-menuitem-link flex-wrap gap-2 justify-content-between w-100 align-items-center py-4`}
              >
                <CategoryFilter
                  //  filterTags={filterTags}
                  filterTags={{ categories: filterTags?.categories?.slice(0, 1) }}
                  formValue={formValue}
                  setFormValue={setFormValue}
                />
              </div>

              {/* CTR */}
              <div className="d-flex justify-content-between align-items-center w-100 gap-2 gap-sm-0">
                <div className="d-flex justify-content-start align-items-center w-100 gap-2 gap-sm-0 pb-3">
                  <p className="mb-0 text-gray ctr-label me-3">{t("ctr")}</p>
                  <input
                    className="ctrwidth w-100 text-gray rounded-2 border border-dark bg-white"
                    type="text"
                    pattern="\d*"
                    inputMode="numeric"
                    placeholder={0}
                    name="ctr"
                    max={100}
                    min={0}
                    onChange={(e) => handleChange(e)}
                    value={formValue.ctr}

                  />
                  <p className="mb-0 text-gray">%</p>
                </div>
              </div>
              <div className="bottom-border"></div>

              <div
                className={`d-flex p-menuitem-link flex-wrap gap-2 justify-content-between w-100 align-items-center py-4`}
              >
                <CategoryFilter
                  filterTags={{ categories: filterTags?.categories?.slice(1) }}
                  // filterTags={filterTags}
                  formValue={formValue}
                  setFormValue={setFormValue}
                />
              </div>


            </>
          </div>
        </div>
        <div className="d-flex justify-content-center">

          <button
            className="w-100 max-w-300 rounded-pill border-0 w-100 p-2 my-3 btn me-3"
            onClick={() => goToDashbaord()}
          >
            {t("cancel")}
          </button>


          <button
            className="w-100 max-w-300 rounded-pill border-0 w-100 p-2 my-3 btn"
            onClick={() => fetchSearchText()}
          >
            {t("search")}
          </button>
        </div>

      </div>
    </div>
  );
};

export default InitialSearch;
