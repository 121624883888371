import React, { useContext, useEffect, useRef, useState } from "react";
import WebFont from "webfontloader";
import image1 from "../../../assets/images/image1.jpg";
import closebtn from "../../../assets/newicons/close.svg";
import resizeIcon from "../../../assets/newicons/resize-icon.svg";
import threeDots from "../../../assets/newicons/three-dots.svg";
import Close from '../../../assets/newicons/close.svg';
import './SideMenu.scss';
import { Skeleton, Box } from "@mui/material";
import ImageContext from "./ImageContext";
import { Button, CircularProgress, Menu, MenuItem } from "@mui/material";
import { useSearchContext } from "../../../context/useSearchContext";
import { useTranslation } from 'react-i18next';
import { useAuthContext } from "../../../context/useAuthContext";
import { imageGet, newDelete, newGet, newPost, newPut, post } from '../../../API/axios';
import { toast } from 'react-toastify';
import UploadDesignFile from "./UploadDesignFile";
import { showAlert } from "./AlertService";
import BrowseSearch from "./BrowseSearch";
import { useReferenceContext } from "../../../context/useReferenceContext";
import { showConfirmationDialog } from "./AlertService";

const SideMenu = ({ onClose, setSelectedFont, typeChange, setTypeChange, createdProjectId, deleteImages,isProjectActive }) => {
    const [isImageLayerOpen, setIsImageLayerOpen] = useState(false);
    const {  setResetPopup,  setImages } = useContext(ImageContext);
    const [selectedImages, setSelectedImages] = useState([])
    const [anchorEl, setAnchorEl] = useState(null);

    const [resetData, setResetData] = useState({});
    const [offSetId, setOffSetId] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [isImageUploaded, setIsImageUploaded] = useState(false);
    const [isImageListLoading, setIsImageListLoading] = useState(false);
   
    const [menuState, setMenuState] = useState({});
    const [imageUrl, setImageUrl] = useState('');
    const { boardId, setBoardId, uploadedFiles, setUploadedFiles } = useReferenceContext();
    const [showImageDetails, setShowImageDetails] = useState(false);
    const [imageDetails, setImageDetails] = useState([]);

    const [fileData, setFiledata] = useState({
        file_id: "",
        file_name: ""
    });

    const [loading, setLoading] = useState(false);
    const [draggedItemIndex, setDraggedItemIndex] = useState(null);
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const [formData, setFormData] = useState({
        input_text: "",
        page: 1,
        ctr: 0,
        tags: [],
        model_filters: [],
        is_edited: "all",
        search_by: "desc",
    });

    const { setResults, setNumberOfResults, setShowResults, setFormValue } = useSearchContext();
    const { selectedSite } = useAuthContext();

    const open = Boolean(anchorEl);
    const handleClick = (event, index) => {
        setMenuState({
            ...menuState,
            [index]: {
                anchorEl: event.currentTarget,
                open: true,
            },
        });
    };
    const handleClose = (index) => {
        setMenuState({
            ...menuState,
            [index]: {
                anchorEl: null,
                open: false,
            },
        });
    };

    const loadGoogleFonts = () => {
        WebFont.load({
            google: {
                families: [
                    'Qwitcher Grypen', 'Space Grotesk', 'Epilogue', 'Lexend',
                    'Outfit', 'Clash Display', 'Red Hat Display', 'Sora',
                    'Be Vietnam Pro', 'General Sans'
                ]
            }
        });
    };

    const modalOpen = () => {
        setResetPopup('');
        setResetPopup('reset');
    }

    const getUploadedFiles = async () => {
        try {
            const data = await newGet(`board/get?project_id=${createdProjectId}`);
            // const designFilterData = data?.result.filter((design => design?.design_id === design_id))
            setUploadedFiles(data?.result?.files);
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        loadGoogleFonts();
        getUploadedFiles();
    }, []);

    const fonts = [
        { family: 'Qwitcher Grypen', name: 'Qwitcher Grypen' },
        { family: 'Space Grotesk', name: 'Space Grotesk' },
        { family: 'Epilogue', name: 'Epilogue' },
        { family: 'Lexend', name: 'Lexend' },
        { family: 'Outfit', name: 'Outfit' },
        { family: 'Clash Display', name: 'Clash Display' },
        { family: 'Cabinet Grotesk', name: 'Cabinet Grotesk' },
        { family: 'GT Walsheim', name: 'GT Walsheim' },
        { family: 'Red Hat Display', name: 'Red Hat Display' },
        { family: 'Sora', name: 'Sora' },
        { family: 'Be Vietnam Pro', name: 'Be Vietnam Pro' },
        { family: 'General Sans', name: 'General Sans' }
    ];

    const [tab, setTab] = useState(2);

    const fileInputRef = useRef(null);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const newImage = {
                    url: e.target.result,
                    rotation: 0,
                    id: "image_" + Date.now(),
                    x: 50,
                    y: 50,
                };
                setSelectedImages((prevImages) => {
                    const updatedImages = [...prevImages, newImage];
                    return updatedImages;
                });

            };
            reader.readAsDataURL(file);
        }
    };

    const handleUploadClick = () => {
        // fileInputRef.current.click();
        setIsImageUploaded(true);
    };
    const handleDragStart = (e, fontFamily) => {
        e.dataTransfer.setData('text/plain', fontFamily);
        setSelectedFont(fontFamily)
        e.currentTarget.style.opacity = '0.5';
    };
    const handleDragStart1 = (e, item) => {
        if(!isProjectActive()){
            return
        }
        // Set the drag data with both the URL and file info
        const dragData = {
            type: 'excalidraw-image',
            src: item?.file_url,
            id: item?.file_id,
            fileName: item?.file_name
        };
        
        e.dataTransfer.setData('application/json', JSON.stringify(dragData));
        
        // Create a smaller drag preview
        // const img = new Image();
        // img.src = item?.file_url;
        // img.style.width = "50px"; // Set smaller width
        // img.height = "50px"; // Set smaller height
        // img.style.maxWidth = '200px';
        // e.dataTransfer.setDragImage(img, 10, 10);
        
        // Set effectAllowed to copy to indicate this is a copy operation
        e.dataTransfer.effectAllowed = 'copy';
    };

    const handleDragEnd = (e) => {
        e.currentTarget.style.opacity = '1';
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.dataTransfer.getData('text/plain');
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDragStartt = (index) => {
        setDraggedItemIndex(index);
    };

    const handleDragOverr = (event) => {
        event.preventDefault(); // Prevent default to allow dropping
    };

    const handleSetImageUrl = (url) => {
        setImageUrl(url);
    };

    const onHandleSelectedImage = async () => {
        const formData = {
            board_id: boardId,
            file_url: imageUrl
        }

        try {
            const data = await newPost(`file/insert`, formData);
            if (data.status_code == 200 || data.status_code == 201) {
                // getUploadedFiles();
                if (Array.isArray(data.result)) {
                    const newImageDetails = data.result.map(item => ({
                        ...item,
                        base64: item.file_url // Assuming file_url is the URL to the image
                    }));
                    setImageDetails(prevDetails => [...prevDetails, ...newImageDetails]);
                    setUploadedFiles(prevFiles => [...prevFiles, ...newImageDetails]);
                    setImages(newImageDetails);
                } else {
                    const newImageDetail = {
                        ...data.result,
                        base64: data.result.file_url // Assuming file_url is the URL to the image
                    };
                    setImageDetails(prevDetails => [...prevDetails, newImageDetail]);
                    setUploadedFiles(prevFiles => [...prevFiles, newImageDetail]);
                    setImages([newImageDetail]);
                }
                // setLoading(false);
                const modalElement = document.getElementById('search_modal');
                const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
                modalInstance.hide();
                setShowImageDetails(false)

                // showAlert("File Name Changed", "success")
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const handleDropp = (index) => {
        if (draggedItemIndex !== null) {
            const updatedFiles = [...uploadedFiles];
            const draggedItem = updatedFiles[draggedItemIndex];
            updatedFiles.splice(draggedItemIndex, 1); // Remove the dragged item
            updatedFiles.splice(index, 0, draggedItem); // Insert it at the dropped position

            uploadedFiles(updatedFiles);
            setDraggedItemIndex(null); // Reset dragged item index
        }
    };

    const hadleSetImage = () => {
        if(!isProjectActive()){
            return
        }
        setIsImageLayerOpen(true)
    };

    const handleSearchInput = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const resetSearch = () => {
        setNumberOfResults({
            primary: 0,
            similar: 0,
        });
        setFormData({ ...formData, 'page': 1 });
    }

    const getSearchText = async (isNextPage = false) => {

        // setLoading(true);
        // setIsImageListLoading(true)

        let payload;
        if (!isNextPage) {
            resetSearch();
            setIsImageListLoading(true)
        }

        if (formData?.input_text) {
            payload = {
                input_text: formData.input_text,
                lang: currentLanguage, //en or ja
                page: isNextPage ? formData.page + 1 : 1,
                ctr: Number(formData.ctr),
            };

            if (
                formData?.input_text ||
                formData?.ctr ||
                formData.tags.length !== 0
            ) {
                payload = {
                    input_text: formData.input_text,
                    lang: currentLanguage, //en or ja
                    page: isNextPage ? formData.page + 1 : 1,
                    ctr: Number(formData.ctr),
                    is_edited: formData.is_edited,
                };
                if (formData.tags.length > 0) {
                    payload.filter_tags = formData.tags;
                }
            }
        } else {
            payload = {
                input_text: formData.input_text,
                lang: currentLanguage, //en or ja
                page: isNextPage ? formData.page + 1 : 1,
                ctr: Number(formData.ctr),
                is_edited: formData.is_edited,
            };

        }

        if (isNextPage) {
            // setScrollLoader(true);
            // setIsImageListLoading(true)
            setFormData({ ...formData, page: formData.page + 1 });
        }
        payload.site_id =
            typeof selectedSite?.site_id == "object"
                ? selectedSite?.site_id
                : [selectedSite?.site_id];
        const { tags, ctr, input_text, lang, page, site_id, is_edited, ...rest } =
            formData;

        payload = {
            ...payload,
            ...rest,
        };

        if (
            !payload?.input_text &&
            payload?.ctr <= 0 &&
            !payload?.filter_tags?.length &&
            !payload?.delivery_content?.length &&
            !payload?.delivery_type?.length &&
            !payload?.image_type?.length &&
            !payload?.industry?.length &&
            !payload?.model_filters?.length &&
            !payload?.is_edited
        ) {
            toast.info("Apply Atleast One Filter!");
            // setLoading(false);
            return;
        }

        setShowResults(true);
        setResetData(formData);
        await post(`text_search_image/`, ((offSetId && isNextPage) ? { offset_id: offSetId, ...payload } : payload))
            .then((response) => {
                setNumberOfResults({
                    total_images: response?.pagination?.total_images_in_page,
                    primary: response?.pagination?.total_images,
                    similar: response?.pagination?.total_images_in_current_page,
                });
                setOffSetId(response?.pagination?.offset_id)
                if (!isNextPage) {
                    setSearchResults(response?.data);
                    setResults(response?.data);
                } else {
                    if (response?.data?.length > 0) {
                        setSearchResults((prevResults) => [
                            ...prevResults,
                            ...response?.data,
                        ]);
                        setResults((prevResults) => [...prevResults, ...response?.data]);
                    }
                }
                setTimeout(() => {
                    // setLoading(false);
                    // setScrollLoader(false);
                    setIsImageListLoading(false)
                }, 1000);

            })
            .catch((error) => {
                // setLoading(false);
                setShowResults(true);
                // setScrollLoader(false);
                setIsImageListLoading(false)
                console.error("error", error);
            })
            .finally(() => {

            });
    };

    const handleInputSearchText = (e) => {
        e.preventDefault();
        getSearchText()
    }

    const layoutData = {
        layouts: [
            {
                elements: [
                    {
                        type: 'image',
                        id: `image_${Date.now()}`,
                        properties: {
                            src: image1
                        }
                    },
                    {
                        type: 'image',
                        id: `image_${Date.now()}`,
                        properties: {
                            src: image1
                        }
                    },
                    {
                        type: 'image',
                        id: `image_${Date.now()}`,
                        properties: {
                            src: image1
                        }
                    },
                    {
                        type: 'image',
                        id: `image_${Date.now()}`,
                        properties: {
                            src: image1
                        }
                    },
                    {
                        type: 'image',
                        id: `image_${Date.now()}`,
                        properties: {
                            src: image1
                        }
                    }
                ]
            }
        ]
    };

    const downloadImage = async (url,item) => {
        try {
            const response = await imageGet("download_image", { image_url: url });
            const downloadUrl = URL.createObjectURL(response);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.download = item?.file_name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(downloadUrl);
        }
        catch (error) {
            console.error("Error downloading image:", error);
        }
    }

    const deleteImage = async (id) => {
        try {
            // Show confirmation dialog first
            const confirmation = await showConfirmationDialog(
                t("deleteFileConfirmation"), // Add this to your translation files
                t("deleteFileWarning"), // Add this to your translation files
                "warning",
                t("cancel"),
                t("delete")
            );

            // Only proceed if user confirms
            if (confirmation) {
                const data = await newDelete(`file/delete?file_id=${id}`);
                if (data.status_code == 200 || data.status_code == 201) {
                    const updatedFiles = uploadedFiles.filter(file => file.file_id !== id);
                    setUploadedFiles(updatedFiles);
                    deleteImages(id);
                    showAlert(t("fileDeletedSuccess"), "success"); // Add this to your translation files
                }
            }
        } catch (error) {
            console.log(error);
            showAlert(t("fileDeleteError"), "error"); // Add this to your translation files
        }
    };

    const renameFile = async (id) => {
        setLoading(true);
        const formData = {
            file_id: id,
            file_name: fileData?.file_name
        }

        try {
            const data = await newPut(`file/update`, formData);
            if (data.status_code == 200 || data.status_code == 201) {
                getUploadedFiles();
                setLoading(false);
                const modalElement = document.getElementById('renameModal');
                const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
                modalInstance.hide();

                showAlert("File Name Changed", "success")
            }
        }
        catch (error) {
            console.log(error)
        }

    }
    const convertToBase64 = async (url) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();

            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = (err) => reject(err);
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error("Error converting image to Base64:", error);
            return null;
        }
    };

    const handleFilesFetched = async (fetchedFiles) => {
        if (fetchedFiles && fetchedFiles.length > 0) {
            try {
                // Convert all file URLs to base64 in parallel
                const newImageDetails = await Promise.all(
                    fetchedFiles.map(async (item) => {
                        const { file_url } = item;
                        const base64String = await convertToBase64(file_url);
                        return { ...item, file_url, base64: base64String };
                    })
                );

                // Update the state with the new image details
                setImageDetails(prevDetails => [...prevDetails, ...newImageDetails]);
                setUploadedFiles(prevFiles => [...prevFiles, ...newImageDetails]);

            } catch (error) {
                console.error("Error converting files to base64:", error);
            }
        }
    };



    //   const handleFilesFetched = (fetchedFiles) => {
    //     console.log("Files received from child:", fetchedFiles);
    //     setFiles(fetchedFiles);

    //     if (fetchedFiles && fetchedFiles.length > 0) {
    //         fetchedFiles.map((item)=>{
    //             console.log("item", item)
    //             setImageUrl(item?.file_url)
    //         })

    //     }
    //   };

    // useEffect(() => {
    //     getUploadedFiles()
    //   }, [imageDetails]);


    //  useEffect(() => {
    //     if (imageUrl) {

    //         convertToBase64(imageUrl)
    //             .then((base64String) => {
    //                 console.log("Base64 String:", base64String); 
    //                 setBase64(base64String);
    //             })
    //             .catch((error) => console.error("Conversion failed:", error));
    //     }
    // }, [imageUrl]);


    return (
        <div >
            {
                typeChange === 'image' && (
                    <div className='position-relative'>
                        <div className='border-right ps-md-3 pe-md-3 pb-md-3 ps-2 pe-2 pb-2 pt-0 bg-white font-noto-sans tabs-conatainer' style={{ border: "1px solid #ddd" }}>
                            <div className='d-flex align-items-center justify-content-between pb-2 pt-3 px-2  bg-white position-sticky top-0'>
                                <h5 className='fs-14 font-noto-sans fw-600 mb-0'>{t("browse")}</h5>
                                <div className='d-flex align-items-center gap-2'>
                                    <button className='bg-transparent border-0 p-0 d-flex align-items-center justify-content-center'
                                        data-bs-toggle="modal"
                                        data-bs-target="#search_modal"
                                        type="button"
                                        disabled={!isProjectActive()}
                                        onClick={() => {
                                            setShowResults(true); modalOpen(); setResults([]); setFormValue((prevFormValue) => ({
                                                ...prevFormValue,
                                                input_text: '',
                                            }));
                                        }}
                                    >
                                        <img src={resizeIcon} alt='resizeIcon' onClick={() => { setSearchResults([]) }} />
                                    </button>
                                    <button className='bg-transparent border-0 p-0 d-flex align-items-center justify-content-center'>
                                        <img src={closebtn} alt='closebtn' onClick={onClose} />
                                    </button>
                                </div>
                            </div>
                            <div className='p-2'>
                                {/* <input
                                    type='file'
                                    accept='image/*'
                                    ref={fileInputRef}
                                    onChange={handleImageUpload}
                                    style={{ display: 'none' }}
                                /> */}

                                {/* <button
                                    className='d-flex align-items-center justify-content-center gap-2 w-100 border-0 black-gradiant-btn'
                                    onClick={() => { setTypeChange('interests') }}
                                >
                                    <img src={aiIcon} alt='aiIcon' />
                                    <span>Generate with MicoAI</span>
                                </button> */}

                                <button
                                    className='text-center w-100 border-0 mt-2 fw-600 fs-14 py-md-2 py-2 text-size-btn'
                                    style={{ borderRadius: "8px" }}
                                    // onClick={handleUploadClick}
                                    disabled={!isProjectActive()}
                                    data-bs-toggle="modal" data-bs-target="#add_design"
                                >
                                    Upload
                                </button>
                            </div>
                            {/* {isImageUploaded && ( <UploadDesignFile /> )} */}
                            <div className="w-100 d-flex flex-lg-row flex-column p-0">
                                <div className="p-2 w-100" >
                                    <div className='d-flex align-items-center gap-2'>
                                        {/* <div className={`font-noto-sans add_navbar add_navbar-fs ${tab === 1 && "active"} cursor_pointer`} onClick={() => setTab(1)}>
                                            <p className="fs-14 fw-600">{t("quickSearch")}</p>
                                        </div> */}
                                        <div className={`font-noto-sans add_navbar add_navbar-fs ${tab === 2 && "active"} cursor_pointer`} onClick={() => setTab(2)}>
                                            <p className="fs-14 fw-600"> {t("uploadedFiles")}</p>
                                        </div>
                                    </div>

                                    {tab === 1 &&
                                        <div>
                                            {/* <form onSubmit={handleInputSearchText}>
                                                <div className='position-relative w-90 mt-4 mb-4 mt-md-4 mt-3 mb-3'>
                                                    <div className='position-relative tabs-search'>
                                                        <input
                                                            className="form-control"
                                                            name='input_text'
                                                            value={formData.input_text}
                                                            onChange={handleSearchInput}
                                                            placeholder="Search"
                                                            aria-label="Search"
                                                        />
                                                        <img src={Search1} className='position-absolute' alt='search' />
                                                    </div>
                                                </div>
                                            </form> */}
                                            <div>
                                                <div className='tabs-grid-image'>
                                                    {/* {layoutData.layouts[0].elements.map((element, index) => (

                                                        <div className='d-flex align-items-center justify-content-center tabs-image'
                                                            draggable
                                                            onDragStart={(e) => handleDragStart1(e, element)} //
                                                            onClick={hadleSetImage}
                                                            >
                                                            <img style={{ width: "100%" }} src={element.properties.src || noImage} alt={`image_${index}`} />
                                                        </div>
                                                    ))} */}



                                                    {isImageListLoading ? (
                                                        <>
                                                            {[...Array(30)].map((_, i) => (
                                                                // <ImageListLoader height="100" key={i} />
                                                                <Box
                                                                    className="max-w-170 image-card"
                                                                    // style={{ minHeight: 100 ? height : "" }}
                                                                    width={100}
                                                                    height={100}
                                                                    margin="auto"
                                                                // padding={isSmallScreen ? 1 : 2}
                                                                >
                                                                    <Skeleton variant="rounded" animation="wave" width="100%" height={100} />

                                                                </Box>

                                                            ))}
                                                        </>
                                                    ) : (
                                                        (searchResults && searchResults.length > 0) ? (
                                                            searchResults.map((image, i) => (
                                                                <div key={i} className='d-flex align-items-center justify-content-center tabs-image'
                                                                    draggable
                                                                    onDragStart={(e) => handleDragStart1(e, image)}
                                                                    onClick={hadleSetImage}
                                                                >

                                                                    <img

                                                                        loading="lazy"
                                                                        src={image.image_url}
                                                                        className='rounded-3'
                                                                        alt={image.image_id}
                                                                        style={{ width: "100%" }}
                                                                    />
                                                                </div>
                                                            ))
                                                        ) : (
                                                            // <p className="fs-14">{t("noRecentImagesFound")}</p>
                                                            null
                                                        )
                                                    )

                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {tab === 2 &&
                                        <div>
                                            <form>
                                                <div className='position-relative w-90 mt-4 mb-4 mt-md-4 mt-3 mb-3'>
                                                    {/* <div className='position-relative tabs-search'>
                                                        <input
                                                            className="form-control"
                                                            name='input_text'
                                                            placeholder="Search"
                                                            aria-label="Search"
                                                        />
                                                        <img src={Search1} className='position-absolute' alt='search' />
                                                    </div> */}
                                                </div>
                                            </form>
                                            <div>
                                                {/* <button onClick={loadExampleImage}>Load Example Image</button> */}
                                                <div className='tabs-grid-image'>


                                                    {uploadedFiles !== null ? (
                                                        uploadedFiles.map((item, i) => {
                                                            

                                                            return (
                                                                <div
                                                                    key={item?.file_id}
                                                                    className="d-flex align-items-center justify-content-center"
                                                                    style={{
                                                                        width: "150px"
                                                                    }}
                                                                   
                                                                >
                                                                    <div
                                                                        className="pt-2 px-2"
                                                                        style={{ backgroundColor: "#F6F6F6", width: "100%" }}
                                                                    >
                                                                        <Button
                                                                            id={`basic-button-${i}`}
                                                                            aria-controls={menuState[i]?.open ? `basic-menu-${i}` : undefined}
                                                                            aria-haspopup="true"
                                                                            aria-expanded={menuState[i]?.open ? "true" : undefined}
                                                                            onClick={(event) => handleClick(event, i)}
                                                                            style={{ right: "-18%", float: "right" }}
                                                                            disabled={!isProjectActive()}
                                                                        >
                                                                            <img src={threeDots} alt="threedot" />
                                                                        </Button>

                                                                        {/* Display the base64 image if available */}
                                                                        <img
                                                                            draggable="true"
                                                                            onDragStart={(e) => handleDragStart1(e, item)}
                                                                            src={ item?.file_url || "placeholder-image.jpg"}
                                                                            alt="noImage"
                                                                            height={90}
                                                                            style={{ width: "100%", objectFit: "cover" }}
                                                                            onError={(e) => {
                                                                                e.target.src = "placeholder-image.jpg";
                                                                            }}
                                                                            
                                                                        />

                                                                        <span className="fs-12 font-noto-sans text-truncate">
                                                                            {item?.file_name.length > 15
                                                                                ? item?.file_name.slice(0, 15) + "..."
                                                                                : item?.file_name}
                                                                        </span>
                                                                    </div>
                                                                    <Menu
                                                                        id={`basic-menu-${i}`}
                                                                        anchorEl={menuState[i]?.anchorEl}
                                                                        open={menuState[i]?.open || false}
                                                                        onClose={() => handleClose(i)}
                                                                        MenuListProps={{
                                                                            "aria-labelledby": `basic-button-${i}`,
                                                                        }}
                                                                    >
                                                                        <MenuItem onClick={() => { downloadImage(item?.file_url,item); handleClose(i); }}>
                                                                            Download
                                                                        </MenuItem>
                                                                        <MenuItem onClick={() => { deleteImage(item?.file_id); handleClose(i); }}>
                                                                            Delete
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#renameModal"
                                                                            onClick={() => {
                                                                                setFiledata({
                                                                                    file_id: item?.file_id,
                                                                                    file_name: item?.file_name,
                                                                                });
                                                                                handleClose(i);
                                                                            }}
                                                                        >
                                                                            Rename
                                                                        </MenuItem>
                                                                    </Menu>
                                                                </div>
                                                            );
                                                        })
                                                    ) : (
                                                        <p className="fs-14">No file found</p>
                                                    )}

                                                    <div >


                                                    </div>
                                                    {/* <div className='d-flex align-items-center justify-content-center tabs-image'>
                                                        <img src={noImage} alt='noImage' />
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-center tabs-image'>
                                                        <img src={noImage} alt='noImage' />
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-center tabs-image'>
                                                        <img src={noImage} alt='noImage' />
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-center tabs-image'>
                                                        <img src={noImage} alt='noImage' />
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-center tabs-image'>
                                                        <img src={noImage} alt='noImage' />
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-center tabs-image'>
                                                        <img src={noImage} alt='noImage' />
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-center tabs-image'>
                                                        <img src={noImage} alt='noImage' />
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-center tabs-image'>
                                                        <img src={noImage} alt='noImage' />
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>


                            </div>
                        </div>
                    </div>
                )
            }

            {/* Rename popup */}
            <div className="modal fade" id="renameModal" tabIndex="-1" aria-labelledby="renameModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content px-3">
                        <div className="modal-header border-0 font-noto-sans">
                            <div className='upload_design fw-bolder'>
                                Rename File
                            </div>
                            {/* <div className='cursor_pointer' data-bs-dismiss="modal" >
                                            <img src={Close} alt='Close' />
                                        </div> */}
                        </div>
                        <div className="modal-body font-noto-sans">
                            <input
                                type='text'
                                name="required_sheet"
                                value={fileData?.file_name}
                                onChange={(e) => setFiledata({ ...fileData, file_name: e.target.value })}
                                autoComplete='off'
                                // onWheel={(e) => e.preventDefault()}
                                className="form-control from-input"
                                id="renameFile"
                                // placeholder="Enter Miro Board URL"
                                max={50}>
                            </input>


                        </div>
                        <div className="modal-footer border-0">
                            <p type="button" className="cancel_modal_footer" data-bs-dismiss="modal" >{t("cancel")}</p>
                            <button type="button" className="send_modal_footer" disabled={loading || !fileData?.file_name?.trim()} onClick={() => renameFile(fileData?.file_id)}>
                                {!loading ? `${t("save")}` : ""}
                                <React.Fragment>
                                    {loading ? (
                                        <CircularProgress color="inherit" size={20} />
                                    ) : null}
                                </React.Fragment>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <AddImagePopUp typeChange={typeChange} /> */}

            <div className="modal fade" id="add_design" tabIndex="-1" aria-labelledby="add_designLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content px-3">
                        <div className="modal-header border-0">
                            <div className='upload_design'>
                                Upload design
                            </div>
                            <div className='cursor_pointer' data-bs-dismiss="modal">
                                <img src={Close} alt='Close' />
                            </div>
                        </div>
                        <div className="modal-body">
                            <UploadDesignFile setUploadedFiles={setUploadedFiles} screen="sidemenu" board_id={boardId} createdProjectId={createdProjectId} onFilesFetched={handleFilesFetched} />
                        </div>
                    </div>
                </div>
            </div>


            {/* Add Search Modal */}

            <div className="modal fade" id="search_modal" tabIndex="-1" aria-labelledby="search_modalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-fullscreen modal-dialog-scrollable">
                    <div className="modal-content px-3">
                        <div className="modal-header border-0">
                            <div className='upload_design'>
                                Browse
                            </div>
                            <div className='cursor_pointer' data-bs-dismiss="modal" onClick={() => { setShowImageDetails(false) }}>
                                <img src={Close} alt='Close' />
                            </div>
                        </div>
                        <div className="modal-body">
                            <BrowseSearch onSetImageUrl={handleSetImageUrl} showImageDetails={showImageDetails} setShowImageDetails={setShowImageDetails} />
                        </div>
                        {showImageDetails &&
                            <div className="modal-footer border-0">
                                <p type="button" className="cancel_modal_footer" data-bs-dismiss="modal" onClick={() => { setShowImageDetails(false) }}>{t("cancel")}  </p>
                                <button type="button" className="send_modal_footer" onClick={() => {onHandleSelectedImage(); }}>
                                    Select
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>

        </div>

    );
};

export default SideMenu;