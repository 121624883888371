import * as React from 'react';
import "./header.scss"
import headerLogo from '../../../assets/newicons/header-logo-micovision.svg';
import userSetting from '../../../assets/newicons/user-setting.svg';
import headerSetting from '../../../assets/newicons/header-setting.svg';
import headerBell from '../../../assets/newicons/header-bell.svg';
import logOutImg from '../../../assets/newicons/logout.png';
import HeaderDropdown from './HeaderDropdown';
import { newGet, newPost, newPut } from '../../../API/axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { useAuthContext } from '../../../context/useAuthContext';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import ProjectLoader from '../skeletonLoaders/ProjectLoader';
import { Badge, Skeleton } from '@mui/material';
import translateIcon from '../../../assets/icons/header_icon_2.svg';
import tick from "../../../assets/icons/tick.svg";
import { useSocketContext } from '../../../context/useSocketContext';
import { showAlert } from './AlertService';
import { useSearchContext } from '../../../context/useSearchContext';

export default function Header({ fetchProjects, setIsAll, screen, onChannelNameUpdate,
  //  setSearchView 
}) {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [notificationLoader, setNotificationLoader] = useState(false)
  const [notificationList, setNotificationList] = useState()
  const [slicedNotificationList, setSlicedNotificationList] = useState()
  const [timer, setTimer] = useState(0);
  const dropdownRef = useRef(null);
  const { removeSession, userSettings, initialLoading } = useAuthContext();
  const { setSearchViewState } = useSearchContext()
  const currentLanguage = i18n.language;

  const { onMessage } = useSocketContext();

  const fetchNotification = async () => {
    try {
      setNotificationLoader(true);
      const data = await newGet(`project/getMyNotifications`);
      const list = data?.result?.reverse()
      setNotificationList(list)
      setSlicedNotificationList(list?.slice(0, 5))
      setNotificationLoader(false);
    } catch (error) {
      console.log("error:???????", error);
      setNotificationLoader(false);
    }
  }

  const formatRelativeTime = (dateString) => {
    const date = new Date(dateString);
    return formatDistanceToNow(date, { addSuffix: true });
  };

  const handleKeyDown = (event) => {
    if (dropdownRef.current) {
      const items = Array.from(dropdownRef.current.querySelectorAll('.dropdown-item'));
      const currentIndex = items.indexOf(document.activeElement);

      if (event.key === 'ArrowDown') {
        event.preventDefault();
        const nextIndex = currentIndex + 1 < items.length ? currentIndex + 1 : 0;
        items[nextIndex].focus();
      }

      if (event.key === 'ArrowUp') {
        event.preventDefault();
        const prevIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : items.length - 1;
        items[prevIndex].focus();
      }
    }
  };

  function makeUserArray(input) {
    if (input) {
      const objectsArray = input.split("},").map(item => {
        // Add back the closing curly brace that was removed during the split (if needed)
        item = item.trim().endsWith("}") ? item : item + "}";

        // Extract the id and username values
        const idMatch = item.match(/id:([a-f0-9\-]+)/);
        const usernameMatch = item.match(/username:([^,}]+)/);

        // Return the object in proper format
        return {
          id: idMatch ? idMatch[1] : null,
          username: usernameMatch ? usernameMatch[1].trim() : null
        };
      });
      return objectsArray
    }
    else {
      return
    }
  }

  const formatCreatedTimestamp = (timestamp) => {
    if (timestamp) {
      const options = { year: 'numeric', month: 'short', day: '2-digit' };
      const date = new Date(timestamp);
      return date.toLocaleDateString('en-US', options);
    } else {
      return "Not specified"
    }
  };

  const getProjectDetail = async (createdProjectId, siteId) => {
    if (createdProjectId && siteId) {
      try {
        const data = await newGet(`project/getProjectDetails?project_id=${createdProjectId}&site_id=${siteId}`);
        return data?.result[0]
      } catch (error) {
        console.log("error:???????", error);
      }
    }
  };

  const handleLogoClick = () => {
    if (screen === "manager" || screen === "settings") {
      navigate('/dashboard');
      // setSearchView(false);
    }
  };


  useEffect(() => {
    if (onMessage) {
      try {
        const socketData = onMessage;
        console.log("Received message", socketData);
        const notificationTriggerEvents = [
          "design_uploaded",
          "create_project",
          "update_project",
          "project_delete",
          "comment_mention",
          "client_design_return",
          "client_design_approve",
          "share_with_client",
          "sheet_revoked",
          "sheet_returned",
          "sheet_approved",
          "sheet_sent_for_approval",
          "comment_added",
          "comment_mention",
        ];

        if (notificationTriggerEvents.includes(socketData.message)) {
          // let datacomments = JSON.parse(JSON.stringify(slicedNotificationList));
          // datacomments = [...datacomments, socketData.data[0]];
          // setSlicedNotificationList(datacomments);
          fetchNotification()
          fetchProjects()
          // setIsAll(true)
        }
      } catch (error) {
        console.error("Failed to parse WebSocket message", error);
      }

    }
  }, [onMessage]);



  const handleLogout = async () => {
    try {
      await newPost(`project/logout`).then((response) => {
        if (response?.status_code == 200) {
          // console.log("response ", response);
          removeSession();
          localStorage.clear();
          localStorage.setItem("selectedLanguage", currentLanguage);
        }
      })
    }
    catch (error) {
      console.log(error)
    }

  };

  const handleNavigation = (path) => {
    navigate(path);
  };



  const handleNotification = async (notification) => {

    const obj = JSON.parse(notification.redirect)
    console.log("notification Board", obj.board_id);
    console.log("notification design", obj.design_id);
    let data = {
      notification_ids: [notification?.notification_id]
    }
    console.log("notification Object", obj);
    try {
      await newPut(`project/updateNotification`, data).then((response) => {
        if (response?.status_code == 200) {
          fetchNotification()
          console.log("response ", response);
        }
      })
    }
    catch (error) {
      console.log(error)
    }

    switch (notification?.notification_type) {
      case "create_project":
      case "update_project":
        if (window.location.pathname === '/reference') {
          // If already on reference page, reload with new state
          window.location.href = `/reference?project_id=${notification?.project_id}&site_id=${obj.site_id}`;
        } else {
          // Otherwise use normal navigation
          navigate(`/reference?project_id=${notification?.project_id}&site_id=${obj.site_id}`, {
            state: {
              siteId: obj.site_id,
              createdProjectId: notification?.project_id,
            },
            replace: true
          });
        }
        break;
      case "project_delete":
        showAlert("Project is deleted", "warning");
        break;
      case "comment_mention":
        if (obj.board_id) {
          navigate(
            `/reference-details?project_id=${obj?.project_id}&channel_id=${obj?.channel_id}&project_name=${obj?.project_name}&channel_name=${obj?.channel_name || ''}`
            , {
              state: {
                createdProjectId: obj?.project_id,
                channelId: obj?.channel_id,
                projectName: obj?.project_name,
                channelName: obj?.channel_name || '',
              },
            });
        }
        else if (obj?.design_id) {
          navigate(
            `/vision-studio?project_id=${obj?.project_id}&project_name=${obj?.project_name}&sheet=${obj?.sheet_id}&channel_name=${obj?.channel_name || ''}&design_id=${obj?.design_id}`
            , {
              state: {
                project_name: obj?.project_name,
                sheet: obj?.sheet_id,
                channelName: obj?.channel_name || '',
                createdProjectId: obj?.project_id
              }
            });
        }
        break;
      case "share_with_client":
        getProjectDetail(notification?.project_id, obj.site_id).then((res) => {
          navigate('/design-version', {
            state: {
              project_id: notification?.project_id,
              version_number: getDesignVersion(notification),
              projectDetails: res,
              assignee: makeUserArray(res?.assign_to_user_name),
              internalApprover: makeUserArray(res?.internal_approver_username),
              clientApprover: makeUserArray(res?.client_approver_username),
              design_id: getDesignId(notification),
            }
          });
        })
        break;
      case "sheet_returned":
      case "sheet_approved":
      case "sheet_revoked":
      case "sheet_sent_for_approval":
        navigate(
          `/vision-studio?project_id=${obj?.project_id}&project_name=${obj?.project_name}&sheet=${obj?.sheet_id}&channel_name=${obj?.channel_name || ''}&design_id=${obj?.design_id}`
          , {
            state: {
              project_name: obj?.project_name,
              sheet: obj?.sheet_id,
              channelName: obj?.channel_name || '',
              createdProjectId: obj?.project_id
            }
          });
        break;
      default:
        if (window.location.pathname === '/reference') {
          window.location.href = `/reference?project_id=${notification?.project_id}&site_id=${obj.site_id}`;
        } else {
          navigate(`/reference?project_id=${notification?.project_id}&site_id=${obj.site_id}`, {
            state: {
              siteId: obj.site_id,
              createdProjectId: notification?.project_id
            }
          });
        }
        break;
    }
  };

  const clearAllNotifications = async () => {
    const notificationIds = notificationList.map((notification) => notification.notification_id)

    const data = {
      notification_ids: notificationIds
    }
    try {
      await newPut(`project/updateNotification`, data).then((response) => {
        if (response?.status_code == 200) {
          console.log("response ", response);
          fetchNotification()
        }
      })
    }
    catch (error) {
      console.log(error)
    }
  }

  const getProjectName = (notification) => {
    const obj = JSON.parse(notification.redirect)
    return obj?.project_name
  }

  const getDesignVersion = (notification) => {
    const obj = JSON.parse(notification.redirect)
    return obj?.version_number;
  }
  const getDesignId = (notification) => {
    const obj = JSON.parse(notification.redirect)
    return obj?.design_id;
  }

  const handleLanguageSelection = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem("selectedLanguage", language);
  };

  useEffect(() => {

    // const newDate = new Date(2024, 7, 17, 11, 40, 10)
    // console.log('newDate: ', newDate)

    const result = formatDistanceToNow(
      new Date(2024, 7, 17, 11, 40, 10),
      { includeSeconds: true }
    )
    fetchNotification()
    const interval = setInterval(() => {
      setTimer(prevTimer => prevTimer + 1);
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const dropdown = dropdownRef.current;
    if (dropdown) {
      const dropdownToggle = dropdown.previousElementSibling;

      const handleDropdownToggle = (event) => {
        setTimeout(() => {
          const firstItem = dropdown.querySelector('.dropdown-item');
          if (firstItem) {
            // firstItem.focus();
          }
        }, 0);
      };

      dropdownToggle.addEventListener('click', handleDropdownToggle);

      return () => {
        dropdownToggle.removeEventListener('click', handleDropdownToggle);
      };
    }
  }, []);

  useEffect(() => {
    if (userSettings?.channel_name && onChannelNameUpdate) {
      onChannelNameUpdate(userSettings.channel_name);
    }
  }, [userSettings?.channel_name, onChannelNameUpdate]);
  return (
    //bg-light
    <nav className="navbar navbar-expand-md ">
      <div className="container-fluid px-lg-5 px-3">
        <div>
          {/* <img src={headerLogo} className='' alt="logo" /> */}
          <div className='d-xxl-flex d-xl-flex d-lg-flex d-md-flex d-sm-flex d-xs-flex d-flex justify-content-between w-100'>
            <Link to="/dashboard">
              <img src={headerLogo} className='' alt="logo" onClick={handleLogoClick} />
            </Link>
            <div className='d-xxl-flex d-xl-flex d-lg-flex d-md-flex d-sm-none d-xs-none d-none ms-4 gap-lg-4 align-items-center'>
              <div className='border-line d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-xs-none d-none'></div>
              <HeaderDropdown screen={screen} />
            </div>
          </div>
        </div>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon d-flex justify-content-end align-items-center">
            <i className="fa-solid fa-ellipsis-vertical"></i>
          </span>
        </button>
        <div className="collapse navbar-collapse justify-content-end mt-2 mt-md-0" id="navbarTogglerDemo02">
          <div className='d-flex justify-content-md-end justify-content-between w-100'>

            <div className=' d-md-none d-block ms-md-4 gap-lg-4 align-items-center'>
              <div className='border-line d-lg-block d-none'></div>
              <HeaderDropdown />
            </div>

            <div className={`d-flex justify-content-end align-items-center ${screen !== "settings" ? "gap-md-3 gap-2" : ""}`}>

              {/* <div>
                <div className="btn-group dropstart">
                  <img src={headerSetting} className='cursor-pointer' onClick={() => { handleNavigation('/settings') }} alt="headerSetting" />
                  <div className="dropdown-menu setting">
                    <div className='dropdown-item border-0 p-3' >

                      <div > {t("settings")}</div>
                    </div>
                    <div className='dropdown-item border-0 p-3' onClick={handleLogout}>
                      <div  > {t("logout")}</div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div>
                {initialLoading ? (
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={40}
                    height={40}
                  />
                ) : (
                  <div className="btn-group dropstart">
                    <img src={translateIcon} width={45} className='cursor-pointer dropdown-image-width' alt="translateIcon" data-bs-toggle="dropdown" />
                    <div className="dropdown-menu dropdown-menu-end profile">

                      <div className='dropdown-item border-0 p-3' onClick={() => handleLanguageSelection("en")}>
                        {currentLanguage === "en" ? (
                          <img className="img-fulid" height={20} width={20} src={tick} alt="currentLang" />
                        ) : <></>}<span className="ms-1" style={{ paddingLeft: currentLanguage === "en" ? 0 : "20px" }}>English</span>
                      </div>
                      <div className='dropdown-item border-0 p-3' onClick={() => handleLanguageSelection("ja")}>
                        {currentLanguage === "ja" ? (
                          <img className="img-fulid" height={20} width={20} src={tick} alt="currentLang" />
                        ) : <></>}<span className="ms-1" style={{ paddingLeft: currentLanguage === "ja" ? 0 : "20px" }}>日本語</span>
                      </div>

                    </div>
                  </div>

                )}
              </div>

              <div>
                {screen !== "settings" &&
                  <div className="btn-group dropstart" >

                    <Badge badgeContent={notificationList?.length} color="primary" >
                    </Badge>
                    <>
                      <img src={headerBell} className='cursor-pointer dropdownMenuNotification-width' alt="headerBell" data-bs-toggle="dropdown" aria-expanded="false" id="dropdownMenuNotification" />
                      <div
                        className="dropdown-menu dropdown-menu-end notification"
                        ref={dropdownRef}
                        onKeyDown={handleKeyDown}
                        tabIndex={-1}
                        style={{ boxShadow: "rgb(0 0 0 / 53%) 0px 0px 10px 0px" }}
                      >
                        <div className='p-3'>
                          <div className='main_title_drop'>{t("notifications")}</div>
                        </div>
                        <div style={{ maxHeight: "195px", overflowY: "auto", overflowX: "hidden" }}>
                          {notificationLoader ? (
                            <div className='px-3'>
                              <ProjectLoader height={90} />
                              <ProjectLoader height={90} />
                            </div>
                          ) : (
                            slicedNotificationList?.length > 0 ? (
                              slicedNotificationList?.map((notification, i) => {
                                let type = notification?.notification_type
                                return (
                                  <div
                                    key={i}
                                    className='dropdown-item border-0 p-3 d-flex align-content-center gap-3'
                                    tabIndex={0}
                                    onClick={() => handleNotification(notification)}
                                  >
                                    {notification?.user_image_url ? (
                                      <img src={notification.user_image_url}
                                        height={50}
                                        width={50}
                                        minWidth={50}
                                        className='cursor-pointer bg-secondary'
                                        alt="headerProfile"
                                        data-bs-toggle="dropdown"
                                        style={{ borderRadius: "50%", minWidth: "50px", }}
                                      />
                                    ) : (
                                      <div
                                        className='cursor-pointer bg-secondary d-flex align-items-center justify-content-center'
                                        data-bs-toggle="dropdown"
                                        style={{
                                          height: 50,
                                          width: 50,
                                          borderRadius: "50%",
                                          color: "white",
                                          fontSize: "22px",
                                          minWidth: "50px"
                                        }}
                                      >
                                        {notification?.created_details[0]?.username?.charAt(0).toUpperCase()}
                                      </div>
                                    )}
                                    {/* <div>
                                  <img src={userSettings?.user_image_url}
                                    onError={() => {
                                      userSettings?.username?.charAt(0).toUpperCase()
                                    }}
                                    height={50} width={50} alt='profile' className='nofi_profile'
                                    style={{ backgroundColor: "gray", borderRadius: "50%", minHeight: "50px", minWidth: "50px" }}
                                  />
                                </div> */}
                                    <div>
                                      {type === "create_project" &&
                                        <div className="profile_tile">{notification?.created_details[0]?.username} assigned you "{getProjectName(notification)}" project.</div>
                                      }
                                      {type === "update_project" &&
                                        <div className="profile_tile">{notification?.created_details[0]?.username} updated the "{getProjectName(notification)}" project.</div>
                                      }
                                      {type === "project_delete" &&
                                        <div className="profile_tile">{notification?.created_details[0]?.username} deleted "{getProjectName(notification)}" project.</div>
                                      }
                                      {type === "comment_mention" &&
                                        <div className="profile_tile">{notification?.created_details[0]?.username} mentioned you in the "{getProjectName(notification)}" project.</div>
                                      }
                                      {type === "design_uploaded" &&
                                        <div>
                                          <div className="profile_tile">{notification?.created_details[0]?.username} has submitted the design for approval.</div>
                                          {/* <div className="profile_tile_short_des">“For your approval”</div> */}
                                        </div>
                                      }
                                      {type === "share_with_client" &&
                                        <div className="profile_tile">{notification?.created_details[0]?.username} shared the designs of the "{getProjectName(notification)}" project.</div>
                                      }
                                      {type === "sheet_returned" &&
                                        // <div className="profile_tile">{notification?.created_details[0]?.username} returned the design for the "{getProjectName(notification)}" project.</div>
                                        <div className="profile_tile">{notification?.notification_content} </div>
                                      }
                                      {type === "sheet_approved" &&
                                        // <div className="profile_tile">{notification?.created_details[0]?.username} approved the design for the "{getProjectName(notification)}" project.</div>
                                        <div className="profile_tile">{notification?.notification_content} </div>
                                      }
                                      {type === "sheet_revoked" &&
                                        <div className="profile_tile">{notification?.notification_content} </div>
                                      }
                                      {type === "sheet_sent_for_approval" &&
                                        <div className="profile_tile"> {notification?.notification_content}</div>
                                      }

                                      {/* <div className="profile_tile_short_des">“For your approval”</div> */}
                                      {/* <div className="profile_time">just now</div> */}
                                      <div className="profile_time"> {formatRelativeTime(notification?.created_timestamp)}</div>
                                    </div>
                                  </div>
                                )
                              })
                            ) : (
                              <div className='dropdown-item border-0 p-3 d-flex align-content-center gap-3'>
                                <div className="profile_tile">{t("noNotifications")}</div>
                                {/* <div className=""> {formatRelativeTime("2024-08-17 15:30:00 +0900")}</div> */}
                              </div>
                            )
                          )}
                          {notificationList && notificationList?.length > 0 ?
                            (notificationList?.length > 5 && slicedNotificationList?.length !== notificationList?.length) ? (
                              <div className='d-flex'>
                                <div
                                  className='link-secondary ps-4 text-decoration-underline cursor_pointer'
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setSlicedNotificationList(notificationList);
                                  }}
                                >
                                  See more
                                </div>

                                <div
                                  className='link-secondary ps-4 text-decoration-underline cursor_pointer'
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    clearAllNotifications()
                                  }}
                                >
                                  {t("clearAll")}
                                </div>
                              </div>
                            ) : (
                              notificationList?.length > 5 &&
                              <div className='d-flex'>
                                <div
                                  className='link-secondary ps-4 text-decoration-underline cursor_pointer'
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setSlicedNotificationList(notificationList?.slice(0, 5))
                                  }}
                                >
                                  See less
                                </div>
                                <div
                                  className='link-secondary ps-4 text-decoration-underline cursor_pointer'
                                  onClick={(event) => {
                                    event.stopPropagation();

                                  }}
                                >
                                  {t("clearAll")}
                                </div>
                              </div>
                            ) : (<></>)
                          }
                        </div>
                      </div>
                    </>
                  </div>
                }
              </div>


              <div>
                <div className="btn-group dropstart">
                  {userSettings?.user_image_url ? (
                    <img src={userSettings.user_image_url} height={30} width={30}
                      className='cursor-pointer bg-secondary'
                      alt="headerProfile"
                      data-bs-toggle="dropdown"
                      style={{ borderRadius: "50%" }}
                    />
                  ) : (
                    <div style={{ marginLeft: "18px" }}
                      className='cursor-pointer bg-secondary d-flex align-items-center justify-content-center profile-manu'
                      data-bs-toggle="dropdown"

                    >
                      <span>
                        {userSettings?.username?.charAt(0).toUpperCase()}
                      </span>
                    </div>
                  )}
                  <div className="dropdown-menu dropdown-menu-end profile">
                    {/* <div className='p-3'>
                      <div className='main_title_drop'>{t("profile")}</div>
                    </div> */}
                    <div className='dropdown-item border-0 p-3' onClick={() => { handleNavigation('/settings') }}>
                      <img src={headerSetting} alt="logout" /><span className="ms-1">{t("settings")}</span>
                    </div>
                    {/* {(userSettings?.role_id === 1 || userSettings?.role_id === 2) &&
                      <div className='dropdown-item border-0 p-3' onClick={() => { handleNavigation('/user-settings') }}>
                        <img src={userSetting} alt="logout" /><span className="ms-1">{t("userManagement")}</span>
                      </div>
                    } */}
                    <div className='dropdown-item border-0 p-3' onClick={handleLogout}>
                      <img height={20} className='ms-1' src={logOutImg} alt="logout" /><span className="ms-2">{t("logout")}</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

