import React, { useEffect } from "react";
import loginBackground from "../../assets/icons/login_background.svg";
import "./logout.scss";
import { useAuthContext } from "../../context/useAuthContext";
import { useNavigate } from "react-router-dom";
import MicoCover from "../../components/MicoCover";
import { getAllSearch } from "../../helpers/url";

const LoginNew = () => {
  const { isAuthenticated, user } = useAuthContext();
  const navigate = useNavigate();
  const { state } = getAllSearch();

  const parseState = (state) => {
    if (!state) return {};
    
    const stateParts = state.split(",");
    const stateObj = {};
    stateParts.forEach((part) => {
      const [key, value] = part.split(":");
      stateObj[key] = value;
    });
    return stateObj;
  };

  useEffect(() => {
    // Save state parameter to localStorage if it exists
    if (state) {
      localStorage.setItem('loginRedirectState', state);
    }
  }, [state]);

  useEffect(() => {
    if (user && isAuthenticated) navigate("/search");
  }, [isAuthenticated, user]);

  return (
    <div className="homePageBg-login">
      <div className="row h-100">
        <div
          className="col-6 d-flex flex-column justify-content-center align-items-center"
          style={{ textAlign: "start" }}
        >
          <MicoCover showLogin={true} urlParams={parseState(state)} />
        </div>
        <div className="col-6 d-flex justify-content-center align-items-center right">
          <img src={loginBackground} className="img-fluid" alt="template-img" />
        </div>
      </div>
    </div>
  );
};

export default LoginNew;
