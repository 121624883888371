import React, { useCallback, useEffect, useRef, useState } from 'react';
import Header from './component/header';
import Search1 from '../../assets/newicons/search1.svg'
import './manager.scss';
import CreateProjectForm from './component/createProjectForm';
import ProjectTable from './component/ProjectTable';
import { useLocation, useNavigate } from 'react-router-dom';
import { newGet, newPost, newDelete } from '../../API/axios';
import { showAlert, showConfirmationDialog } from './component/AlertService';
import { format, set } from 'date-fns';
import CreateProjectFormLoader from './skeletonLoaders/CreateProjectFormLoader';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../context/useAuthContext';
import Search from '../Search';
import ProjectLoader from './skeletonLoaders/ProjectLoader';
import { useSearchContext } from '../../context/useSearchContext';
import SearchComponent from "../../components/SearchInput/index"
import InitialSearch from '../Search/main';
import { useReferenceContext } from '../../context/useReferenceContext';
const useDebouncedValue = (inputValue, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(inputValue);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(inputValue);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [inputValue, delay]);

    return debouncedValue;
};

const Manager = () => {

    const navigate = useNavigate()
    const location = useLocation();
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [projectList, setProjectList] = useState([]);
    const [filteredProject, setFilteredProject] = useState([]);
    const [userList, setUserList] = useState([]);
    const [clientUserList, setClientUserList] = useState([]);
    const [clientManager, setClientManager] = useState([]);
    const allUsers = [...userList, ...clientUserList]
    const [isWelcomeScreen, setIsWelcomeScreen] = useState(true);
    const [isCreateDisable, setIsCreateDisable] = useState(false);
    const [checked, setChecked] = useState(false);
    const [inputProjectName, setInputProjectName] = useState("");
    const [searchInput, setSearchInput] = useState("");
    const [channelname, setChannelName] = useState("");
    const [showComponent, setShowComponent] = useState(false);
    const [searchIcon, setSearchIcon] = useState(false);
    const [formattedData, setFormattedData] = useState([]);
    const debouncedProjectName = useDebouncedValue(inputProjectName, 300);
    const { saveSelectedSite } = useAuthContext();
    const [isAllUsers, setIsAllUsers] = useState(false);

    useEffect(() => {
        if (projectFormValue.project_name.length >= 1) {
            checkProjectName(inputProjectName)
        }
    }, [debouncedProjectName])

    // const [searchView, setSearchView] = useState(false);
    const { t, i18n } = useTranslation();

    const {
        initialLoading,
        isUserSettingsLoading,
        hasPermission,
        userSettings
    } = useAuthContext();

    const {
        formValue,
        setFormValue,
        setShowResults,
        searchView,
        setSearchViewState
    } = useSearchContext();

    const [isAll, setIsAll] = useState(false);


    const [projectFormValue, setProjectFormValue] = useState({
        project_name: '',
        project_description: '',
        channel: "",
        assign_to: [],
        internal_approver: [],
        client_approver: [],
        // watchlist: false,
        watch_list: [],
        // start_date: '',
        // due_date: '',
        required_sheet: "",
        type_id: '',
        // project_type : ""
    });

    const [errors, setErrors] = useState({
        project_name: '',
        project_description: '',
        channel: '',
        assign_to: "",
        internal_approver: "",
        client_approver: "",
        watch_list: "",
        // start_date: '',
        // due_date: '',
        required_sheet: "",
        type_id: '',
        // project_type : ""
    });

    const checkProjectName = async (value) => {
        if (value.length < 1) {
            setErrors({
                ...errors,
                project_name: "It required at least 1 characters",
            });
            return;
        }
        // Check if the first character is not a single-byte special character
        const firstCharSingleByteSpecialPattern = /^[\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]/; // Range of single-byte special characters
        const firstCharIsAlphanumericOrDoubleByte = /^[a-zA-Z0-9\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF]/;

        if (firstCharSingleByteSpecialPattern.test(value.charAt(0))) {
            setErrors({ ...errors, project_name: "The first character cannot be a single-byte special character." });
            setIsCreateDisable(true);
            return;
        }

        if (!firstCharIsAlphanumericOrDoubleByte.test(value.charAt(0))) {
            setErrors({ ...errors, project_name: "The first character must be alphanumeric or a double-byte character." });
            setIsCreateDisable(true);
            return;
        }

        // Check if the % character is present anywhere in the string
        if (value.includes('%')) {
            setErrors({ ...errors, project_name: "The '%' character is not allowed." });
            setIsCreateDisable(true);
            return;
        }

        // Allow double-byte and special characters except for %
        const validCharactersPattern = /^[a-zA-Z0-9\s\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF!@#\$^\&\*\(\)\-_+=\[\]\{\};:'"<>,.?\/\\|`~]*$/;
        const testValue = validCharactersPattern.test(value);

        if (!testValue) {
            setErrors({ ...errors, project_name: "Invalid characters detected." });
            setIsCreateDisable(true);
            return;
        }

        try {
            const response = await newGet(
                `project/checkProjectName?project_name=${value}&site_id=${projectFormValue?.channel?.site_id}`
            );

            if (response && response.status_code === 200) {
                if (response.result[0]?.count_project_name > 0) {
                    setErrors({ ...errors, project_name: "Project name already exists !!!" });
                    setIsCreateDisable(true);
                } else {
                    setErrors({ ...errors, project_name: "Project name is valid" });
                    setIsCreateDisable(false);
                }
            }
        } catch (error) {
            console.error("Error checking project name:", error);
            setErrors({ ...errors, project_name: "An error occurred. Please try again." });
            setIsCreateDisable(true);
        }
    };





    // const checkProjectName = async (value) => {
    //     if (value.length < 3) {
    //         setErrors({ ...errors, project_name: "Enter at least 3 characters" });
    //         setIsCreateDisable(true); 
    //         return; 
    //     }

    //     const specialCharsPattern = /^[\w\s\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF]+$/;
    //     const testValue = specialCharsPattern.test(value);

    //     if (!testValue) {
    //         setErrors({ ...errors, project_name: "Special characters are not allowed !!!" });
    //         setIsCreateDisable(true);
    //         return;
    //     }

    //     try {
    //         const response = await newGet(
    //             `project/checkProjectName?project_name=${value}&site_id=${projectFormValue?.channel?.site_id}`
    //         );

    //         if (response && response.status_code === 200) {
    //             if (response.result[0]?.count_project_name > 0) {
    //                 setErrors({ ...errors, project_name: "Project name already exists !!!" });
    //                 setIsCreateDisable(true);
    //             } else {
    //                 setErrors({ ...errors, project_name: "Project name is valid" });
    //                 setIsCreateDisable(false);
    //             }
    //         }
    //     } catch (error) {
    //         console.error("Error checking project name:", error);
    //         setErrors({ ...errors, project_name: "An error occurred. Please try again." });
    //         setIsCreateDisable(true);
    //     }
    // };


    // const checkProjectName = async (value) => {
    //     if (value.length >= 1) {
    //         // const specialCharsPattern = /[^a-zA-Z0-9 _]/g; 
    //         const specialCharsPattern = /^[\w\s\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF]+$/;
    //         const testValue = specialCharsPattern.test(value)
    //         // const japanTestValue = japaneseCharsPattern.test(value) 
    //         if (testValue) {
    //             try {
    //                 await newGet(`project/checkProjectName?project_name=${value}&site_id=${projectFormValue?.channel?.site_id}`).then((data) => {
    //                     if (data && data.status_code == 200) {

    //                         if (data.result[0]?.count_project_name > 0) {
    //                             setErrors({ ...errors, project_name: "Project name already exist !!!" })
    //                             setIsCreateDisable(true);
    //                         } else {
    //                             setErrors({ ...errors, project_name: "Project name is valid" })
    //                             setIsCreateDisable(false);
    //                         }
    //                     }
    //                 });
    //             } catch (error) {
    //                 console.log("error:???????", error);
    //                 setLoading(false);
    //             }
    //         } else {
    //             setErrors({ ...errors, project_name: "special characters are not allowed !!!" })
    //             setIsCreateDisable(true);
    //         }
    //     } else {
    //         setErrors({ ...errors, project_name: "" });
    //         setIsCreateDisable(true);
    //     }

    // }
    const handleChange = (e) => {
        const { name, value } = e.target;

        // Clear the error for the current field
        setErrors({ ...errors, [name]: "" });

        if (name === "required_sheet") {
            // Allow only digits and no strings
            if (/^\d*$/.test(value.trim())) {
                setProjectFormValue({ ...projectFormValue, [name]: value.trim() });

                // Check after the user has entered a value (when it's a valid number)
                const numericValue = parseInt(value.trim(), 10);
                if (numericValue === 0) {
                    setErrors({ ...errors, [name]: "Value cannot be 0!" });
                }
                else if (numericValue > 50) {
                    setErrors({ ...errors, [name]: "Maximum sheet allowed is 50!" });
                }
            }
        } else {
            // Handle other input fields normally
            setProjectFormValue({ ...projectFormValue, [name]: value });
        }
    };


    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setErrors({ ...errors, [name]: "" });
    //     //  console.log("name", name, value.length)


    //     if (name === "required_sheet") {
    //         // Only allow digits (no strings)
    //         if (/^\d*$/.test(value.trim())) {
    //             // const numericValue = parseInt(value.trim(), 10);
    //             // Check if the value is within the allowed range
    //             if (value.trim() === "" || (numericValue >= 0 && numericValue <= 50)) {
    //                 setProjectFormValue({ ...projectFormValue, [name]: value.trim() });
    //             } else {
    //                 setErrors({ ...errors, [name]: "Maximum sheet allowed is 50!" });
    //             }
    //         }
    //         // If it's not a number, do nothing (no need to update the form value)
    //     } else {
    //         setProjectFormValue({ ...projectFormValue, [name]: value });
    //     }
    // };

    const isValidYear = (date) => {
        const currentYear = new Date().getFullYear();
        const year = date.getFullYear();
        return year >= currentYear && year <= 2026; // Adjust the range as needed
    };

    const handleDateChange = (date, name) => {
        // if (isValidYear(date)) {
        const formattedDate = date ? format(date, 'MM-dd-yyyy') : null;
        setProjectFormValue({ ...projectFormValue, [name]: formattedDate })
        setErrors({ ...errors, [name]: "" })
        // }
    };

    const handleChannelChange = (site) => {
        fetchChannelUsers(site);
        fetchClientUsers(site);
        setProjectFormValue({ ...projectFormValue, channel: site })
        setErrors({ ...errors, channel: "" })
    };

    const handlemultipleUsers = (e, options, fieldName) => {
        let newFormValue = { ...projectFormValue, [fieldName]: options };
        let newErrors = { ...errors, [fieldName]: "" };

        if (['assign_to', 'internal_approver', 'client_approver'].includes(fieldName)) {
            const combinedWatchList = [
                ...new Set([
                    ...newFormValue.assign_to,
                    ...newFormValue.internal_approver,
                    ...newFormValue.client_approver,
                ])
            ];

            const watchListUser = projectFormValue?.watch_list?.filter(user =>
                combinedWatchList?.some(combined => combined.user_id === user.user_id)
            );

            newFormValue = { ...newFormValue, watch_list: watchListUser };
        }

        setProjectFormValue(newFormValue);
        setErrors(newErrors);
    };


    // const handlemultipleUsers = (e, options, fieldName) => {
    //     let newFormValue = { ...projectFormValue, [fieldName]: options };
    //     let newErrors = { ...errors, [fieldName]: "" };

    //     if (['assign_to', 'internal_approver', 'client_approver'].includes(fieldName)) {
    //         const combinedWatchList = [
    //             ...new Set([
    //                 ...newFormValue.assign_to,
    //                 ...newFormValue.internal_approver,
    //                 ...newFormValue.client_approver,
    //             ])
    //         ];
    //         const watchListUser = projectFormValue?.watch_list?.filter(user =>
    //             !combinedWatchList?.some(combined => combined.user_id === user.user_id)
    //         );
    //         newFormValue = { ...newFormValue, watch_list: watchListUser };
    //     }
    //     // if (fieldName === 'watch_list') {
    //     //     const updatedFields = ['assign_to', 'internal_approver', 'client_approver'].reduce((acc, key) => {
    //     //         acc[key] = newFormValue[key].filter(item => options.includes(item));
    //     //         return acc;
    //     //     }, {});
    //     //     newFormValue = { ...newFormValue, ...updatedFields };
    //     // }
    //     setProjectFormValue(newFormValue);
    //     setErrors(newErrors);
    // };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const fetchUsers = async () => {
        // fetchChannelUsers();
        // fetchClientUsers();
        setIsAllUsers(false)
    }

    const fetchChannelUsers = async (site) => {
        // if (userList.length === 0) {
        try {
            // setLoading(true);
            const data = await newGet(`project/usersByRole?role_name=channel&site_id=${site?.site_id}`);
            if (data?.status_code === 200 || data?.status_code === 201) {
                setUserList(data?.result || []);
                setLoading(false);
                setIsAllUsers(true);
            }

        } catch (error) {
            console.log("error:???????", error);
            setLoading(false);
        }
        // }
    }

    const fetchClientUsers = async (site) => {
        // if (clientUserList.length === 0) {
        try {
            // setLoading(true);
            const data = await newGet(`project/usersByRole?role_name=client&site_id=${site?.site_id}`);
            if (data?.status_code === 200 || data?.status_code === 201) {
                setClientUserList(data?.result || []);
                setClientManager(data?.result?.filter((user) => user.role_name === "CLIENT_MANAGER"));
                setLoading(false);
                setIsAllUsers(true);
            }
        } catch (error) {
            console.log("error:???????", error);
            setLoading(false);
        }
        // }
    }

    const fetchProjects = async () => {
        try {
            setLoading(true);
            const data = await newGet(`project`);
            setProjectList(data?.result)
            // console.group(data?.result)
            setFilteredProject(data?.result?.filter((role) => role?.user_role !== "Observer"))
            setLoading(false);
            setIsAll(false);
        } catch (error) {
            console.log("error:???????", error);
            setLoading(false);
        }
    }

    const handleDelete = async (e, id) => {
        e.stopPropagation()
        const confirmation = await showConfirmationDialog(
            `${t("projectDeleteMsg")}`,
            "",
            "warning",
            `${t("no")}`,
            `${t("yes")}`
        );
        try {
            if (confirmation) {
                setLoading(true);
                const data = await newDelete(`project?project_id=${id}`);
                if (data.status_code == 200) {
                    showAlert(i18n.t(data?.message[0]?.property_message), 'success');
                    fetchProjects()
                    setLoading(false);
                }

            }
        } catch (error) {
            console.log("error:???????", error);
            setLoading(false);
        }

    }

    const makeSiteObject = (str) => {
        if (!str) {
            return null;
        }
        const jsonString = str?.replace(/(\w+):([^,}]+)/g, (match, key, value) => {
            // If the value is a number, keep it as is; otherwise, wrap it in quotes
            const formattedValue = isNaN(value) ? `"${value.trim()}"` : value.trim();
            return `"${key}":${formattedValue}`;
        });

        return JSON.parse(jsonString).site_id;
    }

    const modeChange = (value, roleName) => {
        setIsAll(value);
        if (roleName === 'Observer') {
            const filteredList = projectList?.filter((role) => role?.user_role !== roleName)
            setFilteredProject(filteredList)
        } else {
            setFilteredProject(projectList)
        }
    }

    const handleUpdateProject = (projectID, channel, sharedWithClient, userRole) => {

        if (userSettings?.permissions && userSettings?.permissions?.hasOwnProperty(18)) {
            if (userSettings?.role_id === 6) {
                if (sharedWithClient === 1) {
                    navigate(`/reference?project_id=${projectID}&site_id=${makeSiteObject(channel)}&user_role=${userRole}`, {
                        state: {
                            siteId: makeSiteObject(channel),
                            createdProjectId: projectID,
                            userRole: userRole
                        }
                    });
                } else {
                    showAlert("This Project is still in Process. You can see it after it is ready for the approval", "warning")
                }
            } else if (userSettings?.role_id !== 6) {
                navigate(`/reference?project_id=${projectID}&site_id=${makeSiteObject(channel)}&user_role=${userRole}`, {
                    state: {
                        siteId: makeSiteObject(channel),
                        createdProjectId: projectID,
                        userRole: userRole
                    }
                });
            }
        }
    }

    const formatDate = (date1) => {
        // let date = new Date(date1);
        // let day = String(date.getDate()).padStart(2, '0');
        // let month = String(date.getMonth() + 1).padStart(2, '0');
        // let year = date.getFullYear()
        // let formattedDate = `${month}-${day}-${year}`;
        // return formattedDate
        const [year, month, day] = date1.split("-");
        return `${month}-${day}-${year}`;
    }

    const handleSubmit = async () => {
        const requiredFields = [
            { key: 'project_name', message: t("projectNameRequired") },
            { key: 'project_description', message: t("projectDescriptionRequired") },
            { key: 'channel', message: t("channelRequired") },
            { key: 'required_sheet', message: t("noOfSheetRequired") },
            // { key: 'assign_to', message: t("assignToRequired"), isArray: true },
            // { key: 'project_type', message: 'Project type is required.'},
            { key: 'internal_approver', message: t("internalApproverRequired"), isArray: true },
            { key: 'client_approver', message: t("clientRequired"), isArray: true },
            { key: 'type_id', message: t("projectTypeRequired") },
        ];

        // "projectTypeRequired" : "Project type is required.",
        // "assignToRequired" : "Assign to name is required.",
        // "internalApproverRequired" : "Internal approver name is required.",
        // "clientRequired" : "Client approver name is required.",

        let formIsValid = true;
        const newErrors = {};

        // Validate required fields
        requiredFields.forEach((field) => {
            if (
                field.isArray
                    ? projectFormValue[field.key]?.length === 0
                    : !projectFormValue[field.key]
            ) {
                if (field.key === "client_approver") {
                    if (projectFormValue?.type_id === 2 && (!projectFormValue[field.key] || projectFormValue[field.key]?.length === 0)) {
                        newErrors[field.key] = field.message;
                        formIsValid = false;
                    }
                    // if(projectFormValue?.type_id === 2 && !projectFormValue[field.key]) {
                    //   newErrors[field.key] = field.message;
                    //   formIsValid = false;
                    // }
                } else if (field.key === "internal_approver") {
                    if (projectFormValue?.type_id === 1 && (!projectFormValue[field.key] || projectFormValue[field.key]?.length === 0)) {
                        newErrors[field.key] = field.message;
                        formIsValid = false;
                    }
                    // if(projectFormValue?.type_id === 1 && !projectFormValue[field.key]) {
                    //     newErrors[field.key] = field.message;
                    //     formIsValid = false;
                    //   }

                }
                else if (field.key === 'type_id' && !projectFormValue[field.key]) {
                    newErrors[field.key] = t("projectTypeRequired")
                    formIsValid = false;
                }

                else {
                    newErrors[field.key] = field.message;
                    formIsValid = false;
                }
            }
            else if (
                field.key === 'required_sheet' &&
                (!projectFormValue[field.key] || projectFormValue[field.key] === 0)
            ) {
                newErrors[field.key] = projectFormValue[field.key] === 0
                    ? "Required sheet cannot be zero."
                    : field.message;
                formIsValid = false;
            } else {
                if (field.key === "project_name") {
                    if (projectFormValue[field.key].length < 1) {
                        newErrors[field.key] = "It required at least 1 characters";
                        formIsValid = false;
                        return;
                    }

                }
                newErrors[field.key] = ""; // Clear errors if field is valid
            }
        });

        // Display validation errors
        setErrors(newErrors);

        if (!formIsValid) {
            return; // Stop submission if form is invalid
        }

        // Prepare form data for submission
        let approver_id = "";
        if (projectFormValue?.type_id === 1) {
            approver_id = projectFormValue?.internal_approver?.map(user => user?.account_login_id).join(',');
        } else if (projectFormValue?.type_id === 2) {
            approver_id = projectFormValue?.client_approver?.map(user => user?.account_login_id).join(',');
        }

        const formData = {
            project_name: projectFormValue?.project_name,
            project_desc: projectFormValue?.project_description,
            site_id: projectFormValue?.channel?.site_id,
            assign_to_user_id: projectFormValue?.assign_to?.map(user => user?.account_login_id).join(','),
            approver_id,
            watchlist: projectFormValue?.watch_list?.map(user => user?.account_login_id).join(','),
            required_sheet: parseInt(projectFormValue?.required_sheet, 10),
            type_id: projectFormValue?.type_id,
            project_type: 1
        };

        try {
            setLoading(true);
            const data = await newPost(`project/createProjectWithBoardDesign`, formData);
            setLoading(false);
            if (data.status_code === 1027) {
                // showAlert(i18n.t(data?.message[0]?.property_message), 'success');${t("projectDeleteMsg")}
                showAlert(`${t("PROJECT_CREATED_SUCCESSFULLY")}`, 'success');
                closeModal();

                saveSelectedSite(projectFormValue?.channel?.site_id, projectFormValue?.channel);
                navigate(`/reference?project_id=${data?.result?.project_id}&site_id=${projectFormValue?.channel?.site_id}&user_role=Creator`, {
                    state: {
                        siteId: projectFormValue?.channel?.site_id,
                        createdProjectId: data?.result?.project_id,
                        userRole: "Creator"
                    }
                });
                fetchProjects();
            }
        } catch (error) {
            console.error("Submission error:", error);
            setLoading(false);
        }
    };


    // const handleSubmit = async () => {
    //     const requiredFields = [
    //         { key: 'project_name', message: 'Project name is required.' },
    //         { key: 'project_description', message: 'Project description is required.' },
    //         { key: 'channel', message: 'Channel name is required.' },
    //         { key: 'required_sheet', message: 'No.of sheet is required.' },
    //         { key: 'assign_to', message: 'Assign to name is required.', isArray: true },
    //         { key: 'internal_approver', message: 'Internal approver name is required.', isArray: true },
    //         // { key: 'client_approver', message: 'Client approver name is required.', isArray: true },
    //         // { key: 'watch_list', message: 'Watch list name is required.', isArray: true },
    //         // { key: 'start_date', message: 'start date is required.' },
    //         // { key: 'due_date', message: 'Due date is required.' },
    //         { key: 'required_sheet', message: 'No. of sheet is required.' },
    //         { key: 'type_id', message: 'Project type is required.' },
    //     ];

    //     let formIsValid = true;
    //     const newErrors = { ...errors };

    //     requiredFields.forEach((field) => {
    //         if (
    //             field.isArray
    //                 ? projectFormValue[field.key].length === 0
    //                 : !projectFormValue[field.key]
    //         ) {
    //             newErrors[field.key] = field.message;
    //             formIsValid = false;
    //         } else if (field.key === 'required_sheet' && (!projectFormValue[field.key] || projectFormValue[field.key] == 0)) {
    //             newErrors[field.key] = projectFormValue[field.key] == 0 ? "Required sheet can not be zero" : field.message;
    //             formIsValid = false;
    //         } else {
    //             newErrors[field.key] = "";
    //         }
    //     });
    //     console.log("formIsValid", formIsValid)
    //     // if (!formIsValid) {
    //     //     setErrors(newErrors);
    //     //     return;
    //     // }

    //     let approver_id = "";
    //     if (projectFormValue?.type_id === 1) {
    //         approver_id = projectFormValue?.internal_approver?.map(user => user?.account_login_id).join(',');
    //     }
    //     else if (projectFormValue?.type_id === 2) {
    //         approver_id = projectFormValue?.client_approver?.map(user => user?.account_login_id).join(',');
    //     }
    //     const formData = {
    //         project_name: projectFormValue?.project_name,
    //         project_desc: projectFormValue?.project_description,
    //         site_id: projectFormValue?.channel?.site_id,
    //         assign_to_user_id: projectFormValue?.assign_to?.map(user => user?.account_login_id).join(','),
    //         // internal_approver_user_id: projectFormValue?.internal_approver?.map(user => user?.account_login_id).join(','),
    //         // client_approver_user_id: projectFormValue?.client_approver?.map(user => user?.account_login_id).join(','),
    //         approver_id: approver_id,
    //         watchlist: projectFormValue?.watch_list?.map(user => user?.account_login_id).join(','),
    //         // start_date: projectFormValue?.start_date,
    //         // due_date: projectFormValue?.due_date,
    //         required_sheet: parseInt(projectFormValue?.required_sheet),
    //         type_id: projectFormValue?.type_id,
    //         project_type: 1

    //     }
    //     console.log("formData", formData)
    //     try {
    //         setLoading(true);
    //         const data = await newPost(`project/createProjectWithBoardDesign`, formData);
    //         setLoading(false);
    //         console.log("Project data", data)
    //         if (data.status_code == 1027) {
    //             //    setBoardId(data?.result?.board_id);
    //             //    setSheetId(data?.result?.design_id?.design_id);
    //             // try {
    //             //     await newPost('board/updateInsertBoard', {
    //             //         project_id: data?.result,
    //             //         board_json: "",
    //             //         miro_link: ""
    //             //     });
    //             // } catch (error) {
    //             //     console.error('Error upserting annotation data:', error);
    //             // }

    //             // Creating a new design sheet for Vision Studio
    //             // try {
    //             //     await newPost('design/create', {
    //             //         project_id: data?.result,
    //             //         site_id: projectFormValue?.channel?.site_id,
    //             //         template_id: "",
    //             //         design_name: projectFormValue?.project_name,
    //             //         is_auto_saved: 'true'
    //             //     })

    //             // }
    //             // catch (error) {
    //             //     console.error('Error creating a new design sheet:', error);
    //             // }
    //             // showAlert("Project created successfully", "success")
    //             showAlert(i18n.t(data?.message[0]?.property_message), 'success');
    //             closeModal();
    //             navigate('/reference', {
    //                 state: {
    //                     siteId: projectFormValue?.channel?.site_id,
    //                     createdProjectId: data?.result?.project_id,
    //                 }
    //             });

    //             fetchProjects()
    //         }


    //     } catch (error) {
    //         console.log("error:", error);
    //         setLoading(false);
    //     }
    // }

    const closeModal = () => {
        const modalElement = document.getElementById('exampleModal');
        const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
        modalInstance.hide();
    };

    // const handleClearForm = () => {
    //     const modalElement = document.getElementById('exampleModal');
    //     modalElement.addEventListener('hidden.bs.modal', handleModalClose);
    // }

    const handleClearForm = () => {
        setProjectFormValue({
            project_name: '',
            project_description: '',
            channel: '',
            required_sheet: "",
            assign_to: [],
            internal_approver: [],
            client_approver: [],
            // watchlist: false,
            watch_list: [],
            // start_date: '',
            // due_date: '',
            type_id: '',
            // project_type : ""
        })
        setErrors({
            project_name: '',
            project_description: '',
            channel: '',
            required_sheet: "",
            assign_to: "",
            internal_approver: "",
            client_approver: "",
            watch_list: "",
            // start_date: '',
            // due_date: '',
            type_id: '',
            // project_type : ""
        })
        setIsCreateDisable(false)
        closeModal();
    };

    const handleSearchImage = () => {
        // navigate('/search', {
        //     state: {

        //     }
        // })
        // setSearchView(true);

    }

    const handleFormattedData = useCallback((data) => {
        setFormattedData((prevData) => {
            if (JSON.stringify(prevData) !== JSON.stringify(data)) {
                return data;
            }
            return prevData;
        });
    }, []);

    useEffect(() => {
        const modalElement = document.getElementById('exampleModal');
        const handleModalHidden = () => {
            handleClearForm();
        };
        modalElement?.addEventListener('hidden.bs.modal', handleModalHidden);
        return () => {
            modalElement?.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, []);

    useEffect(() => {
        const modalElement = document.getElementById('exampleModal');
        let modalInstance = null;

        if (modalElement) {
            modalInstance = new window.bootstrap.Modal(modalElement);
        }

        const handleModalClose = () => {
            if (modalInstance && modalInstance._isShown) {
                modalInstance.hide();
            }
        };

        handleModalClose();

        return () => {
            handleModalClose();
        };
    }, [location]);

    useEffect(() => {
        fetchProjects()
        setFormValue({
            input_text: "",
            page: 1,
            ctr: 0,
            tags: [],
            model_filters: [],
            is_edited: "",
            search_by: "both",
        });
        // setSearchViewState(false)
        setShowResults(false);
    }, [])

    const handleSearchInput = (e) => {
        setSearchInput(e.target.value)
    }

    const handleChannelNameUpdate = (name) => {
        setChannelName(name);
    };

    const handleFocus = () => {
        setSearchViewState(true); // Show component on focus
    };

    const handleSearchIcon = () => {
        setSearchIcon(true)
    }

    return (
        <div>
            {!initialLoading && (
                <Header
                    fetchProjects={fetchProjects}
                    setIsAll={setIsAll}
                    screen="manager"
                    onChannelNameUpdate={handleChannelNameUpdate}
                // setSearchView={setSearchView}
                />
            )
            }
            {!initialLoading && !isUserSettingsLoading && !searchView && (
                <div style={{ backgroundColor: "#E6E6E6" }}>
                    <div className='d-flex justify-content-center'>
                        <div className='container'>
                            <div className='d-flex justify-content-center flex-column align-items-center py-5'>
                                <h4 className='font-noto-sans mt-4 text-center'>{t("welcomeTitle", { username: userSettings?.username})}!</h4>
                                <h4 className='font-noto-sans text-center'>{t("welcomeManagerDashboardTitle2")}</h4>
                                {/* {!searchView && */}
                                <div className='position-relative search_width mt-4 mb-sm-5 mb-4'>
                                    <input className="form-control me-2 rounded-pill border-0 ps-4"
                                        placeholder={t("searchPastVision")}
                                        aria-label="Search"
                                        onFocus={handleFocus}
                                        // ref={searchRef}
                                        onChange={(e) => handleSearchInput(e)}
                                        style={{
                                            textAlign: "center"
                                        }}
                                    />
                                    {/* <button> */}
                                    {/* <img src={Search1} alt='search' onClick={handleSearchImage} */}
                                    <img src={Search1} alt='search' className='cursor-pointer' onClick={handleSearchIcon}
                                    />
                                    {/* {showComponent && navigate("/search")} */}
                                    {/* </button> */}
                                </div>
                                {/* } */}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {(!searchView && !initialLoading && !isUserSettingsLoading) ? (
                <div className='px-sm-5 px-3'>
                    <div className='d-flex w-100 justify-content-between align-items-center flex-wrap my-sm-4 my-3 gap-2'>
                        <div className="d-flex align-items-center justify-content-center gap-2">
                            <div className="d-flex align-items-center gap-1 rounded-pill p-1 border-black" style={{ boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)", }}>
                                <button
                                    style={{ width: "fit-content", transition: "all 0.3s ease" }}
                                    className={`rounded-pill py-1 border-0 font-noto-sans fw-bold fs-14 ${isAll ? "bg-color-black text-white" : "bg-transparent text-black"} `}
                                    onClick={() => modeChange(true)}
                                    type="button"
                                >
                                    {t("allProjects")}
                                </button>
                                <button
                                    style={{ width: "fit-content", transition: "all 0.3s ease" }}
                                    className={`rounded-pill py-1 border-0 font-noto-sans fw-bold fs-14 ${!isAll ? "bg-color-black text-white" : "bg-transparent text-black"} `}
                                    onClick={() => modeChange(false, "Observer")}
                                    type="button"
                                >
                                    {t("myProjects")}
                                </button>

                            </div>
                            <div className='font-noto-sans fs-14 fw-bold'>
                                {t('NoOfProjects')} : <span className='px-1 border rounded'>{formattedData?.length}</span>
                            </div>
                        </div>
                        {userSettings?.permissions && hasPermission(8) &&
                            <div className='d-flex justify-content-end '>
                                <button
                                    type="button"
                                    onClick={() => fetchUsers()}
                                    className="btn bg-dark text-end my-sm-1 fs-16 rounded-pill py-sm-2 py-1 cursor_pointer"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal">
                                    {t("createNewProject")}
                                </button>
                            </div>
                        }
                    </div>
                    <ProjectTable
                        projectList={filteredProject}
                        handleDelete={handleDelete}
                        loading={loading}
                        handleUpdateProject={handleUpdateProject}
                        userSettings={userSettings}
                        channelname={channelname}
                        onFormattedDataChange={handleFormattedData}
                    />
                </div>
            ) : (
                <>
                    {!searchView && isUserSettingsLoading &&
                        <div>
                            <div style={{ backgroundColor: "#E6E6E6" }}>
                                <div className='d-flex justify-content-center'>
                                    <div className='container'>
                                        <div className='d-flex justify-content-center flex-column align-items-center py-5'>
                                            <div className='w-50'>
                                                <ProjectLoader height={50} />
                                            </div>
                                            <div className='w-75'>
                                                <ProjectLoader height={50} />
                                            </div>
                                            <div className='w-100 py-3'>
                                                <ProjectLoader height={70} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='px-sm-5 px-3'>

                                <table className="table table-responsive table-hover">
                                    <thead className='sticky_top'>
                                        <tr>
                                            <th className="text-center align-middle border-0">
                                                <ProjectLoader />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.from({ length: 6 }).map((_, i) => (
                                            <tr key={i} className='border-bottom'>
                                                <td className='text-center align-middle border-0'>
                                                    <div className='w-75'>
                                                        <ProjectLoader />
                                                    </div>
                                                    <ProjectLoader />
                                                </td>
                                                {Array.from({ length: 9 }).map((_, index) => (
                                                    <td key={index} className="text-center align-middle border-0">
                                                        <ProjectLoader />
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </>
            )
            }
            {/* {projectList && projectList?.length > 0 &&
                <div className="px-5 mt-4 d-flex justify-content-end">
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </div>
            } */}

            {/* seachView DOM */}

            {searchView &&
                <>
                    <Search
                        // searchView={searchView}
                        // setSearchView={setSearchView}
                        setIsWelcomeScreen={setIsWelcomeScreen}
                        isWelcomeScreen={isWelcomeScreen}
                        searchInput={searchInput}
                        handleSearchImage={handleSearchImage}
                        searchIcon={searchIcon}
                    />
                </>
            }


            {/* create new project modal */}
            {!initialLoading && (
                <div className="modal fade font-noto-sans" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        {/* <div className='w-100 p-3 font-noto-sans rounded swich-popup'>
                            <div className='swich-popup-curve'>
                                <h6 className=''>Project Stages</h6>
                                <div className='d-flex align-items-center gap-3 mt-3'>
                                    <div style={{ borderTop: "3px solid black" }} className='w-100 pt-2 text-start'>
                                        <h6 className='fs-14'>Project Stages</h6>
                                    </div>
                                    <div style={{ borderTop: "3px solid black" }} className='w-100 pt-2 text-start'>
                                        <h6 className='fs-14'>Project Stages</h6>
                                    </div>
                                    <div style={{ borderTop: "3px solid black" }} className='w-100 pt-2 text-start'>
                                        <h6 className='fs-14'>Project Stages</h6>
                                    </div>
                                </div>

                            </div>
                        </div> */}


                        <div className="modal-content" style={{ overflow: "inherit" }}>
                            <div className="modal-header border-0 pb-0">
                                <h5 className="modal-title fw-bold" id="exampleModalLabel">{t("createNewProject")}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClearForm}></button>
                            </div>

                            <CreateProjectForm
                                handleChange={handleChange}
                                handlemultipleUsers={handlemultipleUsers}
                                projectFormValue={projectFormValue}
                                setProjectFormValue={setProjectFormValue}
                                errors={errors}
                                userList={userList}
                                allUsers={allUsers}
                                clientManager={clientManager}
                                clientUserList={clientUserList}
                                handleChannelChange={handleChannelChange}
                                loading={(loading && initialLoading)}
                                handleDateChange={handleDateChange}
                                setInputProjectName={setInputProjectName}
                                checked={checked}
                                setChecked={setChecked}
                                isAllUsers={isAllUsers}
                                screen="manager"
                            />

                            {loading ? (
                                <div className="modal-footer border-0">
                                    <p type="button" className="cancel_modal_footer" onClick={handleClearForm}>{t("cancel")}</p>
                                    {/* <CreateProjectFormLoader height={60} width={100} rounded={"rounded-25"} /> */}
                                    <CreateProjectFormLoader height={60} width={100} rounded={"rounded-25"} />
                                </div>
                            ) : (
                                <div className="modal-footer border-0">
                                    <p type="button" className="cancel_modal_footer" onClick={handleClearForm}>{t("cancel")}</p>
                                    <button type="button" className="send_modal_footer" onClick={handleSubmit} disabled={isCreateDisable}>{t("create")}</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

            )}

        </div>

    );
}

export default Manager;