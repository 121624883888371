import React, { useEffect, useRef, useState } from 'react';
import { Stage, Layer, Group, Rect, Text, Image, Circle } from 'react-konva';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DraggableImage from './DraggableImage';
import { useKonvaContext } from '../../context/useKonvaContext';
import { addSheet, updateSheetPosition, updateSheetStatus, updateSheetName, initializeCanvas, removeSheet, updateSheetLock } from '../../store/konvaSlice';
import threeDots from '../../assets/newicons/three-dots.svg';
import lockOpen from "../../assets/newicons/lock_open.svg";
import lockClose from "../../assets/newicons/lock_close.svg";
import aproveSvg from "../../assets/newicons/approve-svg.svg";
import returnSvg from "../../assets/newicons/return-svg.svg";
import './Konva.css';
import verified from "../../assets/newicons/verified.svg";
import { Menu, MenuItem, Divider, styled, Select, alpha } from '@mui/material';
import replace from "../../assets/newicons/replace.svg";
import download from "../../assets/newicons/download1.svg";
import remove from "../../assets/newicons/delete1.svg";
//css
import './Konva.css'
import { useReferenceContext } from '../../context/useReferenceContext';
import { newPut } from '../../API/axios';
import { newPost } from '../../API/axios';
import { newGet } from '../../API/axios';
import { Html } from 'react-konva-utils';
import { showConfirmationDialog, showAlert } from "../../views/Manager/component/AlertService";
import { useAuthContext } from '../../context/useAuthContext';
import { useNavigate } from 'react-router-dom';



const KonvaCanvas = ({ selectedImages, setSelectedImages, scale, templateData, setScale, canvasData, removeSheet, modalRef, setCurrentSheetId, lockNRequestApproval, cancelRequestApproval }) => {
    const { t } = useTranslation();
    const { stageRef, containerRef, setSelectedSheetLayerId, setSelectedSheetId, selectedSheetId, selectedSheetLayerId } = useKonvaContext();
    const dispatch = useDispatch();
    const canvas = useSelector(state => state.konva.canvas);
    const { designId, projectDetails, setProjectDetails } = useReferenceContext();
    const { setSheetCount } = useKonvaContext();
    const { userSettings, selectedSite } = useAuthContext();
    const [lockLoadingStates, setLockLoadingStates] = useState({})
    const [addingNewSheetLoading, setAddingNewSheetLoading] = useState(false);
    const navigate = useNavigate();

    // Get the selected sheet from canvas instead of canvasData
    const selectedSheet = canvas?.layers?.[0]?.groups?.find(group => group.id === selectedSheetId);

    // Destructure from selectedSheet instead of canvasData
    const {
        name: sheet_name = '',
        id: sheet_id = null,
        is_locked: sheet_is_locked = false,
        status_code: sheet_status_code = null,
        status_id: sheet_status_id = 1
    } = selectedSheet || {};

    const [stageSize, setStageSize] = useState({
        width: window.innerWidth,
        height: (window.innerHeight - 44)
    });

    const [stagePosition, setStagePosition] = useState({ x: 0, y: 0 });
    const [menuTargetSheet, setMenuTargetSheet] = useState(null);
    const [pages, setPages] = useState([]);
    const [lockIcon, setLockIcon] = useState(null);
    const [menuIcon, setMenuIcon] = useState(null);
    const [isLock, setisLock] = useState(sheet_is_locked);
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuPosition, setMenuPosition] = useState(false);
    const [editingText, setEditingText] = useState(null);
    const [designStatus, setDesignStatus] = useState(1);
    const [sheetStatus] = useState([

        { id: 2, name: 'Awaiting Approval', label: 'Awaiting Approval' },
        { id: 3, name: 'Approved', label: 'Approve', imgSrc: aproveSvg },
        { id: 4, name: 'Returned', label: 'Return', imgSrc: returnSvg }
    ]);

    // Add a state to track if menu is open
    const isMenuOpen = Boolean(menuPosition);

    // Update the useEffect to handle undefined canvasData
    useEffect(() => {
        if (canvasData && Array.isArray(canvasData)) {
            setPages(canvasData.map((canvas, index) => ({
                id: canvas.sheet_id,
                x: 50 + (index * 600),
                y: 50
            })));
        }
    }, [canvasData]);

    // Update the useEffect for isLock
    useEffect(() => {
        if (selectedSheet?.status_id === 2 || selectedSheet?.status_id === 3) {
            setisLock(true);
        } else {
            setisLock(false);
        }
    }, [selectedSheet]);

    // Check if project is active based on status
    const isProjectActive = () => {
        if (!projectDetails?.status) return true; // Default to active if no status

        const statusId = parseInt(Object.keys(projectDetails.status)[0]);

        // Status 5 means completed/cancelled project, status 3 means inactive
        return statusId !== 5 && statusId !== 3;
    };


    // Get project details if not available
    const getProjectDetails = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const projectId = urlParams.get("project_id");
        const siteId = urlParams.get("site_id");

        if (projectId && siteId) {

            try {
                const response = await newGet(
                    `project/getProjectDetails?project_id=${parseInt(projectId)}&site_id=${parseInt(siteId)}`
                );

                if (response?.message?.[0]?.property_message === "PROJECT_NOT_FOUND") {
                    navigate("/dashboard")
                    return;
                }

                setProjectDetails(response?.result[0]);

            } catch (error) {
                console.error("Error fetching project details:", error);
                navigate("/dashboard");
            }
        }
    };

    useEffect(() => {
        if (!projectDetails) {
            getProjectDetails();
        }
    }, [selectedSite]);
    // Handle page drag
    const handlePageDragStart = (e) => {
        e.target.setAttrs({
            shadowOffset: {
                x: 5,
                y: 5
            },
            shadowBlur: 6,
            shadowOpacity: 0.3
        });
    };

    const handlePageDragEnd = (e, pageId) => {
        const newPos = e.target.position();
        setPages(prevPages =>
            prevPages.map(page =>
                page.id === pageId
                    ? { ...page, x: newPos.x, y: newPos.y }
                    : page
            )
        );

        e.target.setAttrs({
            shadowOffset: {
                x: 0,
                y: 0
            },
            shadowBlur: 0,
            shadowOpacity: 0
        });
    };

    // Add missing handler functions
    const handleDragStart = (e, layerId, elementId) => {
        // Handle drag start
        e.target.setAttrs({
            shadowOffset: {
                x: 15,
                y: 15
            },
            scaleX: 1.1,
            scaleY: 1.1
        });
    };

    const handleDragEnd = (e, layerId, elementId) => {
        // Handle drag end
        e.target.to({
            duration: 0.1,
            scaleX: 1,
            scaleY: 1,
            shadowOffsetX: 0,
            shadowOffsetY: 0
        });
    };


    const handleLockNRequestApproval = async () => {
        try {
            // Check if we have a valid selectedSheetId and sheet is not already locked
            const selectedSheet = canvas?.layers?.[0]?.groups?.find(group => group.id === selectedSheetId);

            if (!selectedSheetId || !selectedSheet) {
                console.error("No sheet selected");
                return;
            }

            // Don't proceed if sheet is already locked or approved
            if (selectedSheet.status_id === 2 || selectedSheet.status_id === 3) {
                return;
            }

            const response = await newPut('sheet/lockUnlock', {
                sheet_id: selectedSheetId,
                is_locked: true
            });

            if (response?.status_code === 200) {
                // Update Redux state
                dispatch(updateSheetStatus({
                    sheetId: selectedSheetId,
                    status: 2 , // Status 2 for locked/awaiting approval,
                    is_locked: true
                }));
                setisLock(true);

                // Refresh the design details to get updated status
                await getDesignDetail();
            }
        } catch (error) {
            console.error("Error in handleLockNRequestApproval:", error);
        }
    };


    const handleCancelRequestApproval = async () => {
        try {
            const selectedSheet = canvas?.layers?.[0]?.groups?.find(group => group.id === selectedSheetId);

            if (!selectedSheetId || !selectedSheet) {
                console.error("No sheet selected");
                return;
            }

            // Only proceed if sheet is locked (status 2)
            if (selectedSheet.status_id !== 2) {
                return;
            }

            const response = await newPut('sheet/lockUnlock', {
                sheet_id: selectedSheetId,
                is_locked: false
            });

            if (response?.status_code === 200) {
                // Update Redux state
                dispatch(updateSheetStatus({
                    sheetId: selectedSheetId,
                    status: 1  // Status 1 for draft/unlocked
                }));
                setisLock(false);

                // Refresh the design details to get updated status
                await getDesignDetail();
            }
        } catch (error) {
            console.error("Error in handleCancelRequestApproval:", error);
        }
    };

    const handleGroupClick = (layerId, elementId) => {
        // Handle group click
        setCurrentSheetId(layerId);
    };

    const handleTransformEnd = (e, layerId, elementId) => {
        // Handle transform end
        const node = e.target;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();

        // Reset scale and adjust width/height instead
        node.scaleX(1);
        node.scaleY(1);
        node.width(Math.max(5, node.width() * scaleX));
        node.height(Math.max(5, node.height() * scaleY));
    };

    const addNewSheet = async () => {
        if (addingNewSheetLoading) return
        setAddingNewSheetLoading(true)
        try {
            const sheetCount = canvas?.layers?.[0]?.groups?.length || 0;
            const newSheetName = `Sheet${sheetCount + 1}`;

            const response = await newPost('sheet/create', {
                design_id: designId,
                sheet_name: newSheetName,
                sheet_status_id: 1,
                sheet_is_locked: false
            });

            if (response?.status_code === 200) {
                const newSheet = {
                    sheet_id: response.result.sheet_id,
                    sheet_name: newSheetName,
                    sheet_status_id: 1,
                    sheet_is_locked: false,
                    layers: [{
                        id: response.result.sheet_id,
                        elements: []
                    }]
                };

                dispatch(addSheet(newSheet));

                setSelectedSheetId(response.result.sheet_id);
                setSelectedSheetLayerId(response.result.sheet_id);
                setSheetCount(prev => prev + 1);

                // Call getDesignDetail API after adding new sheet
                await getDesignDetail();
            }
            setTimeout(() => {
                setAddingNewSheetLoading(false)
            }, 500)
        } catch (error) {
            console.error("Error creating new sheet:", error);
            setTimeout(() => {
                setAddingNewSheetLoading(false)
            }, 500)
        }
    };

    // Add getDesignDetail function
    const getDesignDetail = async () => {
        try {
            const response = await newGet(`design/getDesignDetail?design_id=${designId}`);
            if (response?.status_code === 200) {
                dispatch(initializeCanvas(response.result));
            }
        } catch (error) {
            console.error("Error fetching design details:", error);
        }
    };

    // Handle stage resize
    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                setStageSize({
                    width: containerRef.current.offsetWidth,
                    height: containerRef.current.offsetHeight
                });
            }
        };
        window.addEventListener('resize', updateDimensions);
        updateDimensions();
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // Handle zoom
    const handleWheel = (e) => {
        e.evt.preventDefault();

        const stage = stageRef.current;
        const oldScale = scale;
        const pointer = stage.getPointerPosition();

        const mousePointTo = {
            x: (pointer.x - stage.x()) / oldScale,
            y: (pointer.y - stage.y()) / oldScale,
        };

        const newScale = e.evt.deltaY > 0 ? oldScale * 0.9 : oldScale * 1.1;
        const boundedScale = Math.min(Math.max(newScale, 0.1), 3);

        setScale(boundedScale);
        setStagePosition({
            x: pointer.x - mousePointTo.x * boundedScale,
            y: pointer.y - mousePointTo.y * boundedScale,
        });
    };

    const lockAndUnlockSheet = async (sheet, canvasIdx) => {
        try {

            if (!isProjectActive()) {
                return;
            }

            if (lockLoadingStates[canvasIdx]) return
            setLockLoadingStates(prev => ({ ...prev, [canvasIdx]: true }))
            if (!sheet?.elements?.[0]?.image_data?.data) {
                setLockLoadingStates(prev => ({ ...prev, [canvasIdx]: false }))
                return;
            }

            // Check if sheet is awaiting approval (status 2)
            if (sheet.status_id === 2) {
                showAlert("Cannot unlock sheet while awaiting approval", "warning");
                setLockLoadingStates(prev => ({ ...prev, [canvasIdx]: false }))
                return;
            }


            // Check if we have a valid selectedSheetId and sheet
            const selectedSheet = canvas?.layers?.[0]?.groups?.find(group => group.id === sheet.id);

            // if (!selectedSheetId || !selectedSheet) {
            //     console.error("No sheet selected");
            //     return;
            // }

            // Don't proceed if sheet is approved (status 3)
            if (selectedSheet.status_id === 3) {
                setLockLoadingStates(prev => ({ ...prev, [canvasIdx]: false }))
                return;
            }


            // Determine the new lock state based on current is_locked value
            const newLockState = !selectedSheet.is_locked;

            const response = await newPut('sheet/lockUnlock', {
                sheet_id: sheet.id,
                is_locked: newLockState
            });

            if (response?.status_code === 200) {
                // Update Redux state with is_locked instead of status
                dispatch(updateSheetLock({
                    sheetId: sheet.id,
                    is_locked: newLockState
                }));
                setisLock(newLockState);

                // Refresh the design details to get updated status
                // await getDesignDetail();
            }
            setTimeout(() => {
                setLockLoadingStates(prev => ({ ...prev, [canvasIdx]: false }))
            }, 2000)
        } catch (error) {
            console.error("Error in lockAndUnlockSheet:", error);
            setTimeout(() => {
                setLockLoadingStates(prev => ({ ...prev, [canvasIdx]: false }))
            }, 2000)
        }
    };

    const StyledMenu = styled((props) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            {...props}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            position: 'fixed',
            marginTop: 0,
            minWidth: 250,
            color: 'rgb(55, 65, 81)',
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                fontWeight: 400,
                fontSize: 14,
                '& .MuiSvgIcon-root': {

                    color: theme.palette.text.secondary,
                    marginRight: theme.spacing(0.5),
                },
                '&:active': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        theme.palette.action.selectedOpacity,
                    ),
                },
            },
            ...theme.applyStyles('dark', {
                color: theme.palette.grey[300],
            }),
        },
    }));


    const handleMenuClick = (e, sheet) => {
        e?.preventDefault();
        e?.stopPropagation();
        e.cancelBubble = true;

        const stage = stageRef.current;
        const stageBox = stage.container().getBoundingClientRect();
        const pointerPos = stage.getPointerPosition();

        const absolutePos = {
            x: stageBox.left + pointerPos.x,
            y: stageBox.top + pointerPos.y
        };

        setMenuPosition(absolutePos);
        setAnchorEl(e.currentTarget);
        setMenuTargetSheet(sheet);

        setSelectedSheetId(sheet.id);
        setSelectedSheetLayerId(sheet?.elements?.[0]?.id);
    };

    const handleMenuClose = () => {
        setMenuPosition(null);
        setAnchorEl(null);
        setMenuTargetSheet(null);
    };

    const handleDownload = () => {
        // Implement download logic
        handleMenuClose();
    };

    const handleReplace = () => {
        // Implement replace logic
        handleMenuClose();
    };

    const handleDelete = () => {
        if (menuTargetSheet) {
            removeSheet(menuTargetSheet.sheet_id);
            handleMenuClose();
        }
    };

    // Add this function to handle sheet deletion
    const handleDeleteSheet = async (sheetId) => {
        try {
            // Show confirmation dialog first
            const confirmation = await showConfirmationDialog(
                t("deleteSheetConfirmation"),
                t("deleteSheetWarning"),
                "warning",
                t("cancel"),
                t("delete")
            );

            // Only proceed if user confirms
            if (confirmation) {
                const response = await newPut('sheet/delete', {
                    sheet_id: sheetId
                });

                if (response?.status_code === 200) {
                    // Create a plain object action
                    const action = {
                        type: 'konva/removeSheet',
                        payload: sheetId
                    };

                    // Dispatch the plain object action
                    dispatch(action);

                    // Close menu and update canvas
                    handleMenuClose();
                    setSheetCount(prev => prev - 1);
                    await getDesignDetail();
                }
            }
        } catch (error) {
            console.error("Error deleting sheet:", error);
            showAlert(t("sheetDeleteError"), "error");
        }
    };

    // Update the useEffect for loading images
    useEffect(() => {
        // Load menu icon
        const menuIconImg = new window.Image();
        menuIconImg.src = threeDots;
        menuIconImg.onload = () => {
            setMenuIcon(menuIconImg);
        };

        // Load lock icons
        const lockOpenImg = new window.Image();
        const lockCloseImg = new window.Image();

        lockOpenImg.src = lockOpen;
        lockCloseImg.src = lockClose;

        lockOpenImg.onload = () => {
            setLockIcon(prev => ({ ...prev, open: lockOpenImg }));
        };

        lockCloseImg.onload = () => {
            setLockIcon(prev => ({ ...prev, close: lockCloseImg }));
        };
    }, []);

    const getStatusInfo = (statusId) => {
        switch (statusId) {
            case 1:
                return { text: "statusDraft", color: "#E6E6E6", textColor: "#000", width: '100' };
            case 2:
                return { text: "statusAwaitingApproval", color: "#F1D2B0", textColor: "#000", width: '165' };
            case 3:
                return { text: "statusApproved", color: "#B2DEC8", textColor: "#000", width: '120' };
            case 4:
                return { text: "statusReturned", color: "#F6B8B0", textColor: "#000", width: '120' };
            case 5:
                return { text: "statusDeleted", color: "red", textColor: "#000", width: '120' };
            default:
                return { text: "statusDraft", color: "#E6E6E6", textColor: "#000", width: '100' };
        }
    };

    const renderSheetHeader = (canvas, index) => {
        const isLocked = canvas.sheet_status_id === 2;
        const isDraft = canvas.sheet_status_id === 1;
        const isAwaitingApproval = canvas.sheet_status_id === 2;

        return (
            <Group y={-30}>
                {/* Header Background */}
                <Rect
                    width={500}
                    height={30}
                    fill="#f0f0f0"
                    cornerRadius={[5, 5, 0, 0]}
                />

                {/* Sheet Name */}
                <Text
                    text={`Sheet ${index + 1}`}
                    x={10}
                    y={8}
                    fontSize={14}
                    width={150}
                />

                {/* Status Tag */}
                <Group x={170} y={3}>
                    <Rect
                        width={100}
                        height={24}
                        fill={getStatusInfo(canvas.status_id).color}
                        cornerRadius={12}
                    />
                    <Text
                        text={t(getStatusInfo(canvas.status_id).text)}
                        x={10}
                        y={5}
                        fontSize={12}
                        fill={getStatusInfo(canvas.status_id).textColor}
                        width={80}
                        align="center"
                    />
                </Group>

                {/* Lock Icon */}
                {lockIcon && (
                    <Image
                        image={lockIcon}
                        x={400}
                        y={5}
                        width={20}
                        height={20}
                    />
                )}

                {/* Menu Icon */}
                {menuIcon && (
                    <Group
                        x={440}
                        y={5}
                        onClick={(e) => handleMenuClick(e, canvas)}
                        cursor="pointer"
                    >
                        <Image
                            image={menuIcon}
                            width={20}
                            height={20}
                        />
                    </Group>
                )}
            </Group>
        );
    };

    const renderAddButton = () => (
        <Group
            x={520}
            y={250}
            onClick={addNewSheet}
            cursor="pointer"
        >
            <Circle
                radius={15}
                fill="#000"
                shadowColor="rgba(0,0,0,0.2)"
                shadowBlur={5}
                shadowOffset={{ x: 0, y: 2 }}
            />
            <Text
                text="+"
                x={-5}
                y={-8}
                fontSize={20}
                fill="white"
                fontStyle="bold"
            />
        </Group>
    );

    // Add these handlers for sheet name editing
    const handleDoubleClickSheetName = (sheet, e) => {
        const textNode = e.target;
        const stage = stageRef.current;

        setEditingText({
            id: sheet.id,
            text: sheet.name || "Untitled",
            textNode: textNode
        });
    };

    const handleTextChange = (e) => {
        setEditingText(prev => ({
            ...prev,
            text: e.target.value
        }));
    };

    const handleTextBlur = async () => {
        if (editingText) {
            try {
                const response = await newPut('sheet/rename', {
                    sheet_id: editingText.id,
                    new_sheet_name: editingText.text || "Untitled"
                });

                if (response.status_code === 200) {
                    dispatch(updateSheetName({
                        sheetId: editingText.id,
                        name: editingText.text
                    }));
                }
            } catch (error) {
                console.error("Error updating sheet name:", error);
            }
            setEditingText(null);
        }
    };

    const handleTextKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            handleTextBlur();
        }
        if (e.key === 'Escape') {
            setEditingText(null);
        }
    };

    const handleContextMenu = (e) => {
        e.evt.preventDefault();
    };

    const handleDesignStatusChange = (event) => {
        setDesignStatus(event.target.value);
    };

    const approveReturnSheet = async (statusId, sheet_id = selectedSheetId) => {
        if (statusId === 3) {
            try {
                const response = await newPut(`approval/approve`, {
                    "sheet_ids": [sheet_id]
                })
                if ([200, 201].includes(response?.status_code)) {
                    dispatch(updateSheetStatus({
                        sheetId: sheet_id,
                        status: 3  // 3 for approved status
                    }));
                }
            }
            catch (error) {
                console.log("error", error)
            }
        }
        else if (statusId === 4) {
            const modalElement = modalRef.current;
            const modalInstance = new window.bootstrap.Modal(modalElement);
            modalInstance.show();
            // setReturnModalOpen(true)
            // try {
            //     const response = newPut(`approval/return`, {
            //         "sheet_ids": [sheet_id]
            //     })
            //     console.log("response", response)
            // }
            // catch (error) {
            //     console.log("error", error)
            // }
        }
    };

    const isChannelManager = () => {
        return [3, 4].includes(userSettings?.role_id)
    };

    const [returnComment, setReturnComment] = useState("");

    const handleCommentChange = (event) => {
        setReturnComment(event.target.value);
    };

    const handleReturnSheet = async () => {
        try {
            const response = await newPut(`approval/return`, {
                "sheet_ids": [selectedSheetId],
                "return_reason": returnComment
            });

            if ([200, 201].includes(response?.status_code)) {
                dispatch(updateSheetStatus({
                    sheetId: selectedSheetId,
                    status: 4  // 4 for returned status
                }));
                // Close modal or reset comment if needed
                setReturnComment('');
            }
        } catch (error) {
            console.error("Error in handleReturnSheet:", error);
        }
    };

    return (
        <div
            ref={containerRef}
            style={{ width: '100%', height: '100%' }}
        >
            <Stage
                ref={stageRef}
                width={stageSize.width}
                height={stageSize.height}
                onWheel={handleWheel}
                draggable={!isMenuOpen}
                scaleX={scale}
                scaleY={scale}
                x={stagePosition.x}
                y={stagePosition.y}
                onContextMenu={handleContextMenu}
            >
                <Layer>
                    {canvas?.layers?.[0]?.groups?.map((sheet, index) => (
                        <Group
                            key={sheet.id}
                            x={sheet.position?.x || 50 + (index * 600)}
                            y={sheet.position?.y || 50}
                            draggable={!isMenuOpen}
                            onClick={() => setSelectedSheetId(sheet.id)}
                            onDragStart={(e) => {
                                if (isMenuOpen) {
                                    e.evt.preventDefault();
                                    return;
                                }
                                handlePageDragStart(e);
                            }}
                            onDragEnd={(e) => {
                                if (isMenuOpen) {
                                    e.evt.preventDefault();
                                    return;
                                }
                                handlePageDragEnd(e, sheet.id);
                            }}
                        >
                            {/* Sheet Header */}
                            <Group y={-35}>
                                {/* Header Background */}
                                <Rect
                                    width={500}
                                    height={30}
                                    fill="transparent"
                                    cornerRadius={[5, 5, 0, 0]}
                                />
                                <Html>
                                    <div style={{
                                        width: '500px',
                                        height: '30px',
                                        position: 'relative',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: '0 10px'
                                    }}>
                                        {/* Left side - Sheet name and status */}
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '10px'
                                        }}>
                                            {/* Sheet Name */}
                                            {editingText && editingText.id === sheet.id ? (
                                                <input
                                                    type="text"
                                                    value={editingText.text}
                                                    onChange={handleTextChange}
                                                    onBlur={handleTextBlur}
                                                    onKeyDown={handleTextKeyDown}
                                                    autoFocus
                                                    style={{
                                                        width: 'auto',
                                                        minWidth: '150px',
                                                        height: '24px',
                                                        fontSize: '14px',
                                                        padding: '0 5px',
                                                        margin: '0',
                                                        border: 'none',
                                                        borderRadius: '3px',
                                                        background: 'transparent',
                                                        fontFamily: 'inherit',
                                                        outline: 'none'
                                                    }}
                                                />
                                            ) : (
                                                <span
                                                    style={{
                                                        fontSize: '14px',
                                                        cursor: 'pointer',
                                                        padding: '3px 5px',
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        maxWidth: "230px"
                                                    }}
                                                    title={sheet.name || "Untitled"}
                                                    onDoubleClick={(e) => handleDoubleClickSheetName(sheet, e)}
                                                >
                                                    {sheet.name || "Untitled"}
                                                </span>
                                            )}

                                            {/* Status Tag */}
                                            {!isChannelManager() && (
                                                <div style={{
                                                    backgroundColor: getStatusInfo(sheet.status_id).color,
                                                    borderRadius: '12px',
                                                    padding: '3px 12px',
                                                    display: 'inline-flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    minWidth: (getStatusInfo(sheet.status_id).width) + 'px',
                                                    height: '24px'
                                                }}>
                                                    <span style={{
                                                        fontSize: '12px',
                                                        color: getStatusInfo(sheet.status_id).textColor,
                                                        textAlign: 'center',
                                                        textTransform: 'uppercase',
                                                        fontWeight: "700"
                                                    }}>
                                                        {t(getStatusInfo(sheet.status_id).text)}
                                                    </span>
                                                </div>
                                            )}
                                        </div>

                                        {/* Right side controls */}
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '15px'
                                        }}>
                                            {/* Lock Icon */}
                                            {lockIcon && !isChannelManager() && (
                                                <img
                                                    src={sheet.is_locked ? lockClose : lockOpen}
                                                    alt="Lock"
                                                    style={{
                                                        width: '19px',
                                                        height: '21px',
                                                        cursor: lockLoadingStates[index] ? 'wait' : 'pointer'
                                                    }}
                                                    onClick={() => lockAndUnlockSheet(sheet, index)}
                                                />
                                            )}

                                            {/* Divider */}
                                            {sheet.status_id !== 3 && !isChannelManager() && isProjectActive() && (
                                                <div style={{
                                                    width: '1px',
                                                    height: '20px',
                                                    backgroundColor: '#ddd'
                                                }} />
                                            )}

                                            {/* Menu Icon */}
                                            {sheet.status_id !== 3 && !isChannelManager() && menuIcon && isProjectActive() && (
                                                <img
                                                    src={threeDots}
                                                    alt="Menu"
                                                    style={{
                                                        width: '19px',
                                                        height: '21px',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={(e) => handleMenuClick(e, sheet)}
                                                    key="menu-icon"
                                                />
                                            )}

                                            {/* Approval Dropdown */}
                                            {userSettings?.permissions && isChannelManager() && (
                                                <>
                                                    {sheet.status_id != 2 ? (
                                                        <p className={`font-noto-sans fs-12 fw-600 px-2 mb-0 ${sheetStatus.find(status => status.id === sheet.status_id)?.name.toLowerCase() || 'draft'}`} style={{
                                                            borderRadius: "13px",
                                                            padding: "2px"
                                                        }}>
                                                            {sheetStatus.find(status => status.id === sheet.status_id)?.name || 'Draft'}
                                                        </p>
                                                    ) : (
                                                        <div className="rounded">
                                                            <Select
                                                                value={sheet.status_id}
                                                                name="status"
                                                                onChange={handleDesignStatusChange}
                                                                onClick={() => setSelectedSheetId(sheet.id)}
                                                                displayEmpty
                                                                disabled={sheet.status_id != 2}
                                                                inputProps={{ "aria-label": "Without label" }}
                                                                className={`font-noto-sans konva-select`}
                                                                style={{ color: 'black', fontSize: '12px', fontWeight: '600' }}
                                                            >
                                                                {sheetStatus.map((status) => (
                                                                    <MenuItem
                                                                        key={status.id}
                                                                        value={status.id}
                                                                        sx={{ fontSize: '14px' }}
                                                                        style={{
                                                                            display: sheet.status_id === status.id ? 'none' : null
                                                                        }}
                                                                        onClick={() => approveReturnSheet(status.id, sheet.id)}
                                                                    >
                                                                        {status.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </Html>
                            </Group>

                            {/* Sheet Content */}
                            <Rect
                                width={500}
                                height={500}
                                fill="white"
                                stroke="#ddd"
                                strokeWidth={1}
                                shadowColor="rgba(0,0,0,0.1)"
                                shadowBlur={10}
                                shadowOffset={{ x: 0, y: 2 }}
                                cornerRadius={2}
                            />

                            {/* Sheet Elements */}
                            {sheet.elements?.map(element => (
                                <DraggableImage
                                    key={element.id}
                                    element={{
                                        ...element,
                                        draggable: !isMenuOpen && !sheet.is_locked
                                    }}
                                    sheetId={sheet.id}
                                    handleDragEnd={handleDragEnd}
                                    handleDragStart={handleDragStart}
                                    handleTransformEnd={handleTransformEnd}
                                />
                            ))}
                        </Group>
                    ))}

                    {/* Add the "+" button after the last sheet */}
                    {canvas?.layers?.[0]?.groups?.length > 0 && !isChannelManager() && isProjectActive() && (
                        <Group
                            x={(canvas.layers[0].groups.length * 600) + 100}
                            y={250}
                            onClick={addNewSheet}
                            cursor={addingNewSheetLoading ? "wait" : "pointer"}
                            onMouseEnter={(e) => {
                                const container = e.target.getStage().container();
                                container.style.cursor = 'pointer'; // Set the cursor to pointer
                            }}
                            onMouseLeave={(e) => {
                                const container = e.target.getStage().container();
                                container.style.cursor = 'default'; // Reset to default cursor
                            }}
                        >
                            <Circle
                                radius={15}
                                fill="#000"
                                shadowColor="rgba(0,0,0,0.2)"
                                shadowBlur={5}
                                shadowOffset={{ x: 0, y: 2 }}
                            />
                            <Text
                                text="+"
                                x={-5}
                                y={-8}
                                fontSize={20}
                                fill="white"
                                fontStyle="bold"
                            />
                        </Group>
                    )}

                    {/* Add a "+" button if there are no sheets */}
                    {(!canvas?.layers?.[0]?.groups || canvas.layers[0].groups.length === 0) && (
                        <Group
                            x={300}
                            y={250}
                            onClick={addNewSheet}
                            cursor={addingNewSheetLoading ? "wait" : "pointer"}
                            onMouseEnter={(e) => {
                                const container = e.target.getStage().container();
                                container.style.cursor = 'pointer'; // Set the cursor to pointer
                            }}
                            onMouseLeave={(e) => {
                                const container = e.target.getStage().container();
                                container.style.cursor = 'default'; // Reset to default cursor
                            }}
                        >
                            <Circle
                                radius={15}
                                fill="#000"
                                shadowColor="rgba(0,0,0,0.2)"
                                shadowBlur={5}
                                shadowOffset={{ x: 0, y: 2 }}
                            />
                            <Text
                                text="+"
                                x={-5}
                                y={-8}
                                fontSize={20}
                                fill="white"
                                fontStyle="bold"
                            />
                        </Group>
                    )}
                </Layer>
            </Stage>

            {/* <Menu
                open={Boolean(menuPosition)}
                anchorReference="anchorPosition"
                anchorPosition={
                    menuPosition
                        ? { top: menuPosition.y, left: menuPosition.x }
                        : undefined
                }
                onClose={handleMenuClose}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={handleReplace}>
                    <img src={replace} alt="replace" className="me-2" />
                    {t("replace")}
                </MenuItem>
                <MenuItem onClick={handleDownload}>
                    <img src={download} alt="download" className="me-2" />
                    {t("download")}
                </MenuItem>
                <MenuItem onClick={() => {
                    // Handle lock/request approval
                    handleMenuClose();
                }}>
                    <img src={lockOpen} alt="lock" className="me-2" />
                    {t("lockNRequestApproval")}
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleDelete}>
                    <img src={remove} alt="delete" className="me-2" />
                    {t("delete")}
                </MenuItem>
            </Menu> */}

            {sheet_status_id !== 3 &&
                <>
                    <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                        }}
                        open={Boolean(menuPosition)}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            menuPosition
                                ? { top: menuPosition.y, left: menuPosition.x }
                                : undefined
                        }
                        onClose={handleMenuClose}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        slotProps={{
                            paper: {
                                style: {
                                    transform: 'none',
                                }
                            }
                        }}
                    >
                        {(sheet_status_id === 1 || sheet_status_id === 4) && !selectedSheet?.is_locked &&
                            <>
                                <MenuItem
                                    onClick={() => {
                                        handleMenuClose();

                                    }}
                                    disableRipple
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_design"
                                >
                                    <img className="mx-2" src={replace} alt='lockOpen' width={19} height={21} />
                                    {selectedSheet?.elements?.[0]?.image_data?.data ? t("sheetDropdownReplace") : t("upload")}
                                </MenuItem>

                                <MenuItem
                                    onClick={() => {
                                        handleMenuClose();
                                        handleDeleteSheet(selectedSheetId);
                                    }}
                                    disableRipple
                                >
                                    <img className="mx-2" src={remove} alt='lockOpen' width={19} height={21} />
                                    {t("sheetDropdownRemove")}
                                </MenuItem>
                            </>
                        }

                        {(sheet_status_id === 1 || sheet_status_id === 4) && !isLock && selectedSheet?.elements?.[0]?.image_data?.data &&
                            <Divider sx={{ my: 0.5 }} />
                        }

                        {sheet_status_id !== 3 && selectedSheet?.elements?.[0]?.image_data?.data &&
                            (sheet_status_id === 1 || sheet_status_id === 4 ? (
                                <MenuItem
                                    onClick={() => {
                                        handleMenuClose();

                                    }}
                                    disableRipple
                                    data-bs-toggle="modal"
                                    data-bs-target="#approvalModal">
                                    {/* <MoreHorizIcon /> */}
                                    <img className="mx-2" src={verified} alt='lockOpen' width={19} height={21} />
                                    {selectedSheet?.is_locked ? `${t("sheetDropdownApproval")}` : `${t("sheetDropdownLockApproval")}`}
                                </MenuItem>
                            ) : <>
                                <MenuItem
                                    onClick={() => {
                                        handleMenuClose();
                                    }}
                                    disableRipple
                                    data-bs-toggle="modal"
                                    data-bs-target="#cancelModal">
                                    {/* <MoreHorizIcon /> */}
                                    <img className="mx-2" src={verified} alt='lockOpen' width={19} height={21} />
                                    {t("sheetDropdownCancelApproval")}
                                </MenuItem>
                                {
                                    userSettings?.role_id === 2 || userSettings?.role_id === 1
                                        ? <>
                                            <Divider sx={{ my: 0.5 }} />
                                            {
                                                sheetStatus.map((status) => status.id !== 2 && (
                                                    <MenuItem
                                                        key={status.id}
                                                        value={status.id}
                                                        sx={{ fontSize: '14px' }}
                                                        style={{
                                                            display: selectedSheetId.status_id === status.id ? 'none' : null
                                                        }}
                                                        data-bs-toggle="modal"
                                                        onClick={() => approveReturnSheet(status.id)}
                                                    >
                                                        {status.imgSrc ? <img className='mx-2' src={status.imgSrc} alt='' width={19} height={21} /> : null}
                                                        {status.label}
                                                    </MenuItem>
                                                ))
                                            }
                                        </>
                                        : null
                                }
                            </>
                            )

                        }
                    </StyledMenu>
                </>
            }

            {/* Request Approval */}
            <div className="modal fade" id="approvalModal" tabIndex="-1" aria-labelledby="approvalModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content px-3">
                        <div className="modal-header border-0 font-noto-sans">
                            <div className='upload_design  fs-20' style={{ fontWeight: "700" }}>
                                {t("approvalPopupHeader")}
                            </div>
                            {/* <div className='cursor_pointer' data-bs-dismiss="modal" >
                                            <img src={Close} alt='Close' />
                                        </div> */}
                        </div>
                        <div className="modal-body font-noto-sans">
                            <div className="d-flex  gap-3">
                                <p className="fw-400 fs-16 " > {t("approvalPopupText")} </p>
                            </div>
                            <div className="modal-footer border-0">
                                <p type="button" className="cancel_modal_footer mx-5" data-bs-dismiss="modal" > {t("approvalPopupButton1")} </p>
                                <button style={{ paddingLeft: "6px" }} type="button" className="send_modal_footer" data-bs-dismiss="modal" onClick={() => { lockNRequestApproval(); handleLockNRequestApproval() }}>
                                    {t("approvalPopupButton2")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Cancel Approval */}
            <div className="modal fade" id="cancelModal" tabIndex="-1" aria-labelledby="cancelModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content px-3">
                        <div className="modal-header border-0 font-noto-sans">
                            <div className='upload_design  fs-20' style={{ fontWeight: "700" }}>
                                {t("cancelPopupHeader")}
                            </div>
                            {/* <div className='cursor_pointer' data-bs-dismiss="modal" >
                                            <img src={Close} alt='Close' />
                                        </div> */}
                        </div>
                        <div className="modal-body font-noto-sans">
                            <div className="d-flex  gap-3">
                                <p className="fw-400 fs-16 " > {t("cancelPopupText")}</p>
                            </div>
                            <div className="modal-footer border-0">
                                <p type="button" className="cancel_modal_footer mx-5" data-bs-dismiss="modal" >{t("cancelPopupButton1")}</p>
                                <button style={{ paddingLeft: "6px" }} type="button" className="send_modal_footer"
                                    data-bs-dismiss="modal" onClick={() => { cancelRequestApproval(); handleCancelRequestApproval(); }}>
                                    {t("cancelPopupButton2")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* {returnModalOpen && */}
            <div className="modal fade " id="returnModal" ref={modalRef} tabIndex="-1" aria-labelledby="returnModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content px-3">
                        <div className="modal-header border-0 font-noto-sans">
                            <div className='upload_design  fs-20' style={{ fontWeight: "700" }}>
                                {t("returnPopupText")}
                            </div>
                            {/* <div className='cursor_pointer' data-bs-dismiss="modal" >
                                            <img src={Close} alt='Close' />
                                        </div> */}
                        </div>
                        <div className="modal-body font-noto-sans">
                            <div className="d-flex  gap-3">
                                <p className="fw-400 fs-16 " > {t("returnPopupHeader")}</p>
                            </div>

                            <textarea
                                type='text'
                                rows="2"
                                name="return_comments"
                                onChange={handleCommentChange}
                                value={returnComment}
                                className="form-control from-input"
                                id="return_comments"
                                placeholder={t("returnPopupPlaceholder")}
                                maxLength={500} />


                            {/* <div className="form-floating">
                                <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style="height: 100px"></textarea>
                                <label for="floatingTextarea2">Comments</label>
                            </div> */}
                            <div className="modal-footer border-0">
                                <p type="button" className="cancel_modal_footer mx-5" data-bs-dismiss="modal" >{t("cancel")}</p>
                                <button style={{ paddingLeft: "10px" }} type="button" className="send_modal_footer" data-bs-dismiss="modal" onClick={() => handleReturnSheet()}  > {t("returnPopupButton")}</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    );
};

export default KonvaCanvas;