import React, { useRef, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { Group, Image, Transformer } from 'react-konva';

const DraggableImage = ({ element, sheetId, handleDragEnd, handleDragStart, handleTransformEnd }) => {
  const [image, setImage] = useState(null);
  const imageRef = useRef();
  const transformerRef = useRef();

  useEffect(() => {
    if (element.image_data?.data) {
      const img = new window.Image();
      img.src = `data:image/${element.image_data.format.toLowerCase()};base64,${element.image_data.data}`;
      img.onload = () => {
        setImage(img);
      };
    }
  }, [element.image_data]);

  // Calculate dimensions to fit image within page while maintaining aspect ratio
  const calculateDimensions = () => {
    if (!image) return { width: 500, height: 500 };
    
    const pageWidth = 500;
    const pageHeight = 500;
    const imageRatio = image.width / image.height;
    const pageRatio = pageWidth / pageHeight;

    let width, height;
    if (imageRatio > pageRatio) {
      // Image is wider than page ratio
      width = pageWidth;
      height = pageWidth / imageRatio;
    } else {
      // Image is taller than page ratio
      height = pageHeight;
      width = pageHeight * imageRatio;
    }

    return { width, height };
  };

  const dimensions = calculateDimensions();

  return (
    <Group
      x={(500 - dimensions.width) / 2}  // Center horizontally in page
      y={(500 - dimensions.height) / 2}  // Center vertically in page
      draggable={false}  // Disable dragging
    >
      {image && (
        <>
          <Image
            ref={imageRef}
            image={image}
            width={dimensions.width}
            height={dimensions.height}
          />
        </>
      )}
    </Group>
  );
};

export default DraggableImage;