import React, { useState, useEffect } from 'react';

const InternetConnection = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    if (isOnline) return null;

    return (
        <div 
            style={{
                position: 'fixed',
                top: '0',
                left: '0',
                right: '0',
                zIndex: '9999',
                backgroundColor: '#dc3545',
                color: 'white',
                textAlign: 'center',
                padding: '10px',
                fontFamily: 'Noto Sans'
            }}
        >
            No internet connection. Please check your network connection.
        </div>
    );
};

export default InternetConnection; 