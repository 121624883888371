import React, { useState, useRef, useEffect, useMemo, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSocketContext } from "../../../context/useSocketContext";
import { useUploadContext } from "../../../context/useUploadContext";
import { SOCKET_URL } from "../../../constants/envs";
import { ProgressBar } from "primereact/progressbar";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { FileUpload } from "primereact/fileupload";
import { Tag } from "primereact/tag";
import trueGreenIcon from "../../../assets/icons/trueGreenIcon.svg";
import uploadPopupImg from "../../../assets/icons/upload-popup-img.png";
import DesignUploadImage from "../../../assets/newicons/design_upload_file.svg";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "../../Upload/upload.scss";
import "../../../assets/scss/home.scss";
import { useTranslation } from "react-i18next";
import { newGet, newImagePost, newPut } from "../../../API/axios";
import ImageContext, { MyContext } from "./ImageContext";
import { useReferenceContext } from "../../../context/useReferenceContext";
import { useKonvaContext } from "../../../context/useKonvaContext";
import { initializeCanvas, updateElement } from "../../../store/konvaSlice";
import { useDispatch } from "react-redux";
import { showAlert } from "./AlertService";

const UploadDesignFile = ({ onUploadImage, setTab, screen, setdesignDetails, board_id, layerId, onFilesFetched }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const { createdProjectId } = location.state || {};
  // const { connection, setConnection } = useSocketContext();
  const {
    hasNavigated,
    setHasNavigated,
    imageList,
    setImageList,
    progress,
    setProgress,
    currentStep,
    setCurrentStep,
  } = useUploadContext();
  const fileUploadRef = useRef(null);
  const imageCountRef = useRef(0);
  const navigate = useNavigate();

  //popup
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [imageProgress, setImageProgress] = useState(false);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [progressCount, setProgressCount] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const { setImages, resetPopup } = useContext(ImageContext);
  const { designId } = useReferenceContext()
  const { selectedSheetLayerId } = useKonvaContext();

  const resetPopups = () => {
    // setImages([]);
    // setFiles([]);
    fileUploadRef.current.clear();
    setTab(1);
  }
  const steps = [
    "Generating English Description",
    "Generating Japanese Description",
    "Generating Tags",
    "Updating Tags and Description",
    "Inserting to Database and Collection",
  ];
  const imageCount = useMemo(() => files.length, [files]);
  const percentagePerStep = 100 / 5;
  // websocket
  const [errorMessage, setErrorMessage] = useState("");

  // cancel popup
  const [isCancelOpen, setIsCancelOpen] = useState(false);

  useEffect(() => {
    const modalElement = document.getElementById('add_design');

    if (modalElement) {
      // const modalInstance = window.bootstrap.Modal.getInstance(modalElement);

      const handleModalHide = (event) => {
        if (fileUploadRef.current) {
          fileUploadRef.current.clear();
          fileUploadRef.current.setFiles([]);
        }
        setFiles([]);
      };

      modalElement.addEventListener('hidden.bs.modal', handleModalHide);
      // Cleanup event listener on unmount
      return () => {
        modalElement.removeEventListener('hidden.bs.modal', handleModalHide);
      };
    }
  }, [fileUploadRef]); // Dependency array includes fileUploadRef to ensure it's updated


  // useEffect(() => {

  //   if (fileUploadRef?.current) {
  //     debugger
  //     fileUploadRef.current.clear();
  //     fileUploadRef.current.setFiles([]);
  //   }
  // }, [isPopupOpen])

  // upload images
  // console.log("screen ", screen, location.state)
  const uploadImages = async (e) => {
    setIsUploading(true);

    const images = e?.files || files;
    imageCountRef.current = images.length;

    const formData = new FormData();
    setImageProgress(true);

    for (let i = 0; i < images.length; i++) {
      formData.append('files', images[i]);
    }

    let project_id;
    if (screen === "modal" || screen === "addPsd") {
      project_id = location.state.createdProjectId
      formData.append('project_id', project_id);
    }
    else if (screen === "component") {
      project_id = location.state.project_id;
      formData.append('project_id', project_id);
      formData.append('design_id', location.state.design_id);
    }
    else if (screen === "sidemenu") {
      formData.append('board_id', board_id);
    }
    else if (screen === "visionStudio") {
      formData.append('design_id', designId);
    }

    if (screen === "modal" || screen === "component" || screen === "sidemenu") {
      try {
        const res = await newImagePost(`file/upload`, formData);
        if (res.status_code == 201 || res.status_code == 200) {
          setImageProgress(false);
          if (onFilesFetched) {
            onFilesFetched(res.result);
          }
          // Attach response data to original images
          const updatedImages = images.map((img, index) => {
            if (res.result[index]) {
              img.data = res.result[index];
            }
            return img;
          });

          setImages(updatedImages);

          // Close modal
          const modalElement = document.getElementById('add_design');
          const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
          modalInstance.hide();

          // Cleanup after short delay
          setTimeout(() => {
            setImages([]);
            setFiles([]);
          }, 1000);

         
        }
      } catch (error) {
        console.log(error);
      }
    }
    else if (screen === "visionStudio") {
      console.log("Vision Studio", formData)
      try {
        const data = await newImagePost(`upload`, formData);
        if (data.status_code == 201 || data.status_code == 200) {
          try {
            const layerData = await newPut('layer/changeBackgroundLayerImage', {
              layer_id: selectedSheetLayerId,
              layer_type: "background",
              image_url: data?.result[0]?.url
            });
            if ([200, 201].includes(layerData?.status_code)) {
              getDesignDetails();
            }
          } catch (error) {
            console.error('Error upserting annotation data:', error);
          }
          setImageProgress(false);
          const modalElement = document.getElementById('add_design');
          const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
          modalInstance.hide();
          setImages([]);
          setFiles([]);
        }
      }
      catch (error) {
        console.log(error);
      }
    }
    else {
      console.log("Else Part")
      const modalElement = document.getElementById('addreference');
      const modalInstance = window.bootstrap.Modal.getInstance(modalElement);
      modalInstance.hide();
      resetPopups()
    }
  };

  const getDesignDetails = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const design_id = urlParams.get("design_id");
    try {
      const designData = await newGet(`design/getDesignDetail?design_id=${design_id||designId}`);
      if (designData?.status_code == 200) {
        // setSheetCount(designData?.result?.sheets.length);
        dispatch(initializeCanvas({
          id: designId,
          sheets: designData?.result?.sheets || []
        }));
        setTimeout(() => {
          dispatch(initializeCanvas({
            id: designId,
            sheets: designData?.result?.sheets || []
          }));
        })
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  const getPsdImages = async () => {
    try {
      const data = await newGet(`/document/getDocument?project_id=${createdProjectId || location.state.project_id}`);
      data !== undefined && setdesignDetails(data?.result);

    }
    catch (error) {
      console.log(error);
    }
  }

  const setCurrentStepMsg = async (done, total) => {
    return new Promise((resolve) => {
      const msg = `${done}/${total}`;
      setProgressCount(msg);
      resolve();
    });
  };

  const setCurrentStepAsync = async (step) => {
    return new Promise((resolve) => {
      setCurrentStep(step);
      resolve();
    });
  };

  // console.log("isPopupOpen ", isPopupOpen);
  // useEffect(() => {
  //   if (!connection || connection.readyState !== WebSocket.OPEN) {
  //     console.log("Connection is not established");
  //     return;
  //   }
  //   let messageCount = 0;

  //   connection.onmessage = async function (event) {
  //     if (event?.data && event.data.indexOf('{') == -1) {
  //       return;
  //     }
  //     console.log("Message", event.data);
  //     messageCount++;
  //     if (messageCount === imageCount) {
  //       setIsAnalyzing(true);
  //     }
  //     let data = JSON.parse(event.data);
  //     const { message, done, total, step } = data;

  //     if (step === 1) {
  //       setImageProgress(false);
  //       setIsPopupOpen(true);
  //     }
  //     if (message === "Batch process completed") {
  //       setCurrentStep(6);
  //       setHasNavigated(false);
  //       toast.success("Upload Process Completed!");
  //       const newConnection = new WebSocket(SOCKET_URL);
  //       newConnection.onopen = () => {
  //         console.log("WebSocket connection established");
  //         setConnection(newConnection);
  //       };
  //       navigate("/search");
  //       setProgress(0);
  //     }
  //     if (done) {
  //       setIsAnalyzing(false);
  //       await setCurrentStepMsg(done, total);
  //       setCurrentStepAsync(step);

  //       const noOfImages = imageList.length ? imageList.length : imageCount;
  //       let percentage = percentagePerStep / noOfImages;
  //       setProgress((prevProgress) => prevProgress + percentage);
  //     } else if (data.request === "update_tags_and_desc") {
  //       setTimeout(async () => {
  //         await setCurrentStepAsync(data.step);
  //         setProgressCount(null);
  //         setImageList(data.image_list);
  //         navigate("/upload-edit", {
  //           state: {
  //             data: data.image_list[0],
  //           },
  //         });
  //       }, 2000);
  //     }
  //   };
  //   connection.onerror = function (error) {
  //     setProgress(0);
  //     setCurrentStep(0);
  //     setHasNavigated(false);
  //     setErrorMessage(error);
  //   };
  // }, [connection, imageCount, hasNavigated]);

  //Templates
  const browseFiles = () => {
    const uploadBtn = document.getElementsByClassName("p-fileupload-choose");
    uploadBtn[0].click();
  };

  // const onSelectImage = (e) => {
  //   const selectedFiles = e.files;
  //   // console.log('selectedFiles: ', selectedFiles)
  //   const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/tiff', 'image/webp', 'image/heif', 'image/heic', 'image/avif', 'image/svg+xml'];

  //   // const validImageTypes = ['image/vnd.adobe.photoshop'];
  //   for (let file of selectedFiles) {
  //     if (!validImageTypes.includes(file.type)) {
  //       alert('Only image files are allowed!');
  //       if (fileUploadRef.current) {
  //         fileUploadRef.current.clear();
  //       }
  //       return;
  //     }
  //   }
  //   setFiles(selectedFiles);
  //   imageCountRef.current = selectedFiles.length;
  // };


  const onSelectImage = (e) => {
    const selectedFiles = e.files;

    if (screen === "visionStudio") {
      // Allow only single file for visionStudio
      if (selectedFiles.length > 1) {
        // alert('Please select only one file');
        showAlert("Please select only one file", "error");
        fileUploadRef.current?.clear();
        return;
      }

      // Allow only PNG and JPG for visionStudio
      const validTypes = ['image/png', 'image/jpeg', 'image/jpg'];
      if (!validTypes.includes(selectedFiles[0]?.type)) {
        // alert('Only PNG and JPG files are allowed');
        showAlert("Only PNG and JPG files are allowed", "error");
        fileUploadRef.current?.clear();
        return;
      }
    } else {
      // Original validation for other screens
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/tiff', 'image/webp', 'image/heif', 'image/heic', 'image/avif', 'image/svg+xml'];

      if (selectedFiles.length > 10) {
        // alert('Please select less or 10 files');
        showAlert("Please select less or 10 files", "error");
        fileUploadRef.current?.clear();
        return;
      }
      for (let file of selectedFiles) {
        if (!validImageTypes.includes(file.type)) {
          // alert('Only image files are allowed!');
          showAlert("Only image files are allowed!", "error");
          fileUploadRef.current?.clear();
          return;
        }
      }
    }

    setFiles(selectedFiles);
    imageCountRef.current = selectedFiles.length;
  };


  const preventVideoDragAndDrop = (event) => {
    const items = event.dataTransfer.items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].kind === 'file' && items[i].type.startsWith('video/')) {
        event.preventDefault();
        alert('Video files are not allowed!');
        return;
      }
    }
  };

  useEffect(() => {
    const dropArea = document.querySelector('.border_desh'); // Replace with your actual drop area selector

    dropArea.addEventListener('dragover', preventVideoDragAndDrop);
    dropArea.addEventListener('drop', preventVideoDragAndDrop);

    return () => {
      dropArea.removeEventListener('dragover', preventVideoDragAndDrop);
      dropArea.removeEventListener('drop', preventVideoDragAndDrop);
    };
  }, []);

  const emptyTemplate = (e) => {
    return (
      <div className="text-center">
        <img src={DesignUploadImage} className="img-fluid mb-3" alt="upload" />
        <div className="text-center text-extrabluedarks ">
          {t("Drag&DropFile")}{" "}
          <span
            className=" text-decoration-underline"
            style={{ cursor: "pointer" }}
            onClick={browseFiles}
          >
            {t("browseFile")}
          </span>
        </div>
        {/* <p className="fs-16 text-center">Supports: JPEG, JPG, PNG</p> */}
        <div className="d-flex justify-content-center">
          <button
            className="border-0 rounded-pill text-white px-4 py-sm-1 px-sm-5"
            style={{ display: imageCount === 0 ? "none" : "" }}
            disabled={true}
          >
            Upload
          </button>
        </div>
      </div>
    );
  };
  const progressBarTemplate = () => {
    return (
      <ProgressBar
        mode={imageProgress ? "indeterminate" : "determinate"}
        showValue={false}
      />
    );
  };
  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          // display: "flex",
          display: "none",
          alignItems: "center",
        }}
      >
        {chooseButton}
        {uploadButton}
        {cancelButton}
      </div>
    );
  };
  const itemTemplate = (file, props) => {
    return (
      <div className="">
        <div className="text-center gap-4">
          <img
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={200}
            height={200}
            className="img-fluid"
          />
          <div className="flex flex-column text-left my-3">
            {file.name}{" "}
            <small>{new Date().toLocaleDateString()}</small>
          </div>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="px-3 py-2"
        />
        {/* <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        /> */}
      </div>
    );
  };

  return (

    <div>
      <section
      >
        {!isPopupOpen && !isCancelOpen && !hasNavigated && (
          <div>
            {!isAnalyzing && (
              <div className="font-sora bg-purple">
                <div className="border_desh">
                  <FileUpload
                    ref={fileUploadRef}
                    name="files"
                    multiple
                    accept="image/*"
                    customUpload
                    onSelect={onSelectImage}
                    uploadHandler={uploadImages}
                    headerTemplate={headerTemplate}
                    emptyTemplate={emptyTemplate}
                    progressBarTemplate={progressBarTemplate}
                    itemTemplate={itemTemplate}
                  />
                </div>
                {imageCount > 0 && (
                  <>
                    <div className="d-flex justify-content-center">
                      <button
                        className="border-0 rounded-pill text-white bg-dark px-4 py-2"
                        onClick={uploadImages}
                      // disabled
                      >
                        {!imageProgress ? "Upload" : ""}
                        <React.Fragment>
                          {imageProgress ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                        </React.Fragment>
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}
            {isAnalyzing && (
              <div className="spinner-overlay">
                <div
                  className="spinner"
                  style={{ height: "35px", width: "35px" }}
                />
                <div className="snippet" data-title="dot-flashing">
                  <span
                    style={{
                      fontSize: "21px",
                      fontWeight: "500",
                    }}
                  >
                    Analyzing Images
                    <div className="loader">
                      <div className="dot"></div>
                      <div className="dot"></div>
                      <div className="dot"></div>
                    </div>
                  </span>
                </div>
              </div>
            )}
          </div>
        )}

        {/* Upload Progress popup*/}
        {((isPopupOpen && !isCancelOpen) || hasNavigated) && (
          <div>
            <div className="py-5 font-sora bg-purple">
              <h2 className="text-center fs-35 mt-5 px-2">
                Upload Progress{" "}
                <span className="fs-24 text-nowrap">
                  ({hasNavigated ? imageList.length : imageCount} images)
                </span>
              </h2>

              <div className="pt-4 d-flex justify-content-center px-3">
                <div
                  className="border-purple d-flex flex-column gap-2 rounded-20 w-100 p-lg-5 p-3 p-sm-4"
                  style={{ maxWidth: "738px" }}
                >
                  <ProgressBar
                    value={progress}
                    showValue={false}
                    style={{
                      margin: "10px",
                      height: "12px",
                      justifyContent: "center",
                    }}
                  />

                  {steps.map((step, index) => (
                    <div
                      className="d-flex justify-content-between align-items-center border-bottom pb-3"
                      key={index}
                    >
                      <div className="d-flex align-items-center gap-2">
                        <span className="fs-24 font-Oswald text-light-gray">
                          {index + 1 < 10 ? `0${index + 1}` : index + 1}
                        </span>
                        <h4
                          className={`fs-14 mt-1 mb-0 fs-20 ${currentStep > index ? "text-light-purple" : ""
                            }`}
                        >
                          {step}
                        </h4>
                      </div>
                      <div className="d-flex align-items-center flex-nowrap">
                        {currentStep === index + 1 && (
                          <span className="text-extrabluedark me-1 fs-16">
                            {progressCount !== null ? `(${progressCount})` : ""}
                          </span>
                        )}
                        {currentStep === index + 1 && (
                          <div className="spinner"></div>
                        )}
                        {currentStep > index + 1 && (
                          <img src={trueGreenIcon} alt="green icon" />
                        )}
                      </div>
                    </div>
                  ))}
                  {errorMessage && (
                    <p className="text-danger text-center my-4 fs-14">
                      Error uploading output. Try Again
                    </p>
                  )}
                  <div className="d-flex justify-content-center align-items-center gap-2 mb-4">
                    {/* <button
                      disabled={currentStep === 6}
                      className="text-extrabluedark  rounded-pill border-purple bg-transparent fs-18 cancle-button fw-bold"
                      onClick={() => {
                        setIsCancelOpen(true);
                        setIsPopupOpen(false);
                      }}
                    >
                      Cancel
                    </button> */}
                    <button
                      className="text-white border-0 rounded-pill purple-gradiant fs-18 cancle-button fw-bold"
                      disabled={currentStep !== 6}
                      onClick={() => setIsPopupOpen(false)}
                    >
                      Okay
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isCancelOpen && !isPopupOpen && hasNavigated && (
          <div>
            {/* popup  */}
            <div className="d-flex justify-content-center flex-column align-items-center py-5 px-5 font-sora">
              <img
                src={uploadPopupImg}
                className="img-fluid"
                alt="upload popup"
              />
              <p className="mb-0 fs-20 text-center">
                This will cancel the upload for all images, are you sure?
              </p>
              <div className="d-flex justify-content-center align-items-center gap-2 mt-4">
                <button
                  className="text-white border-0 rounded-pill purple-gradiant fs-18 cancle-button fw-bold"
                  onClick={() => {
                    setIsPopupOpen(true);
                    setIsCancelOpen(false);
                  }}
                >
                  No
                </button>
                <button
                  className="text-extrabluedark  rounded-pill border-purple bg-transparent fs-18 cancle-button fw-bold"
                  onClick={() => {
                    setIsPopupOpen(false);
                    setIsCancelOpen(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
}

export default UploadDesignFile;
